/* eslint @typescript-eslint/no-unused-vars: 0 */
type P = string | number
type S = string
export default {
  agreeToCampaignTerm_1: `Ich habe die Regeln Gelesen`,
  agree_to_campaign_term_1: `Ich habe die Regeln Gelesen`,
  agreeToCampaignText_2: `Ich werde keinen Kontakt zu der Brand außerhalb dieser Plattform haben.`,
  agree_to_campaign_text_2: `Ich werde keinen Kontakt zu der Brand außerhalb dieser Plattform haben.`,
  applicationVideo: `Bewerbungsvideo`,
  application_video: `Bewerbungsvideo`,
  applyToTheCampaign: `Jetzt bewerben`,
  apply_to_the_campaign: `Jetzt bewerben`,
  back: `Zurück`,
  brandCreationContactEmail: `E-Mail Adresse*`,
  brand_creation_contact_email: `E-Mail Adresse*`,
  brandCreationContactFirstname: `Vorname*`,
  brand_creation_contact_firstname: `Vorname*`,
  brandCreationContactPhone: `Mobil*`,
  brand_creation_contact_phone: `Mobil*`,
  brandCreationContactSurname: `Nachname*`,
  brand_creation_contact_surname: `Nachname*`,
  brandCreationContactTitle: `AnsprechpartnerIn`,
  brand_creation_contact_title: `AnsprechpartnerIn`,
  brandCreationCreateAgency: `AGENTUR ERSTELLEN`,
  brand_creation_create_agency: `AGENTUR ERSTELLEN`,
  brandCreationCreateBrand: `BRAND ERSTELLEN`,
  brand_creation_create_brand: `BRAND ERSTELLEN`,
  brandCreationError: `oh nein, du hast was vergessen!`,
  brand_creation_error: `oh nein, du hast was vergessen!`,
  brandCreationInfoAgencyLogo: `Agentur Logo `,
  brand_creation_info_agency_logo: `Agentur Logo `,
  brandCreationInfoAgencyLogoTitle: `Agentur Logo (Optional)`,
  brand_creation_info_agency_logo_title: `Agentur Logo (Optional)`,
  brandCreationInfoAgencyName: `Agentur Name*`,
  brand_creation_info_agency_name: `Agentur Name*`,
  brandCreationInfoAgencyNameQuestion: `Wie heißt deine Agentur?`,
  brand_creation_info_agency_name_question: `Wie heißt deine Agentur?`,
  brandCreationInfoBrandLogo: `Brand Logo `,
  brand_creation_info_brand_logo: `Brand Logo `,
  brandCreationInfoBrandLogoTitle: `Brand Logo (Optional)`,
  brand_creation_info_brand_logo_title: `Brand Logo (Optional)`,
  brandCreationInfoBrandName: `Brand Name*`,
  brand_creation_info_brand_name: `Brand Name*`,
  brandCreationInfoBrandNameQuestion: `Wie heißt deine Brand?`,
  brand_creation_info_brand_name_question: `Wie heißt deine Brand?`,
  brandCreationInfoIndustry: `Branche`,
  brand_creation_info_industry: `Branche`,
  brandCreationInfoIndustryOptionAccessories: `Accessories (Jewellery, Watches etc.)`,
  brand_creation_info_industry_option_accessories: `Accessories (Jewellery, Watches etc.)`,
  brandCreationInfoIndustryOptionAgency: `Werbeagentur`,
  brand_creation_info_industry_option_agency: `Werbeagentur`,
  brandCreationInfoIndustryOptionApparel: `Fashion & Accessoires`,
  brand_creation_info_industry_option_apparel: `Fashion & Accessoires`,
  brandCreationInfoIndustryOptionBeauty: `Beauty`,
  brand_creation_info_industry_option_beauty: `Beauty`,
  brandCreationInfoIndustryOptionBeverages: `Beverages`,
  brand_creation_info_industry_option_beverages: `Beverages`,
  brandCreationInfoIndustryOptionBusiness: `Business Services`,
  brand_creation_info_industry_option_business: `Business Services`,
  brandCreationInfoIndustryOptionEducation: `Education`,
  brand_creation_info_industry_option_education: `Education`,
  brandCreationInfoIndustryOptionEntertainment: `Entertainment`,
  brand_creation_info_industry_option_entertainment: `Entertainment`,
  brandCreationInfoIndustryOptionFinancial: `Financial Services`,
  brand_creation_info_industry_option_financial: `Financial Services`,
  brandCreationInfoIndustryOptionFitness: `Fitness/Sports`,
  brand_creation_info_industry_option_fitness: `Fitness/Sports`,
  brandCreationInfoIndustryOptionFood: `Food`,
  brand_creation_info_industry_option_food: `Food`,
  brandCreationInfoIndustryOptionGames: `Games`,
  brand_creation_info_industry_option_games: `Games`,
  brandCreationInfoIndustryOptionHealth: `Health`,
  brand_creation_info_industry_option_health: `Health`,
  brandCreationInfoIndustryOptionJobs: `Jobs`,
  brand_creation_info_industry_option_jobs: `Jobs`,
  brandCreationInfoIndustryOptionMobile: `Mobile Apps`,
  brand_creation_info_industry_option_mobile: `Mobile Apps`,
  brandCreationInfoIndustryOptionOther: `Andere`,
  brand_creation_info_industry_option_other: `Andere`,
  brandCreationInfoIndustryOptionPets: `Pets & Animals`,
  brand_creation_info_industry_option_pets: `Pets & Animals`,
  brandCreationSectionAgencyInfo: `Agentur Info`,
  brand_creation_section_agency_info: `Agentur Info`,
  brandCreationSectionBrandInfo: `Brand Info`,
  brand_creation_section_brand_info: `Brand Info`,
  brandCreationSectionContact: `Ansprechpartner`,
  brand_creation_section_contact: `Ansprechpartner`,
  brandCreationSectionUsers: `Mitarbeiter`,
  brand_creation_section_users: `Mitarbeiter`,
  brandCreationUploadLogoError: `Fehler beim Hochladen des Logos. Die Brand wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Logo nachtragen können.`,
  brand_creation_upload_logo_error: `Fehler beim Hochladen des Logos. Die Brand wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Logo nachtragen können.`,
  brandCreationUsersBody: `Du kannst anderen Benutzern Rollen zuteilen welche folgende Funktionen haben:
          <br /> <br />
          <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten
          <br />
          <b>Marketer:</b> Kann Kampagnen bearbeiten
          <br />
          <b>Analyst:</b> Kann Kampagnen ansehen
          <br />
          <b>Accountant:</b> Kann nur Rechnungsübersicht einsehen`,
  brand_creation_users_body: `Du kannst anderen Benutzern Rollen zuteilen welche folgende Funktionen haben:
          <br /> <br />
          <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten
          <br />
          <b>Marketer:</b> Kann Kampagnen bearbeiten
          <br />
          <b>Analyst:</b> Kann Kampagnen ansehen
          <br />
          <b>Accountant:</b> Kann nur Rechnungsübersicht einsehen`,
  brandCreationUsersEmailLabel: `E-Mail Adresse`,
  brand_creation_users_email_label: `E-Mail Adresse`,
  brandCreationUsersInvite: `Einladen`,
  brand_creation_users_invite: `Einladen`,
  brandCreationUsersRoleAccountant: `ACCOUNTANT`,
  brand_creation_users_role_accountant: `ACCOUNTANT`,
  brandCreationUsersRoleAdmin: `ADMIN`,
  brand_creation_users_role_admin: `ADMIN`,
  brandCreationUsersRoleAnalyst: `ANALYST`,
  brand_creation_users_role_analyst: `ANALYST`,
  brandCreationUsersRoleLabel: `Rolle`,
  brand_creation_users_role_label: `Rolle`,
  brandCreationUsersRoleMarketer: `MARKETER`,
  brand_creation_users_role_marketer: `MARKETER`,
  brandCreationUsersTitle: `Weitere Nutzer`,
  brand_creation_users_title: `Weitere Nutzer`,
  brandCreationYupBrandName: `Brand Name ist ein Pflichtfeld`,
  brand_creation_yup_brand_name: `Brand Name ist ein Pflichtfeld`,
  brandCreationYupEmail: `Email ist ein Pflichtfeld`,
  brand_creation_yup_email: `Email ist ein Pflichtfeld`,
  brandCreationYupEmail1: `Ungültige Email Adresse`,
  brand_creation_yup_email1: `Ungültige Email Adresse`,
  brandCreationYupEmail2: `Email von Nutzern ist ein Pflichtfeld`,
  brand_creation_yup_email2: `Email von Nutzern ist ein Pflichtfeld`,
  brandCreationYupFirstname: `Vorname ist ein Plfichtfeld`,
  brand_creation_yup_firstname: `Vorname ist ein Plfichtfeld`,
  brandCreationYupIndustry: `Branche ist ein Pflichtfeld`,
  brand_creation_yup_industry: `Branche ist ein Pflichtfeld`,
  brandCreationYupMobile: `Mobilnummer ist ein Pflichtfeld`,
  brand_creation_yup_mobile: `Mobilnummer ist ein Pflichtfeld`,
  brandCreationYupMobile1: `Ungültige Mobilnummer`,
  brand_creation_yup_mobile1: `Ungültige Mobilnummer`,
  brandCreationYupRole: `Rolle ist ein Plichtfeld`,
  brand_creation_yup_role: `Rolle ist ein Plichtfeld`,
  brandCreationYupSurname: `Nachname ist ein Pflichtfeld`,
  brand_creation_yup_surname: `Nachname ist ein Pflichtfeld`,
  brandInvoicesCredit: `Stornorechnung`,
  brand_invoices_credit: `Stornorechnung`,
  brandInvoicesDebit: `Abbuchung`,
  brand_invoices_debit: `Abbuchung`,
  brandInvoicesDownload: `Rechnung herunterladen`,
  brand_invoices_download: `Rechnung herunterladen`,
  brandInvoicesDownloadError: `Bitte melde dich beim Nano Support`,
  brand_invoices_download_error: `Bitte melde dich beim Nano Support`,
  brandInvoicesEmpty: `Noch keine Rechnungen`,
  brand_invoices_empty: `Noch keine Rechnungen`,
  brandInvoicesError: `Unbekannter Fehler`,
  brand_invoices_error: `Unbekannter Fehler`,
  brandInvoicesMissingDate: `Fehlendes Datum`,
  brand_invoices_missing_date: `Fehlendes Datum`,
  brandInvoicesNoAuth: `Du hast keine Berechtigung diesen Bereich zu sehen.`,
  brand_invoices_no_auth: `Du hast keine Berechtigung diesen Bereich zu sehen.`,
  brandInvoicesNumber: `Rechnungsnummer`,
  brand_invoices_number: `Rechnungsnummer`,
  brandInvoicesTableDate: `Datum`,
  brand_invoices_table_date: `Datum`,
  brandInvoicesTableSum: `Summe`,
  brand_invoices_table_sum: `Summe`,
  brandInvoicesTableType: `Betreff`,
  brand_invoices_table_type: `Betreff`,
  brandPaymentBalanceCurrent: `aktuelles Guthaben:`,
  brand_payment_balance_current: `aktuelles Guthaben:`,
  brandPaymentCreditTitle: `Guthaben`,
  brand_payment_credit_title: `Guthaben`,
  brandPaymentCreditcardTitle: `Kreditkarte`,
  brand_payment_creditcard_title: `Kreditkarte`,
  brandPaymentMethodChoose: `Zahlungsmittel wählen`,
  brand_payment_method_choose: `Zahlungsmittel wählen`,
  brandPaymentMethodChooseError: `Fehler bei der Eingabe`,
  brand_payment_method_choose_error: `Fehler bei der Eingabe`,
  brandPaymentMethodNoRole: `Du hast keine Berechtigung das Zahlungsmittel zu ändern!`,
  brand_payment_method_no_role: `Du hast keine Berechtigung das Zahlungsmittel zu ändern!`,
  brandPaymentMethodSaved: (last4: P) => `Gespeichertes Zahlungsmittel endet mit den Ziffern ${last4 as S}` as const,
  brand_payment_method_saved: (last4: P) => `Gespeichertes Zahlungsmittel endet mit den Ziffern ${last4 as S}` as const,
  brandPaymentMethodSavedNothing: `Noch keines angegeben`,
  brand_payment_method_saved_nothing: `Noch keines angegeben`,
  brandPaymentMethodTitle: `Zahlungsmittel`,
  brand_payment_method_title: `Zahlungsmittel`,
  brandPaymentPricingButton: `Einfach hier klicken!`,
  brand_payment_pricing_button: `Einfach hier klicken!`,
  brandPaymentPricingEmail: `Ansonsten schreibe uns eine E-Mail unter`,
  brand_payment_pricing_email: `Ansonsten schreibe uns eine E-Mail unter`,
  brandPaymentPricingTitle: `Fragen zum Pricing`,
  brand_payment_pricing_title: `Fragen zum Pricing`,
  brandPaymentSave: `Zahlungsmittel speichern`,
  brand_payment_save: `Zahlungsmittel speichern`,
  brandPaymentSepaAgreement: ` Ich ermächtige/ Wir ermächtigen (A) Stylink Social Media GmbH and Stripe, Zahlungen von meinem/ unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein/ weisen
        wir unser Kreditinstitut an, die von Stylink Social Media GmbH auf mein/ unser Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann/ Wir können innerhalb von acht Wochen,
        beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.`,
  brand_payment_sepa_agreement: ` Ich ermächtige/ Wir ermächtigen (A) Stylink Social Media GmbH and Stripe, Zahlungen von meinem/ unserem Konto mittels Lastschrift einzuziehen. Zugleich (B) weise ich mein/ weisen
        wir unser Kreditinstitut an, die von Stylink Social Media GmbH auf mein/ unser Konto gezogenen Lastschriften einzulösen. Hinweis: Ich kann/ Wir können innerhalb von acht Wochen,
        beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.`,
  brandPaymentSepaTitle: `SEPA`,
  brand_payment_sepa_title: `SEPA`,
  brandSettingsBrandButtonSave: `Speichern`,
  brand_settings_brand_button_save: `Speichern`,
  brandSettingsBrandCompanyTitle: `Unternehmensinformationen`,
  brand_settings_brand_company_title: `Unternehmensinformationen`,
  brandSettingsBrandFirmAddressTitle: `Firmenanschrift*`,
  brand_settings_brand_firm_address_title: `Firmenanschrift*`,
  brandSettingsBrandFirmCityTitle: `Ort*`,
  brand_settings_brand_firm_city_title: `Ort*`,
  brandSettingsBrandFirmCountryTitle: `Land*`,
  brand_settings_brand_firm_country_title: `Land*`,
  brandSettingsBrandFirmNameTitle: `Firmenname*`,
  brand_settings_brand_firm_name_title: `Firmenname*`,
  brandSettingsBrandFirmZipTitle: `Postleitzahl*`,
  brand_settings_brand_firm_zip_title: `Postleitzahl*`,
  brandSettingsBrandLogoPlaceh: `Brandlogo hochladen*`,
  brand_settings_brand_logo_placeh: `Brandlogo hochladen*`,
  brandSettingsBrandLogoTitle: `Brandlogo`,
  brand_settings_brand_logo_title: `Brandlogo`,
  brandSettingsBrandNamePlaceh: `Brand Name*`,
  brand_settings_brand_name_placeh: `Brand Name*`,
  brandSettingsBrandNameTitle: `Wie heißt deine Brand?`,
  brand_settings_brand_name_title: `Wie heißt deine Brand?`,
  brandSettingsBrandUidnumberDisclaimer: `Wir können nach dem Zeitpunkt der Rechnungslegung keine Änderungen im Bezug auf die Umsatzsteuer vornehmen. Bitte versichere dich daher ob dein Unternehmen eine UID hat.`,
  brand_settings_brand_uidnumber_disclaimer: `Wir können nach dem Zeitpunkt der Rechnungslegung keine Änderungen im Bezug auf die Umsatzsteuer vornehmen. Bitte versichere dich daher ob dein Unternehmen eine UID hat.`,
  brandSettingsBrandUidnumberTitle: `UID Nummer*`,
  brand_settings_brand_uidnumber_title: `UID Nummer*`,
  brandSettingsBrandUserEmailTitle: `E-Mail Adresse*`,
  brand_settings_brand_user_email_title: `E-Mail Adresse*`,
  brandSettingsBrandUserFirstnameTitle: `Vorname*`,
  brand_settings_brand_user_firstname_title: `Vorname*`,
  brandSettingsBrandUserPhoneTitle: `Telefonnummer*`,
  brand_settings_brand_user_phone_title: `Telefonnummer*`,
  brandSettingsBrandUserSurnameTitle: `Nachname*`,
  brand_settings_brand_user_surname_title: `Nachname*`,
  brandSettingsBrandUserTitle: `AnsprechpartnerIn`,
  brand_settings_brand_user_title: `AnsprechpartnerIn`,
  brandSettingsBrandVatnumberTitle: `Mein Unternehmen hat eine Umsatzsteuernummer*`,
  brand_settings_brand_vatnumber_title: `Mein Unternehmen hat eine Umsatzsteuernummer*`,
  brandSettingsNavInvoices: `Rechnungen`,
  brand_settings_nav_invoices: `Rechnungen`,
  brandSettingsNavPayment: `Zahlung`,
  brand_settings_nav_payment: `Zahlung`,
  brandSettingsNavSettings: `Einstellungen`,
  brand_settings_nav_settings: `Einstellungen`,
  brandSettingsNavUsers: `BearbeiterInnen`,
  brand_settings_nav_users: `BearbeiterInnen`,
  brandUsersCampaignSubtitle: `Brand Management`,
  brand_users_campaign_subtitle: `Brand Management`,
  brandUsersCampaignTitle: `Kampagnen`,
  brand_users_campaign_title: `Kampagnen`,
  brandUsersEmailTitle: `E-Mail Adresse`,
  brand_users_email_title: `E-Mail Adresse`,
  brandUsersError: `Es gab einen Fehler probiere es erneut`,
  brand_users_error: `Es gab einen Fehler probiere es erneut`,
  brandUsersInfo: `<b>Du kannst anderen Benutzern Rollen zuteilen welche folgende Funktionen haben:</b>
      <br />
      <br />
      <span>
        <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten
      </span>
      <br />
      <span>
        <b>Marketer:</b> Kann Kampagnen bearbeiten
      </span>
      <br />
      <span>
        <b>Analyst:</b> Kann Kampagnen ansehen
      </span>
      <br />
      <span>
        <b>Accountant:</b> Kann nur Rechnungsübersicht einsehen
      </span>`,
  brand_users_info: `<b>Du kannst anderen Benutzern Rollen zuteilen welche folgende Funktionen haben:</b>
      <br />
      <br />
      <span>
        <b>Admin:</b> Kann Brandeinstellungen sowie Kampagnen bearbeiten
      </span>
      <br />
      <span>
        <b>Marketer:</b> Kann Kampagnen bearbeiten
      </span>
      <br />
      <span>
        <b>Analyst:</b> Kann Kampagnen ansehen
      </span>
      <br />
      <span>
        <b>Accountant:</b> Kann nur Rechnungsübersicht einsehen
      </span>`,
  brandUsersInsert: `BearbeiterInnen hinzufügen`,
  brand_users_insert: `BearbeiterInnen hinzufügen`,
  brandUsersInsertAdminOnly: `Nur Admins können BearbeiterInnen hinzufügen oder entfernen.`,
  brand_users_insert_admin_only: `Nur Admins können BearbeiterInnen hinzufügen oder entfernen.`,
  brandUsersInsertInvalidEmail: `Keine gültige E-Mail Adresse`,
  brand_users_insert_invalid_email: `Keine gültige E-Mail Adresse`,
  brandUsersInvite: `Einladen`,
  brand_users_invite: `Einladen`,
  brandUsersTableEmail: `E-Mail`,
  brand_users_table_email: `E-Mail`,
  brandUsersTableRole: `Rolle`,
  brand_users_table_role: `Rolle`,
  campaignAlertTitle: `Keine Daten!`,
  campaign_alert_title: `Keine Daten!`,
  campaignApplication: `Bewerbung`,
  campaign_application: `Bewerbung`,
  campaignApplicationVideoUpload: `Beispiel-Video hochladen`,
  campaign_application_video_upload: `Beispiel-Video hochladen`,
  campaignCreationAgeLabel: `Alter (mehrere auswählbar)`,
  campaign_creation_age_label: `Alter (mehrere auswählbar)`,
  campaignCreationBudget_10Discount: `10% Rabatt auf Videotyp`,
  campaign_creation_budget_10_discount: `10% Rabatt auf Videotyp`,
  campaignCreationBudget_5Discount: `5% Rabatt auf Videotyp`,
  campaign_creation_budget_5_discount: `5% Rabatt auf Videotyp`,
  campaignCreationBudgetCreator: (number: P) => `${number as S} Content Creator` as const,
  campaign_creation_budget_creator: (number: P) => `${number as S} Content Creator` as const,
  campaignCreationBudgetCreators: (number: P) => `${number as S} Content Creatorn` as const,
  campaign_creation_budget_creators: (number: P) => `${number as S} Content Creatorn` as const,
  campaignCreationBudgetInfo1: `Deine Bestellung wird von`,
  campaign_creation_budget_info1: `Deine Bestellung wird von`,
  campaignCreationBudgetInfo2: `ausgeführt.`,
  campaign_creation_budget_info2: `ausgeführt.`,
  campaignCreationBudgetQuestion: `Wie viele Videos möchtest du bestellen?`,
  campaign_creation_budget_question: `Wie viele Videos möchtest du bestellen?`,
  campaignCreationBudgetRecommended: `empfohlen`,
  campaign_creation_budget_recommended: `empfohlen`,
  campaignCreationBudgetShowcaseCreator: (number: P) => `${number as S} Content Creator` as const,
  campaign_creation_budget_showcase_creator: (number: P) => `${number as S} Content Creator` as const,
  campaignCreationBudgetShowcaseCreators: (number: P) => `${number as S} Content Creatorn` as const,
  campaign_creation_budget_showcase_creators: (number: P) => `${number as S} Content Creatorn` as const,
  campaignCreationBudgetTip: `Wir empfehlen, dass du mehrere Creator dasselbe Videokonzept ausarbeiten lässt um aus den besten Inhalten auswählen zu können.`,
  campaign_creation_budget_tip: `Wir empfehlen, dass du mehrere Creator dasselbe Videokonzept ausarbeiten lässt um aus den besten Inhalten auswählen zu können.`,
  campaignCreationBudgetTooltip: `Du kannst eine Kampagne mit allen deinen verfügbaren Videos erstellen oder diese in mehrere Kampagnen aufteilen.`,
  campaign_creation_budget_tooltip: `Du kannst eine Kampagne mit allen deinen verfügbaren Videos erstellen oder diese in mehrere Kampagnen aufteilen.`,
  campaignCreationBudgetVideoCount: `Videoanzahl`,
  campaign_creation_budget_video_count: `Videoanzahl`,
  campaignCreationBudgetVideoCountShowcaseTip: `Online Marketer erzielen die besten Ergebnisse mit 2-3 Showcase Videos pro Werbekampagne.`,
  campaign_creation_budget_video_count_showcase_tip: `Online Marketer erzielen die besten Ergebnisse mit 2-3 Showcase Videos pro Werbekampagne.`,
  campaignCreationBudgetVideoCountTip: `Online Marketer erzielen die besten Ergebnisse mit 3-5 Creators pro Werbekampagne.`,
  campaign_creation_budget_video_count_tip: `Online Marketer erzielen die besten Ergebnisse mit 3-5 Creators pro Werbekampagne.`,
  campaignCreationBudgetVideosLabel: `Videos`,
  campaign_creation_budget_videos_label: `Videos`,
  campaignCreationCodesBody: `Stelle sicher, dass die Creator das Produkt kostenlos bestellen können und keine Versandkosten zahlen müssen.`,
  campaign_creation_codes_body: `Stelle sicher, dass die Creator das Produkt kostenlos bestellen können und keine Versandkosten zahlen müssen.`,
  campaignCreationCodesCodeLabel: `Gutscheincode`,
  campaign_creation_codes_code_label: `Gutscheincode`,
  campaignCreationCodesCountLabel: `Anzahl`,
  campaign_creation_codes_count_label: `Anzahl`,
  campaignCreationCodesShowcaseBody: `Stelle sicher, dass die Creator das Produkt kostenlos bestellen können und keine Versandkosten zahlen müssen.`,
  campaign_creation_codes_showcase_body: `Stelle sicher, dass die Creator das Produkt kostenlos bestellen können und keine Versandkosten zahlen müssen.`,
  campaignCreationCodesTitle: `Gutscheincodes`,
  campaign_creation_codes_title: `Gutscheincodes`,
  campaignCreationContentCtasAddButton: (i: P) => `+ alternatives Ende (€${i as S})` as const,
  campaign_creation_content_ctas_add_button: (i: P) => `+ alternatives Ende (€${i as S})` as const,
  campaignCreationContentCtasDesc: ``,
  campaign_creation_content_ctas_desc: ``,
  campaignCreationContentCtasPlaceh: (i: P) => `Call-to-Action ${i as S}` as const,
  campaign_creation_content_ctas_placeh: (i: P) => `Call-to-Action ${i as S}` as const,
  campaignCreationContentCtasPlaceholder1: `Sprich eine Kaufempfehlung aus. Jetzt mit Code -20% erhalten.`,
  campaign_creation_content_ctas_placeholder1: `Sprich eine Kaufempfehlung aus. Jetzt mit Code -20% erhalten.`,
  campaignCreationContentCtasTitle: `Ende / Call-To-Action (Optional)`,
  campaign_creation_content_ctas_title: `Ende / Call-To-Action (Optional)`,
  campaignCreationContentHooksAddButton: (i: P) => `+ alternativen Hook (€${i as S})` as const,
  campaign_creation_content_hooks_add_button: (i: P) => `+ alternativen Hook (€${i as S})` as const,
  campaignCreationContentHooksDesc: ``,
  campaign_creation_content_hooks_desc: ``,
  campaignCreationContentHooksPlaceh: (i: P) => `Hook ${i as S}` as const,
  campaign_creation_content_hooks_placeh: (i: P) => `Hook ${i as S}` as const,
  campaignCreationContentHooksPlaceholder1: `Begrüßung, High Energy, Innenkamera`,
  campaign_creation_content_hooks_placeholder1: `Begrüßung, High Energy, Innenkamera`,
  campaignCreationContentHooksTitle: `Intro / Hook (Optional)`,
  campaign_creation_content_hooks_title: `Intro / Hook (Optional)`,
  campaignCreationContentMentionsDesc: `Lege hier Informationen fest, die von den Creatorn erwähnt werden sollen.`,
  campaign_creation_content_mentions_desc: `Lege hier Informationen fest, die von den Creatorn erwähnt werden sollen.`,
  campaignCreationContentMentionsHonestDesc: `Lege hier Don'ts fest welche die Creator nicht erwähnen sollen.`,
  campaign_creation_content_mentions_honest_desc: `Lege hier Don'ts fest welche die Creator nicht erwähnen sollen.`,
  campaignCreationContentMentionsHonestLabel: (i: P) => `Don't ${i as S}` as const,
  campaign_creation_content_mentions_honest_label: (i: P) => `Don't ${i as S}` as const,
  campaignCreationContentMentionsHonestTitle: `Don'ts (optional)`,
  campaign_creation_content_mentions_honest_title: `Don'ts (optional)`,
  campaignCreationContentMentionsLabel: (i: P) => `Info ${i as S}` as const,
  campaign_creation_content_mentions_label: (i: P) => `Info ${i as S}` as const,
  campaignCreationContentMentionsShowcaseDesc: `Lege hier Anmerkungen fest auf welche beim Showcase Dreh besonders geachtet werden sollen.`,
  campaign_creation_content_mentions_showcase_desc: `Lege hier Anmerkungen fest auf welche beim Showcase Dreh besonders geachtet werden sollen.`,
  campaignCreationContentMentionsShowcaseLabel: (i: P) => `Info ${i as S}` as const,
  campaign_creation_content_mentions_showcase_label: (i: P) => `Info ${i as S}` as const,
  campaignCreationContentMentionsShowcaseTitle: `Zusatzinfo`,
  campaign_creation_content_mentions_showcase_title: `Zusatzinfo`,
  campaignCreationContentMentionsTitle: `Zusatzinfo (optional)`,
  campaign_creation_content_mentions_title: `Zusatzinfo (optional)`,
  campaignCreationContentMoodboardBody: `Lade bis zu 6 Bilder/Videos hoch, die den Creatorn als Inspiration dienen sollen.\nZeige uns auch gerne, wie du dir deine Untertitel, Musik oder Animationen wünscht.`,
  campaign_creation_content_moodboard_body: `Lade bis zu 6 Bilder/Videos hoch, die den Creatorn als Inspiration dienen sollen.\nZeige uns auch gerne, wie du dir deine Untertitel, Musik oder Animationen wünscht.`,
  campaignCreationContentMoodboardBody1: `Zeige den Creatorn, wie du dir ihre Videos vorstellst.`,
  campaign_creation_content_moodboard_body1: `Zeige den Creatorn, wie du dir ihre Videos vorstellst.`,
  campaignCreationContentMoodboardBody2: `Füge bis zu 6 Bilder oder Videos hinzu, die den Creatorn als Inspiration für ihre Videos dienen sollen.`,
  campaign_creation_content_moodboard_body2: `Füge bis zu 6 Bilder oder Videos hinzu, die den Creatorn als Inspiration für ihre Videos dienen sollen.`,
  campaignCreationContentMoodboardDontUse: `Moodboard nicht verwenden`,
  campaign_creation_content_moodboard_dont_use: `Moodboard nicht verwenden`,
  campaignCreationContentMoodboardTitle: `Moodboard (optional)`,
  campaign_creation_content_moodboard_title: `Moodboard (optional)`,
  campaignCreationContentMoodboardUse: `Moodboard verwenden`,
  campaign_creation_content_moodboard_use: `Moodboard verwenden`,
  campaignCreationContentNanoStoryboardDesc: `Ein Experte von unserem Team erstellt ein Storyboard für die Creator. `,
  campaign_creation_content_nano_storyboard_desc: `Ein Experte von unserem Team erstellt ein Storyboard für die Creator. `,
  campaignCreationContentNanoStoryboardLabel: `getnano Storyboard`,
  campaign_creation_content_nano_storyboard_label: `getnano Storyboard`,
  campaignCreationContentNoVoiceOverDesc: `360° Premium Video ohne Voice-Over`,
  campaign_creation_content_no_voice_over_desc: `360° Premium Video ohne Voice-Over`,
  campaignCreationContentNoVoiceOverLabel: `Kein Voice-Over`,
  campaign_creation_content_no_voice_over_label: `Kein Voice-Over`,
  campaignCreationContentProductPrefPlaceholder1: `Begrüßung, High Energy, Innenkamera`,
  campaign_creation_content_product_pref_placeholder1: `Begrüßung, High Energy, Innenkamera`,
  campaignCreationContentProductPrefPlaceholder2: `Außenkamera, T-Shirt auf neutralen Hintergrund legen und Vorteile erklären`,
  campaign_creation_content_product_pref_placeholder2: `Außenkamera, T-Shirt auf neutralen Hintergrund legen und Vorteile erklären`,
  campaignCreationContentProductPrefPlaceholder3: `Innenkamera, T-Shirt angezogen und Aufruf, dass man auf Website kaufen soll`,
  campaign_creation_content_product_pref_placeholder3: `Innenkamera, T-Shirt angezogen und Aufruf, dass man auf Website kaufen soll`,
  campaignCreationContentScenePlaceh: `Szenen`,
  campaign_creation_content_scene_placeh: `Szenen`,
  campaignCreationContentScenesDesc: `Beschreibe durch einzelne Szenen wie das Video aufgebaut sein soll.`,
  campaign_creation_content_scenes_desc: `Beschreibe durch einzelne Szenen wie das Video aufgebaut sein soll.`,
  campaignCreationContentScenesPlaceholder1: `Außenkamera, setze das T-Shirt auf neutralem Hintergrund in Szene.
Innenkamera, zeige wie das T-Shirt trägst und sprich über die Qualität.
Screenrecording vom Onlineshop mit VoiceOver`,
  campaign_creation_content_scenes_placeholder1: `Außenkamera, setze das T-Shirt auf neutralem Hintergrund in Szene.
Innenkamera, zeige wie das T-Shirt trägst und sprich über die Qualität.
Screenrecording vom Onlineshop mit VoiceOver`,
  campaignCreationContentScenesShowcaseTitle: `Inhalt des Voiceovers`,
  campaign_creation_content_scenes_showcase_title: `Inhalt des Voiceovers`,
  campaignCreationContentScenesTitle: `Ablauf`,
  campaign_creation_content_scenes_title: `Ablauf`,
  campaignCreationContentSelfServiceDesc: `Du legst selbst die Szenen und weitere Inspirationen für die Creator fest.`,
  campaign_creation_content_self_service_desc: `Du legst selbst die Szenen und weitere Inspirationen für die Creator fest.`,
  campaignCreationContentSelfServiceLabel: `Self-Service`,
  campaign_creation_content_self_service_label: `Self-Service`,
  campaignCreationContentStandardServiceDesc: `Die Creator erstellen Videos passend zu deinem Produkt.`,
  campaign_creation_content_standard_service_desc: `Die Creator erstellen Videos passend zu deinem Produkt.`,
  campaignCreationContentStandardServiceLabel: `Standard`,
  campaign_creation_content_standard_service_label: `Standard`,
  campaignCreationContentVoiceOverDesc: `Premium Voice-Over nach deinen Vorgaben`,
  campaign_creation_content_voice_over_desc: `Premium Voice-Over nach deinen Vorgaben`,
  campaignCreationContentVoiceOverDescription: `Beschreibe die einzelnen Abschnitte deines Voice-Overs.`,
  campaign_creation_content_voice_over_description: `Beschreibe die einzelnen Abschnitte deines Voice-Overs.`,
  campaignCreationContentVoiceOverLabel: `Voice-Over`,
  campaign_creation_content_voice_over_label: `Voice-Over`,
  campaignCreationContentVoiceOverPlaceh: (i: P) => `Voice-Over ${i as S}` as const,
  campaign_creation_content_voice_over_placeh: (i: P) => `Voice-Over ${i as S}` as const,
  campaignCreationCreate: `CHECKOUT`,
  campaign_creation_create: `CHECKOUT`,
  campaignCreationDeliveryTitle: `Zustellung`,
  campaign_creation_delivery_title: `Zustellung`,
  campaignCreationDisclaimerBody: `Kommt es zu Verzögerungen, die von deiner Seite verschuldet werden, dann wirkt sich dies auch auf das Lieferdatum aus.`,
  campaign_creation_disclaimer_body: `Kommt es zu Verzögerungen, die von deiner Seite verschuldet werden, dann wirkt sich dies auch auf das Lieferdatum aus.`,
  campaignCreationDisclaimerContactCheckLabel: `Ich nehme zur Kenntnis, dass ich außerhalb der Plattform keinen Kontakt mit den Creatorn aufnehmen darf.`,
  campaign_creation_disclaimer_contact_check_label: `Ich nehme zur Kenntnis, dass ich außerhalb der Plattform keinen Kontakt mit den Creatorn aufnehmen darf.`,
  campaignCreationDisclaimerInfoCheckLabel: `Ich nehme diese Information zur Kenntniss.`,
  campaign_creation_disclaimer_info_check_label: `Ich nehme diese Information zur Kenntniss.`,
  campaignCreationDisclaimerNoPaymentCheck: `Keine Rechnung ausstellen. Rechnung wurde bereits außerhalb von Stripe erstellt. (Admin only)`,
  campaign_creation_disclaimer_no_payment_check: `Keine Rechnung ausstellen. Rechnung wurde bereits außerhalb von Stripe erstellt. (Admin only)`,
  campaignCreationDisclaimerTitle: `Voraussichtliches Lieferdatum`,
  campaign_creation_disclaimer_title: `Voraussichtliches Lieferdatum`,
  campaignCreationError: `oh nein, du hast was vergessen!`,
  campaign_creation_error: `oh nein, du hast was vergessen!`,
  campaignCreationExample: `Beispiel`,
  campaign_creation_example: `Beispiel`,
  campaignCreationFormatTitle: `Video Format`,
  campaign_creation_format_title: `Video Format`,
  campaignCreationGenderAny: `Alle Geschlechter`,
  campaign_creation_gender_any: `Alle Geschlechter`,
  campaignCreationGenderDiverse: `Divers`,
  campaign_creation_gender_diverse: `Divers`,
  campaignCreationGenderFemale: `Weiblich`,
  campaign_creation_gender_female: `Weiblich`,
  campaignCreationGenderLabel: `Geschlecht`,
  campaign_creation_gender_label: `Geschlecht`,
  campaignCreationGenderMale: `Männlich`,
  campaign_creation_gender_male: `Männlich`,
  campaignCreationGeneralCampaignImageLabel: `Produktbild`,
  campaign_creation_general_campaign_image_label: `Produktbild`,
  campaignCreationGeneralProductDescLabel: `Beschreibe dein Produkt kurz.*`,
  campaign_creation_general_product_desc_label: `Beschreibe dein Produkt kurz.*`,
  campaignCreationGeneralProductInfo: `Produktinformationen`,
  campaign_creation_general_product_info: `Produktinformationen`,
  campaignCreationGeneralProductInfoBody: `Beschreibe, welches Produkt die Content Creator bewerben sollen.`,
  campaign_creation_general_product_info_body: `Beschreibe, welches Produkt die Content Creator bewerben sollen.`,
  campaignCreationGeneralProductInfoShowcaseBody: `Beschreibe, welches Produkt die Content Creator bewerben sollen.`,
  campaign_creation_general_product_info_showcase_body: `Beschreibe, welches Produkt die Content Creator bewerben sollen.`,
  campaignCreationGeneralProductLinkLabel: `Link zum Produkt/Website*`,
  campaign_creation_general_product_link_label: `Link zum Produkt/Website*`,
  campaignCreationGeneralProductNameLabel: `Produktname*`,
  campaign_creation_general_product_name_label: `Produktname*`,
  campaignCreationGeneralProductPriceDisclaimer: `Bitte hier Selbstkosten statt Verkaufspreis angeben. `,
  campaign_creation_general_product_price_disclaimer: `Bitte hier Selbstkosten statt Verkaufspreis angeben. `,
  campaignCreationGeneralProductPriceLabel: `Wert in €*`,
  campaign_creation_general_product_price_label: `Wert in €*`,
  campaignCreationGeneralProductType: `Produktart`,
  campaign_creation_general_product_type: `Produktart`,
  campaignCreationGeneralProductTypeDigital: `Digitales Produkt`,
  campaign_creation_general_product_type_digital: `Digitales Produkt`,
  campaignCreationGeneralProductTypePhysical: `Physisches Produkt`,
  campaign_creation_general_product_type_physical: `Physisches Produkt`,
  campaignCreationGeneralProducttypeDigitalDesc: `Für digitale Produkte (App, Software, etc.)`,
  campaign_creation_general_producttype_digital_desc: `Für digitale Produkte (App, Software, etc.)`,
  campaignCreationGeneralProducttypeDigitalTitle: `Digital`,
  campaign_creation_general_producttype_digital_title: `Digital`,
  campaignCreationGeneralProducttypePhysicalDesc: `Lasse dein Produkt den ausgewählten Content Creatorn zukommen. Sie können es sich nach Abschluss des Auftrags behalten.`,
  campaign_creation_general_producttype_physical_desc: `Lasse dein Produkt den ausgewählten Content Creatorn zukommen. Sie können es sich nach Abschluss des Auftrags behalten.`,
  campaignCreationGeneralProducttypePhysicalShowcaseDesc: `Lasse dein Produkt einem ausgewähltem Creator aus unserem Team zukommen. Er kann sich das Produkt nach Abschluss des Auftrags behalten.`,
  campaign_creation_general_producttype_physical_showcase_desc: `Lasse dein Produkt einem ausgewähltem Creator aus unserem Team zukommen. Er kann sich das Produkt nach Abschluss des Auftrags behalten.`,
  campaignCreationGeneralProducttypePhysicalTitle: `Physisch`,
  campaign_creation_general_producttype_physical_title: `Physisch`,
  campaignCreationInviteBody: `Mit welchen Creatorn möchtest du erneut zusammenarbeiten?`,
  campaign_creation_invite_body: `Mit welchen Creatorn möchtest du erneut zusammenarbeiten?`,
  campaignCreationInviteTitle: `Creator einladen`,
  campaign_creation_invite_title: `Creator einladen`,
  campaignCreationLanguageEnglish: `Englisch`,
  campaign_creation_language_english: `Englisch`,
  campaignCreationLanguageGerman: `Deutsch`,
  campaign_creation_language_german: `Deutsch`,
  campaignCreationLanguageLabel: `Videosprache`,
  campaign_creation_language_label: `Videosprache`,
  campaignCreationLocaleDeliveryDesc: `Du erhältst die Adressen von teilnehmenden Creator und lässt ihnen dein Produkt per Post zukommen.`,
  campaign_creation_locale_delivery_desc: `Du erhältst die Adressen von teilnehmenden Creator und lässt ihnen dein Produkt per Post zukommen.`,
  campaignCreationLocaleDeliveryTitle: `Per Post`,
  campaign_creation_locale_delivery_title: `Per Post`,
  campaignCreationLocaleOnlineDesc: `Die Creator erhalten einen Gutscheincode, den sie in einem Onlineshop oder vor Ort einlösen können.`,
  campaign_creation_locale_online_desc: `Die Creator erhalten einen Gutscheincode, den sie in einem Onlineshop oder vor Ort einlösen können.`,
  campaignCreationLocaleOnlineTitle: `Per Gutschein`,
  campaign_creation_locale_online_title: `Per Gutschein`,
  campaignCreationPersistBody: `Du hast noch einen Auftrag zwischengespeichert. Willst du diese Daten laden?`,
  campaign_creation_persist_body: `Du hast noch einen Auftrag zwischengespeichert. Willst du diese Daten laden?`,
  campaignCreationPersistDelete: `Löschen`,
  campaign_creation_persist_delete: `Löschen`,
  campaignCreationPersistTitle: `Zwischengespeicherte Daten Laden`,
  campaign_creation_persist_title: `Zwischengespeicherte Daten Laden`,
  campaignCreationPreferences: `Produktpräferenzen`,
  campaign_creation_preferences: `Produktpräferenzen`,
  campaignCreationPreferencesNo: `Das Produkt ist universal für alle geeignet.`,
  campaign_creation_preferences_no: `Das Produkt ist universal für alle geeignet.`,
  campaignCreationPreferencesPlaceh: `Bsp. Welche Kleidergröße trägst du?`,
  campaign_creation_preferences_placeh: `Bsp. Welche Kleidergröße trägst du?`,
  campaignCreationPreferencesQuestion: (i: P) => `Frage ${i as S}` as const,
  campaign_creation_preferences_question: (i: P) => `Frage ${i as S}` as const,
  campaignCreationPreferencesTooltip: `Brauchst du bestimmte Angaben wie z.B. Schuhgröße der Creator, um ihnen das passende Produkt zukommen zu lassen? Dann formuliere hier passende Fragen um die notwendigen Informationen zu sammeln.`,
  campaign_creation_preferences_tooltip: `Brauchst du bestimmte Angaben wie z.B. Schuhgröße der Creator, um ihnen das passende Produkt zukommen zu lassen? Dann formuliere hier passende Fragen um die notwendigen Informationen zu sammeln.`,
  campaignCreationPreferencesYes: `Ich benötige weitere Informationen von den Creatorn.`,
  campaign_creation_preferences_yes: `Ich benötige weitere Informationen von den Creatorn.`,
  campaignCreationProductOptionNo: `Nein, die Creator brauchen mein Produkt nicht, um es vorzustellen`,
  campaign_creation_product_option_no: `Nein, die Creator brauchen mein Produkt nicht, um es vorzustellen`,
  campaignCreationProductTypeQuestion: `Wirst du den Creatorn dein Produkt zukommen lassen?`,
  campaign_creation_product_type_question: `Wirst du den Creatorn dein Produkt zukommen lassen?`,
  campaignCreationRequirements: `Anforderungen an die Creator`,
  campaign_creation_requirements: `Anforderungen an die Creator`,
  campaignCreationSectionBrand: `BrandInfo`,
  campaign_creation_section_brand: `BrandInfo`,
  campaignCreationSectionBudget: `Anzahl`,
  campaign_creation_section_budget: `Anzahl`,
  campaignCreationSectionContent: `Inhalte`,
  campaign_creation_section_content: `Inhalte`,
  campaignCreationSectionInvite: `Einladen`,
  campaign_creation_section_invite: `Einladen`,
  campaignCreationSectionProduct: `Produkt`,
  campaign_creation_section_product: `Produkt`,
  campaignCreationSectionRequirements: `Anforderungen`,
  campaign_creation_section_requirements: `Anforderungen`,
  campaignCreationSectionVideoType: `Videotyp`,
  campaign_creation_section_video_type: `Videotyp`,
  campaignCreationTimelineApplications: (endDate: P) => `Bewerber auswählen\nbis ${endDate as S}` as const,
  campaign_creation_timeline_applications: (endDate: P) => `Bewerber auswählen\nbis ${endDate as S}` as const,
  campaignCreationTimelineDelivery: (endDate: P) => `Produkt versenden\nbis ${endDate as S}` as const,
  campaign_creation_timeline_delivery: (endDate: P) => `Produkt versenden\nbis ${endDate as S}` as const,
  campaignCreationTimelineFeedback: (endDate: P) => `Video erhalten\nbis ${endDate as S}` as const,
  campaign_creation_timeline_feedback: (endDate: P) => `Video erhalten\nbis ${endDate as S}` as const,
  campaignCreationTimelineVideos: (endDate: P) => `Feedback geben (Optional)\nbis ${endDate as S}` as const,
  campaign_creation_timeline_videos: (endDate: P) => `Feedback geben (Optional)\nbis ${endDate as S}` as const,
  campaignCreationUpdate: `UPDATE SPEICHERN`,
  campaign_creation_update: `UPDATE SPEICHERN`,
  campaignCreationUploadBrandLogoError: `Fehler beim Hochladen des Brandbildes. Die Kampagne wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Bild nachtragen können.`,
  campaign_creation_upload_brand_logo_error: `Fehler beim Hochladen des Brandbildes. Die Kampagne wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Bild nachtragen können.`,
  campaignCreationUploadCampaignImageError: `Fehler beim Hochladen des Kampagnenbildes. Die Kampagne wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Bild nachtragen können.`,
  campaign_creation_upload_campaign_image_error: `Fehler beim Hochladen des Kampagnenbildes. Die Kampagne wird dennoch erstellt. Bitte bei ugc@stylink.com melden, damit wir das fehlende Bild nachtragen können.`,
  campaignCreationUploadMoodboardError: `Fehler beim Hochladen des Moodboards`,
  campaign_creation_upload_moodboard_error: `Fehler beim Hochladen des Moodboards`,
  campaignCreationVideoDuration_15: `10 - 15 Sekunden`,
  campaign_creation_video_duration_15: `10 - 15 Sekunden`,
  campaignCreationVideoDuration_30: `25 - 30 Sekunden`,
  campaign_creation_video_duration_30: `25 - 30 Sekunden`,
  campaignCreationVideoDuration_60: `60 Sekunden`,
  campaign_creation_video_duration_60: `60 Sekunden`,
  campaignCreationVideoDurationPrice: (price: P) => `€${price as S} / Sek` as const,
  campaign_creation_video_duration_price: (price: P) => `€${price as S} / Sek` as const,
  campaignCreationVideoDurationTitle: `Videodauer`,
  campaign_creation_video_duration_title: `Videodauer`,
  campaignCreationVideoFeaturesInfo: `Diese Zusatzleistungen werden direkt von unserem Team hinzugefügt.`,
  campaign_creation_video_features_info: `Diese Zusatzleistungen werden direkt von unserem Team hinzugefügt.`,
  campaignCreationVideoFeaturesTitle: `Zusatzleistungen (optional)`,
  campaign_creation_video_features_title: `Zusatzleistungen (optional)`,
  campaignCreationVideoFormatFullscreen: `Querformat`,
  campaign_creation_video_format_fullscreen: `Querformat`,
  campaignCreationVideoFormatHorizontal: `Querformat`,
  campaign_creation_video_format_horizontal: `Querformat`,
  campaignCreationVideoFormatHorizontalPlatform: `YouTube`,
  campaign_creation_video_format_horizontal_platform: `YouTube`,
  campaignCreationVideoFormatSquare: `Quadratisch`,
  campaign_creation_video_format_square: `Quadratisch`,
  campaignCreationVideoFormatVertical: `Hochformat`,
  campaign_creation_video_format_vertical: `Hochformat`,
  campaignCreationVideoFormatVerticalPlatform: `Instagram | TikTok`,
  campaign_creation_video_format_vertical_platform: `Instagram | TikTok`,
  campaignCreationVideoTypeAdDesc: `Ideal für Werbeschaltungen auf Social Media.`,
  campaign_creation_video_type_ad_desc: `Ideal für Werbeschaltungen auf Social Media.`,
  campaignCreationVideoTypeAdTitle: `UGC Video Ad`,
  campaign_creation_video_type_ad_title: `UGC Video Ad`,
  campaignCreationVideoTypeExampleTitle: `Beispiel Video`,
  campaign_creation_video_type_example_title: `Beispiel Video`,
  campaignCreationVideoTypeHonestDesc: `Creator geben einen ehrlichen Erfahrungsbericht über dein Produkt.`,
  campaign_creation_video_type_honest_desc: `Creator geben einen ehrlichen Erfahrungsbericht über dein Produkt.`,
  campaignCreationVideoTypeHonestTitle: `Honest Review`,
  campaign_creation_video_type_honest_title: `Honest Review`,
  campaignCreationVideoTypePremiumDesc: `UGC Videos von erfahrenen Creatorn. Im Detail: bessere Beleuchtung, Tonqualität etc.`,
  campaign_creation_video_type_premium_desc: `UGC Videos von erfahrenen Creatorn. Im Detail: bessere Beleuchtung, Tonqualität etc.`,
  campaignCreationVideoTypePremiumTitle: `UGC Premium Video`,
  campaign_creation_video_type_premium_title: `UGC Premium Video`,
  campaignCreationVideoTypeShowcaseDesc: `Zeige dein Produkt in einem 360° Video. Erstellt von einem Premium Creator unseres Teams.`,
  campaign_creation_video_type_showcase_desc: `Zeige dein Produkt in einem 360° Video. Erstellt von einem Premium Creator unseres Teams.`,
  campaignCreationVideoTypeShowcaseTitle: `360° Showcase`,
  campaign_creation_video_type_showcase_title: `360° Showcase`,
  campaignCreationVideoTypeTitle: `Videotyp`,
  campaign_creation_video_type_title: `Videotyp`,
  campaignCreationVoiceOverAny: `Egal`,
  campaign_creation_voice_over_any: `Egal`,
  campaignCreationVoiceOverComputer: `Computer`,
  campaign_creation_voice_over_computer: `Computer`,
  campaignCreationVoiceOverFemale: `Weiblich`,
  campaign_creation_voice_over_female: `Weiblich`,
  campaignCreationVoiceOverLabel: `Voice-Over`,
  campaign_creation_voice_over_label: `Voice-Over`,
  campaignCreationVoiceOverMale: `Männlich`,
  campaign_creation_voice_over_male: `Männlich`,
  campaignCreationVoiceOverNone: `Kein Voice-Over`,
  campaign_creation_voice_over_none: `Kein Voice-Over`,
  campaignCreationYupAge: `Alter ist ein Plichtfeld`,
  campaign_creation_yup_age: `Alter ist ein Plichtfeld`,
  campaignCreationYupBrandLogo: `Logo ist ein Pflichtfeld`,
  campaign_creation_yup_brand_logo: `Logo ist ein Pflichtfeld`,
  campaignCreationYupCampaignImage: `Produktbild ist ein Pflichtfeld`,
  campaign_creation_yup_campaign_image: `Produktbild ist ein Pflichtfeld`,
  campaignCreationYupCode: (number: P) => `Du musst mindestens ${number as S} Code hinzufügen` as const,
  campaign_creation_yup_code: (number: P) => `Du musst mindestens ${number as S} Code hinzufügen` as const,
  campaignCreationYupCodePlural: (number: P) => `Du musst mindestens ${number as S} Codes hinzufügen` as const,
  campaign_creation_yup_code_plural: (number: P) => `Du musst mindestens ${number as S} Codes hinzufügen` as const,
  campaignCreationYupCodes: `Gutscheincode ist ein Pflichtfeld`,
  campaign_creation_yup_codes: `Gutscheincode ist ein Pflichtfeld`,
  campaignCreationYupCodesCount1: `Gutscheinanzahl muss eine Zahl sein`,
  campaign_creation_yup_codes_count1: `Gutscheinanzahl muss eine Zahl sein`,
  campaignCreationYupCodesCount2: `Gutscheinanzahl ist ein Pflichtfeld`,
  campaign_creation_yup_codes_count2: `Gutscheinanzahl ist ein Pflichtfeld`,
  campaignCreationYupContactCheck: `Bitte bestätige, dass du nur über die Plattform mit den Creatorn in Kontakt trittst`,
  campaign_creation_yup_contact_check: `Bitte bestätige, dass du nur über die Plattform mit den Creatorn in Kontakt trittst`,
  campaignCreationYupGender: `Geschlecht ist ein Pflichtfeld`,
  campaign_creation_yup_gender: `Geschlecht ist ein Pflichtfeld`,
  campaignCreationYupHooksCtasLimit: `Maximal 200 Zeichen möglich`,
  campaign_creation_yup_hooks_ctas_limit: `Maximal 200 Zeichen möglich`,
  campaignCreationYupInfoCheck: `Bitte bestätige, dass du die Information zur Kenntnis genommen hast`,
  campaign_creation_yup_info_check: `Bitte bestätige, dass du die Information zur Kenntnis genommen hast`,
  campaignCreationYupLanguage: `Sprache ist ein Pflichtfeld`,
  campaign_creation_yup_language: `Sprache ist ein Pflichtfeld`,
  campaignCreationYupLink1: `Ungültige URL`,
  campaign_creation_yup_link1: `Ungültige URL`,
  campaignCreationYupLink2: `Website ist ein Pflichtfeld`,
  campaign_creation_yup_link2: `Website ist ein Pflichtfeld`,
  campaignCreationYupLocale: `Zustellungsart ist ein Pflichfeld`,
  campaign_creation_yup_locale: `Zustellungsart ist ein Pflichfeld`,
  campaignCreationYupMetionsLimit: `Maximal 120 Zeichen möglich`,
  campaign_creation_yup_metions_limit: `Maximal 120 Zeichen möglich`,
  campaignCreationYupName: `Name ist ein Pflichtfeld`,
  campaign_creation_yup_name: `Name ist ein Pflichtfeld`,
  campaignCreationYupNumberOfSlots1: `Anzahl der Videos muss mindestens als 1 sein`,
  campaign_creation_yup_number_of_slots1: `Anzahl der Videos muss mindestens als 1 sein`,
  campaignCreationYupNumberOfSlots2: `Anzahl der Videos ist ein Pflichtfeld`,
  campaign_creation_yup_number_of_slots2: `Anzahl der Videos ist ein Pflichtfeld`,
  campaignCreationYupNumberOfSlots3: `Es sind maximal 10 Videos möglich`,
  campaign_creation_yup_number_of_slots3: `Es sind maximal 10 Videos möglich`,
  campaignCreationYupPrice1: `Preis muss eine Zahl sein`,
  campaign_creation_yup_price1: `Preis muss eine Zahl sein`,
  campaignCreationYupPrice2: `Preis muss größer sein als 0€`,
  campaign_creation_yup_price2: `Preis muss größer sein als 0€`,
  campaignCreationYupPrice3: `Preis ist ein Pflichtfeld`,
  campaign_creation_yup_price3: `Preis ist ein Pflichtfeld`,
  campaignCreationYupProductDesc: `Beschreibung ist ein Pflichtfeld`,
  campaign_creation_yup_product_desc: `Beschreibung ist ein Pflichtfeld`,
  campaignCreationYupProductType: `Produkttyp ist ein Pflichtfeld`,
  campaign_creation_yup_product_type: `Produkttyp ist ein Pflichtfeld`,
  campaignCreationYupScenes1: `Szenen sind ein Pflichtfeld`,
  campaign_creation_yup_scenes1: `Szenen sind ein Pflichtfeld`,
  campaignCreationYupScenes2: `Mindestens eine Szene`,
  campaign_creation_yup_scenes2: `Mindestens eine Szene`,
  campaignCreationYupVideoDuration: `Videodauer ist ein Pflichtfeld`,
  campaign_creation_yup_video_duration: `Videodauer ist ein Pflichtfeld`,
  campaignCreationYupVideoFormat: `Videoformat ist ein Pflichtfeld`,
  campaign_creation_yup_video_format: `Videoformat ist ein Pflichtfeld`,
  campaignCreationYupVideoType: `Videotyp ist ein Pflichtfeld`,
  campaign_creation_yup_video_type: `Videotyp ist ein Pflichtfeld`,
  campaignDataMissing: `Für diese Kampagne sind keine Daten verfügbar.`,
  campaign_data_missing: `Für diese Kampagne sind keine Daten verfügbar.`,
  campaignFormNanoserviceDesc: `Ein Experte aus unserem Team erstellt ein Storyboard für die Creator. `,
  campaign_form_nanoservice_desc: `Ein Experte aus unserem Team erstellt ein Storyboard für die Creator. `,
  campaignFormNanoserviceTitle: `getnano Storyboard`,
  campaign_form_nanoservice_title: `getnano Storyboard`,
  campaignMoodboard: `Inspiration`,
  campaign_moodboard: `Inspiration`,
  campaignOverviewAcceptInfluencer: `Akzeptieren`,
  campaign_overview_accept_influencer: `Akzeptieren`,
  campaignOverviewAcceptInfluencerError: `Fehler aufgetreten beim Annehmen`,
  campaign_overview_accept_influencer_error: `Fehler aufgetreten beim Annehmen`,
  campaignOverviewApplicantsEmptyBody: `Wo sind denn alle?`,
  campaign_overview_applicants_empty_body: `Wo sind denn alle?`,
  campaignOverviewApplicantsEmptyTitle: `Du hast keine BewerberInnen für diese Kampagne`,
  campaign_overview_applicants_empty_title: `Du hast keine BewerberInnen für diese Kampagne`,
  campaignOverviewBookInfluencer: `Buchen`,
  campaign_overview_book_influencer: `Buchen`,
  campaignOverviewCheckEmptyBody: `Es gibt noch keine Werbevideos zu deiner Kampagne`,
  campaign_overview_check_empty_body: `Es gibt noch keine Werbevideos zu deiner Kampagne`,
  campaignOverviewCheckEmptyDoneBody: `Keine neuen Content Pieces zu reviewen`,
  campaign_overview_check_empty_done_body: `Keine neuen Content Pieces zu reviewen`,
  campaignOverviewCheckEmptyDoneTitle: `Alles Erledigt`,
  campaign_overview_check_empty_done_title: `Alles Erledigt`,
  campaignOverviewCheckEmptyTitle: `Huch, noch keine Content Pieces`,
  campaign_overview_check_empty_title: `Huch, noch keine Content Pieces`,
  campaignOverviewCheckGiveFeedback: `Feedback geben`,
  campaign_overview_check_give_feedback: `Feedback geben`,
  campaignOverviewCheckInfo: `Gebuchte Zusatzleistungen, wie Untertitel, Musik und Animationen werden erst nach dem Akzeptieren von unserem Team hinzugefügt.`,
  campaign_overview_check_info: `Gebuchte Zusatzleistungen, wie Untertitel, Musik und Animationen werden erst nach dem Akzeptieren von unserem Team hinzugefügt.`,
  campaignOverviewContentBeingEdited: `WIRD BEARBEITET`,
  campaign_overview_content_being_edited: `WIRD BEARBEITET`,
  campaignOverviewContentDownload: `downloaden`,
  campaign_overview_content_download: `downloaden`,
  campaignOverviewContentDuplicateBody: `Dupliziere jetzt deinen Auftrag, um weitere UGC-Werbevideos zu erhalten.`,
  campaign_overview_content_duplicate_body: `Dupliziere jetzt deinen Auftrag, um weitere UGC-Werbevideos zu erhalten.`,
  campaignOverviewContentDuplicateButton: `Auftrag duplizieren`,
  campaign_overview_content_duplicate_button: `Auftrag duplizieren`,
  campaignOverviewContentDuplicateTitle: `Weitere Videos bekommen`,
  campaign_overview_content_duplicate_title: `Weitere Videos bekommen`,
  campaignOverviewContentEmptyBody: `Du hast noch keine fertigen Videos`,
  campaign_overview_content_empty_body: `Du hast noch keine fertigen Videos`,
  campaignOverviewContentEmptyTitle: `Es gibt noch keine fertigen Werbevideos`,
  campaign_overview_content_empty_title: `Es gibt noch keine fertigen Werbevideos`,
  campaignOverviewContentGiveRatingBody: `Anhand deines Feedbacks, können wir dir bei künftigen Aufträgen entsprechende Creator anzeigen.`,
  campaign_overview_content_give_rating_body: `Anhand deines Feedbacks, können wir dir bei künftigen Aufträgen entsprechende Creator anzeigen.`,
  campaignOverviewContentGiveRatingButton: `Bewertung senden`,
  campaign_overview_content_give_rating_button: `Bewertung senden`,
  campaignOverviewContentGiveRatingPholder: `Lass den Creator wissen, was er besser machen kann.`,
  campaign_overview_content_give_rating_pholder: `Lass den Creator wissen, was er besser machen kann.`,
  campaignOverviewContentGiveRatingTitle: `Bewertung abgeben`,
  campaign_overview_content_give_rating_title: `Bewertung abgeben`,
  campaignOverviewContentRawDownload: `Rohdatei downloaden`,
  campaign_overview_content_raw_download: `Rohdatei downloaden`,
  campaignOverviewContentUpgradeLabel: `UPGRADE BUCHEN`,
  campaign_overview_content_upgrade_label: `UPGRADE BUCHEN`,
  campaignOverviewContentUpgradedMessage: `Erfolgreich gebucht`,
  campaign_overview_content_upgraded_message: `Erfolgreich gebucht`,
  campaignOverviewCreatorsAddress: `Adresse`,
  campaign_overview_creators_address: `Adresse`,
  campaignOverviewCreatorsBeingEdited: `Wird bearbeitet`,
  campaign_overview_creators_being_edited: `Wird bearbeitet`,
  campaignOverviewCreatorsCheckContent: `content überprüfen`,
  campaign_overview_creators_check_content: `content überprüfen`,
  campaignOverviewCreatorsCodes: `Gutscheincode`,
  campaign_overview_creators_codes: `Gutscheincode`,
  campaignOverviewCreatorsCreator: `Creator`,
  campaign_overview_creators_creator: `Creator`,
  campaignOverviewCreatorsDeleteButton: `entfernen`,
  campaign_overview_creators_delete_button: `entfernen`,
  campaignOverviewCreatorsDisclaimer: `Falls möglich, hinterlege die Email ugc@stylink.com beim Versanddienstleister als Kontakt. Damit können wir Lieferschwierigkeiten vorbeugen.`,
  campaign_overview_creators_disclaimer: `Falls möglich, hinterlege die Email ugc@stylink.com beim Versanddienstleister als Kontakt. Damit können wir Lieferschwierigkeiten vorbeugen.`,
  campaignOverviewCreatorsEmptyBody: `Du hast noch keine aktiven Teilnehmer`,
  campaign_overview_creators_empty_body: `Du hast noch keine aktiven Teilnehmer`,
  campaignOverviewCreatorsEmptyTitle: `Huch, noch keine Teilnehmer`,
  campaign_overview_creators_empty_title: `Huch, noch keine Teilnehmer`,
  campaignOverviewCreatorsFinished: `abgeschlossen`,
  campaign_overview_creators_finished: `abgeschlossen`,
  campaignOverviewCreatorsGiveFeedback: (endDate: P) => `Feedback geben bis ${endDate as S}` as const,
  campaign_overview_creators_give_feedback: (endDate: P) => `Feedback geben bis ${endDate as S}` as const,
  campaignOverviewCreatorsMail: (brandName: P, firstname: P) => `mailto:ugc@stylink.com?subject=Problem Meldung von ${brandName as S} mit Nano ${firstname as S}` as const,
  campaign_overview_creators_mail: (brandName: P, firstname: P) => `mailto:ugc@stylink.com?subject=Problem Meldung von ${brandName as S} mit Nano ${firstname as S}` as const,
  campaignOverviewCreatorsMailButton: `melden`,
  campaign_overview_creators_mail_button: `melden`,
  campaignOverviewCreatorsMake_2ndContent: (endDate: P) => `Überarbeitet Content bis ${endDate as S}` as const,
  campaign_overview_creators_make_2nd_content: (endDate: P) => `Überarbeitet Content bis ${endDate as S}` as const,
  campaignOverviewCreatorsProductPreferences: `Produktpräferenzen`,
  campaign_overview_creators_product_preferences: `Produktpräferenzen`,
  campaignOverviewCreatorsSendUntil: (endDate: P) => `Versenden bis ${endDate as S}` as const,
  campaign_overview_creators_send_until: (endDate: P) => `Versenden bis ${endDate as S}` as const,
  campaignOverviewCreatorsShippingButton: `Versanddaten`,
  campaign_overview_creators_shipping_button: `Versanddaten`,
  campaignOverviewCreatorsStatus: `Status`,
  campaign_overview_creators_status: `Status`,
  campaignOverviewCreatorsSuccess: `Alles erledigt`,
  campaign_overview_creators_success: `Alles erledigt`,
  campaignOverviewCreatorsTitle1: `Creators`,
  campaign_overview_creators_title1: `Creators`,
  campaignOverviewCreatorsTitle2: `Produkt versendet`,
  campaign_overview_creators_title2: `Produkt versendet`,
  campaignOverviewCreatorsTitle3: `Content produziert`,
  campaign_overview_creators_title3: `Content produziert`,
  campaignOverviewCreatorsTodo: `ToDo`,
  campaign_overview_creators_todo: `ToDo`,
  campaignOverviewCreatorsWaitForFeedback: `Content wird von unserem Team geprüft`,
  campaign_overview_creators_wait_for_feedback: `Content wird von unserem Team geprüft`,
  campaignOverviewMakeContent: (endDate: P) => `Produziert Content bis ${endDate as S}` as const,
  campaign_overview_make_content: (endDate: P) => `Produziert Content bis ${endDate as S}` as const,
  campaignOverviewNoMoreCodes: `Es gibt keine Gutscheincodes mehr. Bitte füge welche ein bevor du weitere Nanos bestätigst.`,
  campaign_overview_no_more_codes: `Es gibt keine Gutscheincodes mehr. Bitte füge welche ein bevor du weitere Nanos bestätigst.`,
  campaignOverviewNoMoreSlots: `Keine freien Videos mehr`,
  campaign_overview_no_more_slots: `Keine freien Videos mehr`,
  campaignOverviewOnsentError: `Fehler aufgetreten beim Senden`,
  campaign_overview_onsent_error: `Fehler aufgetreten beim Senden`,
  campaignOverviewParticipant: `Teilnehmer:`,
  campaign_overview_participant: `Teilnehmer:`,
  campaignOverviewStatsBody1: `Creator laden ein Beispiel-Video hoch, in dem sie ein Produkt ihrer Wahl bewerben um zu zeigen, wie sie vor der Kamera performen. Jede Buchung ist verbindlich.`,
  campaign_overview_stats_body1: `Creator laden ein Beispiel-Video hoch, in dem sie ein Produkt ihrer Wahl bewerben um zu zeigen, wie sie vor der Kamera performen. Jede Buchung ist verbindlich.`,
  campaignOverviewStatsBody2: (daysForApplying: P) => `Bewerbungen bleiben ${daysForApplying as S} Tage bestehen.` as const,
  campaign_overview_stats_body2: (daysForApplying: P) => `Bewerbungen bleiben ${daysForApplying as S} Tage bestehen.` as const,
  campaignOverviewStatsTitle: `Beachte:`,
  campaign_overview_stats_title: `Beachte:`,
  campaignOverviewStepApplications: `Bewerbungen`,
  campaign_overview_step_applications: `Bewerbungen`,
  campaignOverviewStepCheck: `Content Überprüfen`,
  campaign_overview_step_check: `Content Überprüfen`,
  campaignOverviewStepContent: `Mein Content`,
  campaign_overview_step_content: `Mein Content`,
  campaignOverviewStepCreators: `Meine Creator`,
  campaign_overview_step_creators: `Meine Creator`,
  campaignProductInfo: `Produkt informationen`,
  campaign_product_info: `Produkt informationen`,
  campaignReward: `Belohnung`,
  campaign_reward: `Belohnung`,
  campaignRewardAmount: `In Höhe von `,
  campaign_reward_amount: `In Höhe von `,
  campaignRules: `Regeln`,
  campaign_rules: `Regeln`,
  campaignTask: `Aufgabe`,
  campaign_task: `Aufgabe`,
  campaignTaskMentions: `Erwähnungen`,
  campaign_task_mentions: `Erwähnungen`,
  campaignTaskScenes: `Szenen`,
  campaign_task_scenes: `Szenen`,
  cancel: `Ablehnen`,
  change: `Ändern`,
  clear: `Klar!`,
  commonSaved: `Erfolgreich gespeichert!`,
  common_saved: `Erfolgreich gespeichert!`,
  completeVideoToReceiveCredit: `Nach Fertigstellung des Videos erhältst du Guthaben, das du dir über die Einstellungen der App auszahlen lassen kannst.`,
  complete_video_to_receive_credit: `Nach Fertigstellung des Videos erhältst du Guthaben, das du dir über die Einstellungen der App auszahlen lassen kannst.`,
  copied: `Kopiert!`,
  couponErrorNotEnoughVideos: (number: P) => `Der Code ist erst ab ${number as S} Videos gültig` as const,
  coupon_error_not_enough_videos: (number: P) => `Der Code ist erst ab ${number as S} Videos gültig` as const,
  createAPromoVideo: `Erstelle eine 15-sekündiges Beispiel-Werbevideo mit einem Produkt deiner Wahl. Wir wollen wissen wie du ein Produkt bewerben würdest.`,
  create_a_promo_video: `Erstelle eine 15-sekündiges Beispiel-Werbevideo mit einem Produkt deiner Wahl. Wir wollen wissen wie du ein Produkt bewerben würdest.`,
  creatorsExamples: `Beispiele von Creatorn`,
  creators_examples: `Beispiele von Creatorn`,
  creatorsStatusDeletedFirstupload: `Abgebrochen`,
  creators_status_deleted_firstupload: `Abgebrochen`,
  creatorsStatusDeletedSecondupload: `Feedback nicht wahrgenommen`,
  creators_status_deleted_secondupload: `Feedback nicht wahrgenommen`,
  dashboardCampaignApplicants: `Bewerbungen`,
  dashboard_campaign_applicants: `Bewerbungen`,
  dashboardCampaignCodesRunningOut: `Codes gehen bald aus`,
  dashboard_campaign_codes_running_out: `Codes gehen bald aus`,
  dashboardCampaignFinished: `Abgeschlossen`,
  dashboard_campaign_finished: `Abgeschlossen`,
  dashboardCampaignNotVerified: `Kampagne wird geprüft`,
  dashboard_campaign_not_verified: `Kampagne wird geprüft`,
  dashboardCampaignOffline: `offline`,
  dashboard_campaign_offline: `offline`,
  dashboardCampaignOnline: `online`,
  dashboard_campaign_online: `online`,
  dashboardCampaignParticipants: `Teilnehmer`,
  dashboard_campaign_participants: `Teilnehmer`,
  dashboardCampaignUgcLabel: `CONTENT`,
  dashboard_campaign_ugc_label: `CONTENT`,
  dashboardCampaignUsedUpCodes: `Codes aufgebraucht`,
  dashboard_campaign_used_up_codes: `Codes aufgebraucht`,
  dashboardCreateUgcBody: `Content Creator erstellen natürlich wirkende Werbevideos für dein Unternehmen.`,
  dashboard_create_ugc_body: `Content Creator erstellen natürlich wirkende Werbevideos für dein Unternehmen.`,
  dashboardCreateUgcButton: `Auftrag Erstellen`,
  dashboard_create_ugc_button: `Auftrag Erstellen`,
  dashboardCreateUgcTitle: `Werbevideo`,
  dashboard_create_ugc_title: `Werbevideo`,
  dashboardEmptyBody: (price: P) => `Lass uns das ändern! Erstelle jetzt deine ersten UGC Aufträge ab ${price as S}€` as const,
  dashboard_empty_body: (price: P) => `Lass uns das ändern! Erstelle jetzt deine ersten UGC Aufträge ab ${price as S}€` as const,
  dashboardEmptyIcon1: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1AF8AB9E-BFD2-4204-B116-ABED909E249F-26664-00000C30E97293A2/839718E9-3E1E-49A4-9BD8-FD71A20A0C2F-26664-00000C30E977F5BC.jpeg`,
  dashboard_empty_icon1: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1AF8AB9E-BFD2-4204-B116-ABED909E249F-26664-00000C30E97293A2/839718E9-3E1E-49A4-9BD8-FD71A20A0C2F-26664-00000C30E977F5BC.jpeg`,
  dashboardEmptyIcon2: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1CC9768A-1BE7-4690-B672-C6FE70A991E2-2978-000000DF802555CD/28BBE067-FF7A-442E-963E-7D829C0932A6-2978-000000DF802CD92D.jpeg`,
  dashboard_empty_icon2: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1CC9768A-1BE7-4690-B672-C6FE70A991E2-2978-000000DF802555CD/28BBE067-FF7A-442E-963E-7D829C0932A6-2978-000000DF802CD92D.jpeg`,
  dashboardEmptyIcon3: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/39E52DD7-8131-400D-A070-82FD17C05BA5-1825-000000D71CA09EB5/6E320706-B093-4B37-80F2-9172F7036B26-1825-000000D71CA4B4DE.jpeg`,
  dashboard_empty_icon3: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/39E52DD7-8131-400D-A070-82FD17C05BA5-1825-000000D71CA09EB5/6E320706-B093-4B37-80F2-9172F7036B26-1825-000000D71CA4B4DE.jpeg`,
  dashboardEmptyName1: `Sophie`,
  dashboard_empty_name1: `Sophie`,
  dashboardEmptyName2: `Carolin`,
  dashboard_empty_name2: `Carolin`,
  dashboardEmptyName3: `Claudio`,
  dashboard_empty_name3: `Claudio`,
  dashboardEmptyTitle: `Ziemlich leer hier.`,
  dashboard_empty_title: `Ziemlich leer hier.`,
  dashboardEmptyTour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
  dashboard_empty_tour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
  dashboardEmptyVideo1: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_1.mp4`,
  dashboard_empty_video1: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_1.mp4`,
  dashboardEmptyVideo2: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_2.mp4`,
  dashboard_empty_video2: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_2.mp4`,
  dashboardEmptyVideo3: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_3.mp4`,
  dashboard_empty_video3: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_3.mp4`,
  dashboardSortBudget: `Budget`,
  dashboard_sort_budget: `Budget`,
  dashboardSortChronological: `Chronologisch`,
  dashboard_sort_chronological: `Chronologisch`,
  dashboardSortOnline: `Online zuerst`,
  dashboard_sort_online: `Online zuerst`,
  dashboardTitle: `Deine Aufträge`,
  dashboard_title: `Deine Aufträge`,
  denyDialogChooseFeedback: `Feedback eintragen`,
  deny_dialog_choose_feedback: `Feedback eintragen`,
  denyDialogDisclaimer: `Du kannst nur einmalig Änderungen von den Creatorn anfordern. Überlege dir also gut, wie dein Feedback aussieht.`,
  deny_dialog_disclaimer: `Du kannst nur einmalig Änderungen von den Creatorn anfordern. Überlege dir also gut, wie dein Feedback aussieht.`,
  denyDialogFeedback: (number: P) => `Feedback ${number as S}` as const,
  deny_dialog_feedback: (number: P) => `Feedback ${number as S}` as const,
  denyDialogMax: `max. 5 Feedback-Kommentare`,
  deny_dialog_max: `max. 5 Feedback-Kommentare`,
  denyDialogReason1: `Seitenverhältnis passt nicht.`,
  deny_dialog_reason1: `Seitenverhältnis passt nicht.`,
  denyDialogReason2: `Videodauer passt nicht.`,
  deny_dialog_reason2: `Videodauer passt nicht.`,
  denyDialogReason3: `Video braucht mehr Energie.`,
  deny_dialog_reason3: `Video braucht mehr Energie.`,
  denyDialogReason4: `Entferne die Filter oder Effekte.`,
  deny_dialog_reason4: `Entferne die Filter oder Effekte.`,
  denyDialogReason5: `Entferne das Voiceover.`,
  deny_dialog_reason5: `Entferne das Voiceover.`,
  denyDialogReason6: `Entferne die Musik.`,
  deny_dialog_reason6: `Entferne die Musik.`,
  denyDialogReason7: `Überprüfe die Rechtschreibung.`,
  deny_dialog_reason7: `Überprüfe die Rechtschreibung.`,
  denyDialogReason8: `Versuch es etwas natürlicher.`,
  deny_dialog_reason8: `Versuch es etwas natürlicher.`,
  denyDialogSendFeedback: `feedback senden`,
  deny_dialog_send_feedback: `feedback senden`,
  denyDialogTitle: `Änderungen anfordern`,
  deny_dialog_title: `Änderungen anfordern`,
  denyReasonBadAge: `Zu jung oder zu alt`,
  deny_reason_bad_age: `Zu jung oder zu alt`,
  denyReasonBadQuality: `Qualität nicht ausreichend`,
  deny_reason_bad_quality: `Qualität nicht ausreichend`,
  denyReasonBadVoicing: `Stimme passt nicht`,
  deny_reason_bad_voicing: `Stimme passt nicht`,
  denyReasonIrrelevant: `Passt nicht zur Marke`,
  deny_reason_irrelevant: `Passt nicht zur Marke`,
  denyReasonOther: `Sonstiges`,
  deny_reason_other: `Sonstiges`,
  denyReasonQuestion: `Bitte erkläre uns, was nicht gepasst hat.`,
  deny_reason_question: `Bitte erkläre uns, was nicht gepasst hat.`,
  deselect: `Abwählen`,
  discount: `Rabattcode`,
  dragAndDropBody: `max. 6 Bilder oder Videos`,
  drag_and_drop_body: `max. 6 Bilder oder Videos`,
  dragAndDropCaption: `File: MP4, JPG, PNG; min. 400x400`,
  drag_and_drop_caption: `File: MP4, JPG, PNG; min. 400x400`,
  dragAndDropTitle: `Drag & Drop oder File auswählen`,
  drag_and_drop_title: `Drag & Drop oder File auswählen`,
  error: `Fehler aufgetreten`,
  error2: `Es ist ein Fehler aufgetreten`,
  errorReloadBody: `Es ist ein Fehler aufgetreten. Versuche mal die Seite neu zu Laden.`,
  error_reload_body: `Es ist ein Fehler aufgetreten. Versuche mal die Seite neu zu Laden.`,
  errorTitle: `Fehler`,
  error_title: `Fehler`,
  feedbackDialogDescription: `Hilf uns dabei, deine User Experience auf Nano zu verbessern! Wir schätzen dein Feedback, damit wir unsere Plattform noch besser machen können!`,
  feedback_dialog_description: `Hilf uns dabei, deine User Experience auf Nano zu verbessern! Wir schätzen dein Feedback, damit wir unsere Plattform noch besser machen können!`,
  feedbackDialogSubmitButton: `Senden`,
  feedback_dialog_submit_button: `Senden`,
  feedbackDialogSuccess: `Feedback erfolgreich gesendet!`,
  feedback_dialog_success: `Feedback erfolgreich gesendet!`,
  feedbackDialogTextfield: `Feedback`,
  feedback_dialog_textfield: `Feedback`,
  feedbackDialogTitle: `Feedback geben`,
  feedback_dialog_title: `Feedback geben`,
  fullSlotsCouponDialogBody: `Für den nächsten Teilnehmer den du in diesem Auftrag buchst, erhältst du`,
  full_slots_coupon_dialog_body: `Für den nächsten Teilnehmer den du in diesem Auftrag buchst, erhältst du`,
  fullSlotsCouponDialogBodyEmpty: `Sollte dein Auftrag heute noch neue Bewerber bekommen, hast die Möglichkeit diese Creator zu buchen. Für den nächsten Teilnehmer den du folglich in diesem Auftrag buchst, erhältst du`,
  full_slots_coupon_dialog_body_empty: `Sollte dein Auftrag heute noch neue Bewerber bekommen, hast die Möglichkeit diese Creator zu buchen. Für den nächsten Teilnehmer den du folglich in diesem Auftrag buchst, erhältst du`,
  fullSlotsCouponDialogExpiration: `Dein Gutschein lautet:`,
  full_slots_coupon_dialog_expiration: `Dein Gutschein lautet:`,
  fullSlotsCouponDialogTitle: `Hier ein Gutschein`,
  full_slots_coupon_dialog_title: `Hier ein Gutschein`,
  fullSlotsCouponDialogValidUntil: `Gültig bis:`,
  full_slots_coupon_dialog_valid_until: `Gültig bis:`,
  fullSlotsNormalDialogBody: `Du hast jetzt die Möglichkeit weitere Creator zu buchen.

Alle offenen Bewerber werden heute Nacht abgelehnt.`,
  full_slots_normal_dialog_body: `Du hast jetzt die Möglichkeit weitere Creator zu buchen.

Alle offenen Bewerber werden heute Nacht abgelehnt.`,
  fullSlotsNormalDialogBodyEmpty: `Sollte dein Auftrag heute noch neue Bewerber bekommen, hast die Möglichkeit diese Creator zu buchen.

Alle offenen Bewerber werden dann mit heute Nacht abgelehnt.`,
  full_slots_normal_dialog_body_empty: `Sollte dein Auftrag heute noch neue Bewerber bekommen, hast die Möglichkeit diese Creator zu buchen.

Alle offenen Bewerber werden dann mit heute Nacht abgelehnt.`,
  fullSlotsNormalDialogTitle: `Weitere Creator buchen`,
  full_slots_normal_dialog_title: `Weitere Creator buchen`,
  howToBecomeReward: `Wie kommst du an deine Belohnung?`,
  how_to_become_reward: `Wie kommst du an deine Belohnung?`,
  influencerDialogAge: `Alter:`,
  influencer_dialog_age: `Alter:`,
  influencerDialogBirthPlace: `Wohnort:`,
  influencer_dialog_birth_place: `Wohnort:`,
  influencerDialogCountry: `Land:`,
  influencer_dialog_country: `Land:`,
  influencerDialogDenyReason: `Ablehngrund auswählen`,
  influencer_dialog_deny_reason: `Ablehngrund auswählen`,
  introCampaignOverviewCreatorsSend: (date: P) => `Du musst bis zum ${date as S} hier die Sendungsnummer eintragen.` as const,
  intro_campaign_overview_creators_send: (date: P) => `Du musst bis zum ${date as S} hier die Sendungsnummer eintragen.` as const,
  loginAgbButton: `AGBs`,
  login_agb_button: `AGBs`,
  loginButton: `Login`,
  login_button: `Login`,
  loginCodeLabel: `Code`,
  login_code_label: `Code`,
  loginDataProtectionButton: `Datenschutzerklärung`,
  login_data_protection_button: `Datenschutzerklärung`,
  loginEmailLabel: `E-Mail`,
  login_email_label: `E-Mail`,
  loginError: `Es ist ein Fehler aufgetreten`,
  login_error: `Es ist ein Fehler aufgetreten`,
  loginErrorEmailExists: `Email exisitert bereits.`,
  login_error_email_exists: `Email exisitert bereits.`,
  loginErrorTooManyAttempts: `Zu viele Anmeldeversuche.`,
  login_error_too_many_attempts: `Zu viele Anmeldeversuche.`,
  loginErrorWrongCredentials: `Benutzername und Passwort stimmen nicht überein`,
  login_error_wrong_credentials: `Benutzername und Passwort stimmen nicht überein`,
  loginForgotPasswordButton: `Passwort vergessen?`,
  login_forgot_password_button: `Passwort vergessen?`,
  loginLoginButton: `Einloggen!`,
  login_login_button: `Einloggen!`,
  loginLoginText: `Noch kein Account?`,
  login_login_text: `Noch kein Account?`,
  loginPasswordLabel: `Passwort`,
  login_password_label: `Passwort`,
  loginRegisterButton: `Erstelle einen!`,
  login_register_button: `Erstelle einen!`,
  loginRegisterConfirmationPart1: `Ich stimme den`,
  login_register_confirmation_part1: `Ich stimme den`,
  loginRegisterConfirmationPart2: `AGBs`,
  login_register_confirmation_part2: `AGBs`,
  loginRegisterConfirmationPart3: `und der`,
  login_register_confirmation_part3: `und der`,
  loginRegisterConfirmationPart4: `Datenschutzbestimmungen`,
  login_register_confirmation_part4: `Datenschutzbestimmungen`,
  loginRegisterConfirmationPart5: `zu.`,
  login_register_confirmation_part5: `zu.`,
  loginRegisterFirstnameLabel: `Vorname`,
  login_register_firstname_label: `Vorname`,
  loginRegisterSubmitButton: `Loslegen`,
  login_register_submit_button: `Loslegen`,
  loginRegisterSurnameLabel: `Nachname`,
  login_register_surname_label: `Nachname`,
  loginRegisterText: `Du hast bereits einen Account?`,
  login_register_text: `Du hast bereits einen Account?`,
  loginRegisterYupCode1: `Bitte füge den Code aus der Email ein`,
  login_register_yup_code1: `Bitte füge den Code aus der Email ein`,
  loginRegisterYupConfirmation: `AGB und Datenschutz muss bestätigt sein`,
  login_register_yup_confirmation: `AGB und Datenschutz muss bestätigt sein`,
  loginRegisterYupEmail1: `Ungültige Email Adresse`,
  login_register_yup_email1: `Ungültige Email Adresse`,
  loginRegisterYupEmail2: `Email sind ein Pflichtfeld`,
  login_register_yup_email2: `Email sind ein Pflichtfeld`,
  loginRegisterYupFirstname1: `Vorname muss länger als 2 Zeichen sein`,
  login_register_yup_firstname1: `Vorname muss länger als 2 Zeichen sein`,
  loginRegisterYupPassword1: `Passwort muss min. 8 Zeichen lang sein`,
  login_register_yup_password1: `Passwort muss min. 8 Zeichen lang sein`,
  loginRegisterYupPassword2: `Passwort muss min. 1 Kleinbuchstaben enthalten`,
  login_register_yup_password2: `Passwort muss min. 1 Kleinbuchstaben enthalten`,
  loginRegisterYupPassword3: `Passwort muss min. 1 Großbuchstaben enthalten`,
  login_register_yup_password3: `Passwort muss min. 1 Großbuchstaben enthalten`,
  loginRegisterYupPassword4: `Passwort muss min. 1 Zahl enthalten`,
  login_register_yup_password4: `Passwort muss min. 1 Zahl enthalten`,
  loginRegisterYupPassword5: `Passwort ist ein Plichtfeld`,
  login_register_yup_password5: `Passwort ist ein Plichtfeld`,
  loginRegisterYupSurname1: `Nachname muss länger als 2 Zeichen sein`,
  login_register_yup_surname1: `Nachname muss länger als 2 Zeichen sein`,
  loginResetPasswordBody: `Mach dir nichts draus, passiert den Besten! Sag uns bitte deine E-Mail-Adresse und wir senden dir einen Code zum Zurücksetzen.`,
  login_reset_password_body: `Mach dir nichts draus, passiert den Besten! Sag uns bitte deine E-Mail-Adresse und wir senden dir einen Code zum Zurücksetzen.`,
  loginResetPasswordTitle: `Passwort Zurücksetzten`,
  login_reset_password_title: `Passwort Zurücksetzten`,
  loginSlideDesc1: `Erstelle einen Auftrag und lege das Videoskript, Videolänge, Erwähnungen und vieles mehr fest.`,
  login_slide_desc1: `Erstelle einen Auftrag und lege das Videoskript, Videolänge, Erwähnungen und vieles mehr fest.`,
  loginSlideDesc2: `Creator bewerben sich um deinen Auftrag durchzuführen. Du entscheidest, mit wem du zusammenarbeiten willst.`,
  login_slide_desc2: `Creator bewerben sich um deinen Auftrag durchzuführen. Du entscheidest, mit wem du zusammenarbeiten willst.`,
  loginSlideDesc3: `Sende den jeweiligen Creatorn dein Produkt`,
  login_slide_desc3: `Sende den jeweiligen Creatorn dein Produkt`,
  loginSlideDesc4: `Du erhältst deine Videos. Solltest du nicht zufrieden sein, hast du die Möglichkeit auf eine Änderungsschleife.`,
  login_slide_desc4: `Du erhältst deine Videos. Solltest du nicht zufrieden sein, hast du die Möglichkeit auf eine Änderungsschleife.`,
  loginSlideTitle1: `Auftrag erstellen`,
  login_slide_title1: `Auftrag erstellen`,
  loginSlideTitle2: `Bewerber erhalten & auswählen`,
  login_slide_title2: `Bewerber erhalten & auswählen`,
  loginSlideTitle3: `Produkt versenden`,
  login_slide_title3: `Produkt versenden`,
  loginSlideTitle4: `Videos erhalten`,
  login_slide_title4: `Videos erhalten`,
  loginYupEmail1: `Ungültige Email Adresse`,
  login_yup_email1: `Ungültige Email Adresse`,
  loginYupEmail2: `Email ist ein Pflichtfeld`,
  login_yup_email2: `Email ist ein Pflichtfeld`,
  loginYupPassword1: `Passwort muss min. 8 Zeichen lang sein`,
  login_yup_password1: `Passwort muss min. 8 Zeichen lang sein`,
  loginYupPassword2: `Passwort muss min. 1 Kleinbuchstaben enthalten`,
  login_yup_password2: `Passwort muss min. 1 Kleinbuchstaben enthalten`,
  loginYupPassword3: `Passwort muss min. 1 Großbuchstaben enthalten`,
  login_yup_password3: `Passwort muss min. 1 Großbuchstaben enthalten`,
  loginYupPassword4: `Passwort muss min. 1 Zahl enthalten`,
  login_yup_password4: `Passwort muss min. 1 Zahl enthalten`,
  loginYupPassword5: `Passwort ist ein Plichtfeld`,
  login_yup_password5: `Passwort ist ein Plichtfeld`,
  maximizeRoas: `Maximieren deinen ROAS`,
  maximize_roas: `Maximieren deinen ROAS`,
  missingCodesBody: `Einige Creator haben noch keinen Gutscheincode zugewiesen`,
  missing_codes_body: `Einige Creator haben noch keinen Gutscheincode zugewiesen`,
  missingCodesInfluencer: (name: P) => `Code ${name as S}` as const,
  missing_codes_influencer: (name: P) => `Code ${name as S}` as const,
  missingCodesTitle: `Hier ein Gutschein`,
  missing_codes_title: `Hier ein Gutschein`,
  more: `mehr`,
  moreAboutTheProduct: `Mehr zum Produkt`,
  more_about_the_product: `Mehr zum Produkt`,
  mostPopular: `am beliebtesten`,
  most_popular: `am beliebtesten`,
  next: `Weiter`,
  no: `Nein`,
  noAuthBody: `Es scheint du hast keine Berechtigungen um auf diesen Bereich
                zuzugreifen.
                <br />
                Ansonsten probiere die Seite neu zu laden.`,
  no_auth_body: `Es scheint du hast keine Berechtigungen um auf diesen Bereich
                zuzugreifen.
                <br />
                Ansonsten probiere die Seite neu zu laden.`,
  noAuthTitle: `Manchmal trifft es die Besten`,
  no_auth_title: `Manchmal trifft es die Besten`,
  onboardingOther: `SONSTIGES`,
  onboarding_other: `SONSTIGES`,
  onboardingPlattformForAgencyButton: `FÜR AGENTUREN`,
  onboarding_plattform_for_agency_button: `FÜR AGENTUREN`,
  onboardingPlattformForBrandsButton: `FÜR BRANDS`,
  onboarding_plattform_for_brands_button: `FÜR BRANDS`,
  onboardingReferral: `EMPFEHLUNG`,
  onboarding_referral: `EMPFEHLUNG`,
  onboardingTitle: `Wie hast du von uns erfahren?`,
  onboarding_title: `Wie hast du von uns erfahren?`,
  paymentModalCancel: `ABBRECHEN`,
  payment_modal_cancel: `ABBRECHEN`,
  paymentModalChooseMethod: `Zahlungsart wählen:`,
  payment_modal_choose_method: `Zahlungsart wählen:`,
  paymentModalChooseMethodCreditcard: `Kreditkarte`,
  payment_modal_choose_method_creditcard: `Kreditkarte`,
  paymentModalChooseMethodSepa: `SEPA Lastschrift`,
  payment_modal_choose_method_sepa: `SEPA Lastschrift`,
  paymentModalCouponInvalid: `Der Rabattcode ist ungültig`,
  payment_modal_coupon_invalid: `Der Rabattcode ist ungültig`,
  paymentModalMethodChooseError: `Fehler bei der Eingabe`,
  payment_modal_method_choose_error: `Fehler bei der Eingabe`,
  paymentModalMethodCompanyInfoAddress: `Straße, Hausnummer`,
  payment_modal_method_company_info_address: `Straße, Hausnummer`,
  paymentModalMethodCompanyInfoCity: `Stadt`,
  payment_modal_method_company_info_city: `Stadt`,
  paymentModalMethodCompanyInfoCountry: `Land`,
  payment_modal_method_company_info_country: `Land`,
  paymentModalMethodCompanyInfoName: `Firmenname`,
  payment_modal_method_company_info_name: `Firmenname`,
  paymentModalMethodCompanyInfoTitle: `Rechnungsanschrift`,
  payment_modal_method_company_info_title: `Rechnungsanschrift`,
  paymentModalMethodCompanyInfoVatnumber: `Umsatzsteuernummer`,
  payment_modal_method_company_info_vatnumber: `Umsatzsteuernummer`,
  paymentModalMethodCompanyInfoVatnumberCheck: `Mein Unternehmen besitzt eine Umsatzsteuernummer`,
  payment_modal_method_company_info_vatnumber_check: `Mein Unternehmen besitzt eine Umsatzsteuernummer`,
  paymentModalMethodCompanyInfoZip: `PLZ`,
  payment_modal_method_company_info_zip: `PLZ`,
  paymentModalPay: `BEZAHLEN`,
  payment_modal_pay: `BEZAHLEN`,
  paymentModalTitle: `Deine Bestellung`,
  payment_modal_title: `Deine Bestellung`,
  paymentModalYupCompanyAddress: `Straße & Hausnummer ist ein Pflichtfeld`,
  payment_modal_yup_company_address: `Straße & Hausnummer ist ein Pflichtfeld`,
  paymentModalYupCompanyCity: `Stadt ist ein Pflichtfeld`,
  payment_modal_yup_company_city: `Stadt ist ein Pflichtfeld`,
  paymentModalYupCompanyCountry: `Land ist ein Pflichtfeld`,
  payment_modal_yup_company_country: `Land ist ein Pflichtfeld`,
  paymentModalYupCompanyName: `Firmenname ist ein Pflichtfeld`,
  payment_modal_yup_company_name: `Firmenname ist ein Pflichtfeld`,
  paymentModalYupCompanyPaymentMethod: `Bezahlmethode ist ein Pflichtfeld`,
  payment_modal_yup_company_payment_method: `Bezahlmethode ist ein Pflichtfeld`,
  paymentModalYupCompanyVatNumber1: `UID Nummer ist ein Pflichtfeld`,
  payment_modal_yup_company_vat_number1: `UID Nummer ist ein Pflichtfeld`,
  paymentModalYupCompanyVatNumber2: (country: P) => `Ungültige UST-Nr. für ${country as S}` as const,
  payment_modal_yup_company_vat_number2: (country: P) => `Ungültige UST-Nr. für ${country as S}` as const,
  paymentModalYupCompanyZip: `Postleitzahl ist ein Pflichtfeld`,
  payment_modal_yup_company_zip: `Postleitzahl ist ein Pflichtfeld`,
  phoneFeatureNotAvailable: `Die Telefonanruffunktion ist auf Ihrem Gerät nicht verfügbar`,
  phone_feature_not_available: `Die Telefonanruffunktion ist auf Ihrem Gerät nicht verfügbar`,
  preferencesDialogEmpty: `Es ist ein Fehler aufgetreten`,
  preferences_dialog_empty: `Es ist ein Fehler aufgetreten`,
  preferencesDialogNoAnswer: `Keine Antwort`,
  preferences_dialog_no_answer: `Keine Antwort`,
  preferencesDialogTitle: `Produktpräferenzen`,
  preferences_dialog_title: `Produktpräferenzen`,
  previewInvoiceBody: `Dein Preis setzt sich aus folgendem zusammen:`,
  preview_invoice_body: `Dein Preis setzt sich aus folgendem zusammen:`,
  previewInvoiceBrutto: `Brutto`,
  preview_invoice_brutto: `Brutto`,
  previewInvoiceCount: `Anzahl`,
  preview_invoice_count: `Anzahl`,
  previewInvoiceDiscount: `Rabatt`,
  preview_invoice_discount: `Rabatt`,
  previewInvoiceNetto: `Netto`,
  preview_invoice_netto: `Netto`,
  previewInvoiceReverseCharge: `Reverse Charge`,
  preview_invoice_reverse_charge: `Reverse Charge`,
  previewInvoiceTitle: `Preisgestaltung`,
  preview_invoice_title: `Preisgestaltung`,
  previewInvoiceUst_20: `Ust 19%`,
  preview_invoice_ust_20: `Ust 19%`,
  pricingCtas: (i: P) => `${i as S}x alt. Call-to-Action` as const,
  pricing_ctas: (i: P) => `${i as S}x alt. Call-to-Action` as const,
  pricingHooks: (i: P) => `${i as S}x alt. Hook` as const,
  pricing_hooks: (i: P) => `${i as S}x alt. Hook` as const,
  pricingNanoService: `Storyboard`,
  pricing_nano_service: `Storyboard`,
  pricingProductDigital: `Digitales Produkt`,
  pricing_product_digital: `Digitales Produkt`,
  pricingSelfService: `Self Service`,
  pricing_self_service: `Self Service`,
  pricingVideoDuration: `Videodauer`,
  pricing_video_duration: `Videodauer`,
  pricingVideoFeatureAnimations: `Animationen`,
  pricing_video_feature_animations: `Animationen`,
  pricingVideoFeatureLarge: `Editing: Large`,
  pricing_video_feature_large: `Editing: Large`,
  pricingVideoFeatureMedium: `Editing: Medium`,
  pricing_video_feature_medium: `Editing: Medium`,
  pricingVideoFeatureMusic: `Musik`,
  pricing_video_feature_music: `Musik`,
  pricingVideoFeatureSmall: `Untertitel`,
  pricing_video_feature_small: `Untertitel`,
  pricingVideoFeatureSubtitles: `Untertitel`,
  pricing_video_feature_subtitles: `Untertitel`,
  pricingVideoType: `Videotyp`,
  pricing_video_type: `Videotyp`,
  pricingVoiceOverAny: `Voice-Over`,
  pricing_voice_over_any: `Voice-Over`,
  pricingVoiceOverComputer: `Voice-Over`,
  pricing_voice_over_computer: `Voice-Over`,
  pricingVoiceOverFemale: `Voice-Over`,
  pricing_voice_over_female: `Voice-Over`,
  pricingVoiceOverMale: `Voice-Over`,
  pricing_voice_over_male: `Voice-Over`,
  productAtYourAddressShipped: `Das Produkt wird an deine Adresse versendet.`,
  product_at_your_address_shipped: `Das Produkt wird an deine Adresse versendet.`,
  profileSettingsAnalyticsDisabled: `Sie müssen die Sitzung neu starten, um die Änderung zu übernehmen!`,
  profile_settings_analytics_disabled: `Sie müssen die Sitzung neu starten, um die Änderung zu übernehmen!`,
  profileSettingsAnalyticsEnable: `Analytics aktivieren`,
  profile_settings_analytics_enable: `Analytics aktivieren`,
  profileSettingsAnalyticsUnable: `Analytics deaktivieren`,
  profile_settings_analytics_unable: `Analytics deaktivieren`,
  profileSettingsChangePassword: `Passwort ändern`,
  profile_settings_change_password: `Passwort ändern`,
  profileSettingsEmail: `Email`,
  profile_settings_email: `Email`,
  profileSettingsFirstname: `Vorname`,
  profile_settings_firstname: `Vorname`,
  profileSettingsPassword: `Passwort`,
  profile_settings_password: `Passwort`,
  profileSettingsSave: `Änderungen speichern`,
  profile_settings_save: `Änderungen speichern`,
  profileSettingsSaveError: `Fehler beim Speichern`,
  profile_settings_save_error: `Fehler beim Speichern`,
  profileSettingsSurname: `Nachname`,
  profile_settings_surname: `Nachname`,
  profileSettingsTitle: `Profil verwalten`,
  profile_settings_title: `Profil verwalten`,
  recommended: `empfohlen`,
  recordVideoToBeAccepted: `Um bei Kooperationen angenommen zu werden, musst du zuerst ein 15sek Bewerbungsvideo aufnehmen, um zu beweisen, wie du vor der Kamera performst.`,
  record_video_to_be_accepted: `Um bei Kooperationen angenommen zu werden, musst du zuerst ein 15sek Bewerbungsvideo aufnehmen, um zu beweisen, wie du vor der Kamera performst.`,
  save: `Speichern`,
  saved: `Gespeichert`,
  scene: `Szene`,
  select: `Hinzufügen`,
  shippingBody: `Wähle bitte hier das Versandunternehmen aus und gib den Tracking Code ein.`,
  shipping_body: `Wähle bitte hier das Versandunternehmen aus und gib den Tracking Code ein.`,
  shippingButton: `Sendungsnr. eintragen`,
  shipping_button: `Sendungsnr. eintragen`,
  shippingOtherService: `Versanddienstleister`,
  shipping_other_service: `Versanddienstleister`,
  shippingSent: `Versendet`,
  shipping_sent: `Versendet`,
  shippingService: `Versandunternehmen`,
  shipping_service: `Versandunternehmen`,
  shippingTitle: `Versand Tracking Code`,
  shipping_title: `Versand Tracking Code`,
  shippingTrackingCodeLabel: `Versand Tracking Code`,
  shipping_tracking_code_label: `Versand Tracking Code`,
  sidebarHeadlineBrandSettings: `Brand verwalten`,
  sidebar_headline_brand_settings: `Brand verwalten`,
  sidebarHeadlineCreateCampaign: `Auftrag erstellen`,
  sidebar_headline_create_campaign: `Auftrag erstellen`,
  sidebarHeadlineOverview: `Auftragsübersicht`,
  sidebar_headline_overview: `Auftragsübersicht`,
  sidebarHeadlineProfileSettings: `Profil verwalten`,
  sidebar_headline_profile_settings: `Profil verwalten`,
  sidebarItemBrandSettings: `Brand Verwalten`,
  sidebar_item_brand_settings: `Brand Verwalten`,
  sidebarItemCampaigns: `Aufträge`,
  sidebar_item_campaigns: `Aufträge`,
  sidebarItemContact: `Support`,
  sidebar_item_contact: `Support`,
  sidebarItemCreateBrand: `Neue Brand Erstellen`,
  sidebar_item_create_brand: `Neue Brand Erstellen`,
  sidebarItemFeedback: `Feedback`,
  sidebar_item_feedback: `Feedback`,
  sidebarPopupCoupon: `Guthaben aufladen und <b>Bonus</b> sichern!`,
  sidebar_popup_coupon: `Guthaben aufladen und <b>Bonus</b> sichern!`,
  sidebarPopupCouponButton: `Aufladen`,
  sidebar_popup_coupon_button: `Aufladen`,
  sidebarPopupExpert: `Lass dich von unseren Experten <b>kostenlos</b> beraten.`,
  sidebar_popup_expert: `Lass dich von unseren Experten <b>kostenlos</b> beraten.`,
  sidebarPopupExpertButton: `Termin vereinbaren`,
  sidebar_popup_expert_button: `Termin vereinbaren`,
  sidebarStatsActiveCampaign: `aktive Auftrag`,
  sidebar_stats_active_campaign: `aktive Auftrag`,
  sidebarStatsActiveCampaigns: `aktive Aufträge`,
  sidebar_stats_active_campaigns: `aktive Aufträge`,
  somethingWentWrong: `Etwas ist schief gelaufen`,
  something_went_wrong: `Etwas ist schief gelaufen`,
  supportButtonCall: `Anrufen`,
  support_button_call: `Anrufen`,
  supportButtonChat: `Chat`,
  support_button_chat: `Chat`,
  supportButtonWhatsapp: `Whats App`,
  support_button_whatsapp: `Whats App`,
  test: (name: P, n: P) => `${name as S} Hallo. ${n as S}` as const,
  timePeriod: `Zeitraum`,
  time_period: `Zeitraum`,
  tipsForYourApplicationVideo: `Tipps für dein Bewerbungsvideo`,
  tips_for_your_application_video: `Tipps für dein Bewerbungsvideo`,
  toTheRules: `Zu den Regeln`,
  to_the_rules: `Zu den Regeln`,
  toTheTask: `Zur Aufgabe`,
  to_the_task: `Zur Aufgabe`,
  tutorialDoNotShowAgain: `Nicht mehr anzeigen`,
  tutorial_do_not_show_again: `Nicht mehr anzeigen`,
  understood: `Verstanden!`,
  unknownError: `Unbekannter Fehler`,
  unknown_error: `Unbekannter Fehler`,
  upgradeVideoTutorialBody: `Grade jetzt deine Videos mit Musik, Untertitel oder Animationen auf, um noch besseren ROAS zu erhalten!`,
  upgrade_video_tutorial_body: `Grade jetzt deine Videos mit Musik, Untertitel oder Animationen auf, um noch besseren ROAS zu erhalten!`,
  upgradeVideoTutorialButton: `Upgrade wählen`,
  upgrade_video_tutorial_button: `Upgrade wählen`,
  upgradeVideoTutorialJoyrideContent: `Klicke hier für Upgrades.`,
  upgrade_video_tutorial_joyride_content: `Klicke hier für Upgrades.`,
  upgradeVideoTutorialTitle: `Jetzt deine Videos Upgraden.`,
  upgrade_video_tutorial_title: `Jetzt deine Videos Upgraden.`,
  uploadYourFinishedVideoTill: `Du hast Zeit dein fertiggestelltes Video hochzuladen bis:`,
  upload_your_finished_video_till: `Du hast Zeit dein fertiggestelltes Video hochzuladen bis:`,
  useVideosPicturesForPosting: `Bitte orientiere dich bitte an folgenden Bildern und Videos, wenn du dein Posting machsts.`,
  use_videos_pictures_for_posting: `Bitte orientiere dich bitte an folgenden Bildern und Videos, wenn du dein Posting machsts.`,
  userRoleAccountant: `ACCOUNTANT`,
  user_role_accountant: `ACCOUNTANT`,
  userRoleAdmin: `ADMIN`,
  user_role_admin: `ADMIN`,
  userRoleAnalyst: `ANALYST`,
  user_role_analyst: `ANALYST`,
  userRoleMarketer: `MARKETER`,
  user_role_marketer: `MARKETER`,
  videoFeatureAnimationsTitle: `Animationen`,
  video_feature_animations_title: `Animationen`,
  videoFeatureLargeDescription: `✓ Untertitel
✓ Logo
✓ Call-to-Action
+ Motion Grafiken
+ Musik`,
  video_feature_large_description: `✓ Untertitel
✓ Logo
✓ Call-to-Action
+ Motion Grafiken
+ Musik`,
  videoFeatureMediumDescription: `✓ Untertitel
+ Logo
+ Call-to-Action`,
  video_feature_medium_description: `✓ Untertitel
+ Logo
+ Call-to-Action`,
  videoFeatureMusicTitle: `Musik`,
  video_feature_music_title: `Musik`,
  videoFeatureSmallDescription: `✓ Untertitel`,
  video_feature_small_description: `✓ Untertitel`,
  videoFeatureSubtitlesTitle: `Untertitel`,
  video_feature_subtitles_title: `Untertitel`,
  wait: `Warten`,
  whatsappNotInstalledOnYourDevice: `Whats App ist nicht auf Ihrem Gerät installiert`,
  whatsapp_not_installed_on_your_device: `Whats App ist nicht auf Ihrem Gerät installiert`,
  yes: `Ja`,
  yourApplicationVideo: `Dein Bewerbungsvideo`,
  your_application_video: `Dein Bewerbungsvideo`,
  login_register_referral_code: 'Empfehlungscode (optional)',
  login_error_referral_code: 'Empfehlungscode ungültig',
  brand_creation_info_brand_audio: 'Brand Aussprache',
  campaign_creation_upload_brand_audio_error:
    'Ups! Dein Audio konnte nicht hochgeladen werden, deine Brand wird dennoch erstellt. Bitte melde dich bei uns über ugc@stylink.com, damit wir dein Bild nachtragen können.',
  inspo_classic_desc_1: 'geeignet für Social Media Ads und organischen Content',
  inspo_classic_desc_2: 'Produkt und Creator stehen im Fokus',
  inspo_classic_desc_3: 'ideal für physische Produkte, die angewendet werden',
  inspo_classic_title_1: 'Klassisches',
  inspo_classic_title_2: 'UGC Video',
  inspo_digital_desc_1: 'geeignet für Apps und Software-Lösungen',
  inspo_digital_desc_2: 'Anwendung steht im Fokus',
  inspo_digital_desc_3: 'ideal für Food, Versicherungen, Travel',
  inspo_digital_title_1: 'Digitales',
  inspo_digital_title_2: 'UGC Video',
  inspo_industry_desc_1: 'geeignet für Bau - und Handwerksindustrie',
  inspo_industry_desc_2: 'stelle dem Creator Cut Outs zur Verfügung, um einen „Vorher-Nachher“ Effekt zu bewirken',
  inspo_industry_desc_3: 'ideal für größere Produkte',
  inspo_industry_title_1: 'Industrie',
  inspo_industry_title_2: 'UGC Video',
  inspo_unboxing_desc_1: 'geeignet für E-Commerce & Online Marktplätze ',
  inspo_unboxing_desc_2: '“Unboxing Effekt” steht im Fokus',
  inspo_unboxing_desc_3: 'ideal für soundless Videos mit Creator oder faceless Videos',
  inspo_unboxing_title_1: 'Unboxing & Faceless',
  inspo_unboxing_title_2: 'UGC Video',
  texterifyTimestamp: `2024-11-27T15:01:11Z`,
  texterify_timestamp: `2024-11-27T15:01:11Z`
} as const
