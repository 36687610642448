import { CircularProgress, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Dot from '../../assets/icons/ic_dot.svg'
import { useStrings } from '../../assets/localization/strings'
import { Campaign as TCampaign } from '../../stores/campaignStore'
import rootStore from '../../stores/rootStore'
import styles from '../../style/CampaignDashboard.module.css'
import InformationDialog from '../common/InformationDialog'
import ErrorReload from '../error/ErrorReload'
import NotAuth from '../error/NotAuth'
import Campaign from './Campaign'
import { EmptyCampaigns } from './EmptyCampaigns'
import InspirationCard from './InspirationCard'

const useStyles = makeStyles(() => ({
  moreContentButton: {
    background: '#a890fe',
    borderRadius: '10px',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
    marginTop: '10px',
    lineHeight: '18px'
  },
  cardContainer: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    borderColor: '#f1f1f1',
    borderRadius: '10px',
    marginLeft: '0 !important',
    padding: '30px',
    marginBottom: '30px',
    marginTop: '10px',
    overflow: 'auto',
    color: '#4F4F4F'
  },
  selectContainer: {
    borderRadius: '10px'
  }
}))

export const DashboardCard = ({ title, body, button, onClick, className }: { title: string; body: string; button: string; onClick(): void; className?: string }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12} md={6} lg={5} xl={5}>
      <div className={[styles.createCampaignContainer, className].filterNotNull().join(' ')}>
        <Grid container>
          <Grid item xs={12}>
            <b className={styles.createCampaignHeader}>{title}</b>
          </Grid>
          <Grid item xs={12}>
            <span>{body}</span>
          </Grid>
          <Grid item xs={12}>
            <button className={classes.moreContentButton} onClick={onClick} id='CREATE_CAMPAIGN_LINK_CARD'>
              {button}
            </button>
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

const CampaignDashboard = observer(() => {
  const [campaignsLoading, setCampaignsLoading] = useState(true)
  const [campaignsError, setCampaignsError] = useState<Response>()
  const [showInformationDialog, setShowInformationDialog] = useState(false)
  const [campaigns, setCampaigns] = useState<TCampaign[]>()
  const history = useHistory()
  const strings = useStrings()
  const [videos, _] = useState([
    {
      title1: strings.inspo_classic_title_1,
      title2: strings.inspo_classic_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_classic.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_classic_thumb.png',
      bulletPoints: [strings.inspo_classic_desc_1, strings.inspo_classic_desc_2, strings.inspo_classic_desc_3]
    },
    {
      title1: strings.inspo_unboxing_title_1,
      title2: strings.inspo_unboxing_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_unboxing.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_unboxing_thumb.png',
      bulletPoints: [strings.inspo_unboxing_desc_1, strings.inspo_unboxing_desc_2, strings.inspo_unboxing_desc_3]
    },
    {
      title1: strings.inspo_digital_title_1,
      title2: strings.inspo_digital_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_digital.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_digital_thumb.png',
      bulletPoints: [strings.inspo_digital_desc_1, strings.inspo_digital_desc_2, strings.inspo_digital_desc_3]
    },
    {
      title1: strings.inspo_industry_title_1,
      title2: strings.inspo_industry_title_2,
      videoUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_industry.mp4',
      thumbUrl: 'https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/inspo_industry_thumb.png',
      bulletPoints: [strings.inspo_industry_desc_1, strings.inspo_industry_desc_2, strings.inspo_industry_desc_3]
    }
  ])

  const loadCampaigns = async () => {
    try {
      setCampaignsLoading(true)
      setCampaignsError(undefined)
      await rootStore.campaignStore.getCampaigns(rootStore.brandStore.selectedBrand?.brandId)
      if (rootStore.campaignStore.campaigns) setCampaigns(rootStore.campaignStore.campaigns.ascendingSorted(({ createdAt }) => createdAt ?? 0).reversed())
    } catch (e) {
      console.error(e)
      setCampaignsError(e)
    } finally {
      setCampaignsLoading(false)
    }
  }

  useEffect(() => {
    const onMount = async () => {
      if (rootStore.userStore.authenticatedUser?.showInformation) {
        setShowInformationDialog(true)
      }

      console.log(rootStore.brandStore.selectedBrand)
      await loadCampaigns()
    }

    onMount()
  }, [rootStore.brandStore.selectedBrand])

  const onCreateCampaign = () => {
    const { selectedBrand } = rootStore.brandStore

    if (selectedBrand?.role === 'Marketer' || selectedBrand?.role === 'Admin') {
      history.push(`/${selectedBrand.brandId}/createContentCampaign`)
    } else {
      alert('Du hast leider keine Berechtigungen um auf diesen Bereich zuzugreifen')
    }
  }

  const renderCampaigns = () =>
    campaigns?.length ? (
      <>
        {campaigns.map(campaign => (
          <Campaign campaign={campaign} key={campaign.campaignId} />
        ))}
      </>
    ) : (
      <EmptyCampaigns onCreate={onCreateCampaign} />
    )

  if (campaignsLoading)
    return (
      <Grid container height='100%' alignItems='center' justifyContent='center'>
        <CircularProgress className='loadingFreebee' color='primary' />
      </Grid>
    )

  if (campaignsError?.status === 401)
    return (
      <Grid container wrap='nowrap' alignItems='center' style={{ textAlign: 'center' }}>
        <NotAuth />
      </Grid>
    )

  if (!campaigns) return <ErrorReload />

  const renderInspiration = () => {
    return (
      <Grid container spacing={3} justifyContent='center'>
        {videos.map((video, index) => (
          <InspirationCard key={index} videoUrl={video.videoUrl} thumbUrl={video.thumbUrl} title1={video.title1} title2={video.title2} bulletPoints={video.bulletPoints} />
        ))}
      </Grid>
    )
  }

  const renderHeaderCard = () => (
    <Grid item xs={12}>
      <Grid container>
        <DashboardCard
          title={strings.dashboard_create_ugc_title}
          body={strings.dashboard_create_ugc_body}
          button={strings.dashboard_create_ugc_button}
          onClick={onCreateCampaign}
          className='my-first-step'
        />
      </Grid>
    </Grid>
  )

  const renderCampaignsSection = () => (
    <>
      <Grid item xs={12} mt={2} mb={2}>
        <span className={styles.dashBoardHeader}>
          <span className={styles.yourCampaignHeader}>{strings.dashboard_title}</span>
          <img src={Dot} className={styles.dotHeader} alt='.' />
          <span className={styles.yourCampaignsTotalHeader}>{campaigns.length}</span>
        </span>
      </Grid>
      <Grid id='campaigns' container pb='2em'>
        {renderCampaigns()}
      </Grid>
    </>
  )

  const renderInspirationSection = () => (
    <>
      <Grid item xs={12} mt={2} mb={2}>
        <span className={styles.dashBoardHeader}>
          <span className={styles.yourCampaignHeader}>Inspiration</span>
        </span>
      </Grid>
      <Grid item xs={12}>
        <div>{renderInspiration()}</div>
      </Grid>
    </>
  )

  return (
    <>
      <InformationDialog showModal={showInformationDialog} onCloseModal={() => setShowInformationDialog(false)} />
      <Grid className={styles.campaignDashboardConainter}>
        <Grid className={styles.dashboard}>
          {renderHeaderCard()}

          {campaigns.length > 0 ? (
            <>
              {renderCampaignsSection()}
              {renderInspirationSection()}
            </>
          ) : (
            <>
              {renderInspirationSection()}
              {renderCampaignsSection()}
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
})

export default CampaignDashboard
