export enum ContentCampaignLocale {
  DELIVERY = 'delivery',
  MONEY = 'MONEY'
}

export { ContentCampaignLocale as Locale }

export enum ShippingService {
  AT_POST = 'Österreichische Post',
  DE_POST = 'Deutsche Post',
  DHL = 'DHL',
  DPD = 'DPD',
  GLS = 'GLS',
  UPS = 'UPS',
  OTHER = 'Andere'
}

export const ShippingServicesArray = [
  ShippingService.AT_POST,
  ShippingService.DE_POST,
  ShippingService.DHL,
  ShippingService.DPD,
  ShippingService.GLS,
  ShippingService.UPS,
  ShippingService.OTHER
]

export enum VideoType {
  ad = 'ad',
  premium = 'premium'
}

export enum VideoFormat {
  square = 'square',
  horizontal = 'wide',
  vertical = 'vertical',
  fullscreen = 'fullscreen'
}

export enum VideoFeature {
  small = 'small',
  medium = 'medium',
  up_small_medium = 'up_small_medium'
}

export enum VideoDuration {
  Sec15 = 15,
  Sec30 = 30,
  Sec60 = 60
}

export enum Service {
  selfService = 'selfService',
  nanoService = 'nanoService'
}

export enum ProductType {
  physical = 'physical',
  digital = 'digital'
}

export enum StylinkGender {
  MALE = 'm',
  FEMALE = 'f',
  DIVERSE = 'd'
}

export enum CampaignGender {
  MALE = 'm',
  FEMALE = 'f',
  DIVERSE = 'd',
  ANY = 'a'
}

export enum AgeOptions {
  age1 = '16-18',
  age2 = '18-30',
  age3 = '30+'
}

export enum Role {
  Admin = 'Admin',
  Marketer = 'Marketer',
  Analyst = 'Analyst',
  Accountant = 'Accountant'
}

export enum PaymentType {
  Creditcard = 'creditcard',
  Sepa = 'sepa'
}

export enum TodoType {
  awaitFeedback = 'awaitFeedback',
  seeFeedback = 'seeFeedback',
  awaitContentFeedback = 'awaitContentFeedback',
  awaitDelivery = 'awaitDelivery',
  uploadContent = 'uploadContent'
}

export enum StatusInfo {
  Applied = 'applied',
  Accepted = 'accepted',
  Finished = 'finished',
  Rejected = 'rejected',
  Reported = 'reported', // creator was reported by an admin
  // Means that admin needs to verify whether the user should be accepted
  /** @deprecated */
  ToBeVerified = 'to_be_verified'
}

export enum HubspotIndustry {
  Accounting = 'Accounting',
  Airlines_Aviation = 'Airlines/Aviation',
  Alternative_Dispute_Resolution = 'Alternative Dispute Resolution',
  Alternative_Medicine = 'Alternative Medicine',
  Animation = 'Animation',
  Apparel_Fashion = 'Apparel & Fashion',
  Architecture_Planning = 'Architecture & Planning',
  Arts_and_Crafts = 'Arts and Crafts',
  Automotive = 'Automotive',
  Aviation_Aerospace = 'Aviation & Aerospace',
  Banking = 'Banking',
  Biotechnology = 'Biotechnology',
  Broadcast_Media = 'Broadcast Media',
  Building_Materials = 'Building Materials',
  Business_Supplies_and_Equipment = 'Business Supplies and Equipment',
  Capital_Markets = 'Capital Markets',
  Chemicals = 'Chemicals',
  Civic_Social_Organization = 'Civic & Social Organization',
  Civil_Engineering = 'Civil Engineering',
  Commercial_Real_Estate = 'Commercial Real Estate',
  Computer_Network_Security = 'Computer & Network Security',
  Computer_Games = 'Computer Games',
  Computer_Hardware = 'Computer Hardware',
  Computer_Networking = 'Computer Networking',
  Computer_Software = 'Computer Software',
  Internet = 'Internet',
  Construction = 'Construction',
  Consumer_Electronics = 'Consumer Electronics',
  Consumer_Goods = 'Consumer Goods',
  Consumer_Services = 'Consumer Services',
  Cosmetics = 'Cosmetics',
  Dairy = 'Dairy',
  Defense_Space = 'Defense & Space',
  Design = 'Design',
  Education_Management = 'Education Management',
  E_Learning = 'E-Learning',
  Electrical_Electronic_Manufacturing = 'Electrical/Electronic Manufacturing',
  Entertainment = 'Entertainment',
  Environmental_Services = 'Environmental Services',
  Events_Services = 'Events Services',
  Executive_Office = 'Executive Office',
  Facilities_Services = 'Facilities Services',
  Farming = 'Farming',
  Financial_Services = 'Financial Services',
  Fine_Art = 'Fine Art',
  Fishery = 'Fishery',
  Food_Beverages = 'Food & Beverages',
  Food_Production = 'Food Production',
  Fund_Raising = 'Fund-Raising',
  Furniture = 'Furniture',
  Gambling_Casinos = 'Gambling & Casinos',
  Glass_Ceramics_Concrete = 'Glass, Ceramics & Concrete',
  Government_Administration = 'Government Administration',
  Government_Relations = 'Government Relations',
  Graphic_Design = 'Graphic Design',
  Health_Wellness_and_Fitness = 'Health, Wellness and Fitness',
  Higher_Education = 'Higher Education',
  Hospital_Health_Care = 'Hospital & Health Care',
  Hospitality = 'Hospitality',
  Human_Resources = 'Human Resources',
  Import_and_Export = 'Import and Export',
  Individual_Family_Services = 'Individual & Family Services',
  Industrial_Automation = 'Industrial Automation',
  Information_Services = 'Information Services',
  Information_Technology_and_Services = 'Information Technology and Services',
  Insurance = 'Insurance',
  International_Affairs = 'International Affairs',
  International_Trade_and_Development = 'International Trade and Development',
  Investment_Banking = 'Investment Banking',
  Investment_Management = 'Investment Management',
  Judiciary = 'Judiciary',
  Law_Enforcement = 'Law Enforcement',
  Law_Practice = 'Law Practice',
  Legal_Services = 'Legal Services',
  Legislative_Office = 'Legislative Office',
  Leisure_Travel_Tourism = 'Leisure, Travel & Tourism',
  Libraries = 'Libraries',
  Logistics_and_Supply_Chain = 'Logistics and Supply Chain',
  Luxury_Goods_Jewelry = 'Luxury Goods & Jewelry',
  Machinery = 'Machinery',
  Management_Consulting = 'Management Consulting',
  Maritime = 'Maritime',
  Market_Research = 'Market Research',
  Marketing_and_Advertising = 'Marketing and Advertising',
  Mechanical_or_Industrial_Engineering = 'Mechanical or Industrial Engineering',
  Media_Production = 'Media Production',
  Medical_Devices = 'Medical Devices',
  Medical_Practice = 'Medical Practice',
  Mental_Health_Care = 'Mental Health Care',
  Military = 'Military',
  Mining_Metals = 'Mining & Metals',
  Motion_Pictures_and_Film = 'Motion Pictures and Film',
  Museums_and_Institutions = 'Museums and Institutions',
  Music = 'Music',
  Nanotechnology = 'Nanotechnology',
  Newspapers = 'Newspapers',
  Non_Profit_Organization_Management = 'Non-Profit Organization Management',
  Oil_Energy = 'Oil & Energy',
  Online_Media = 'Online Media',
  Outsourcing_Offshoring = 'Outsourcing/Offshoring',
  Package_Freight_Delivery = 'Package/Freight Delivery',
  Packaging_and_Containers = 'Packaging and Containers',
  Paper_Forest_Products = 'Paper & Forest Products',
  Performing_Arts = 'Performing Arts',
  Pharmaceuticals = 'Pharmaceuticals',
  Philanthropy = 'Philanthropy',
  Photography = 'Photography',
  Plastics = 'Plastics',
  Political_Organization = 'Political Organization',
  Primary_Secondary_Education = 'Primary/Secondary Education',
  Printing = 'Printing',
  Professional_Training_Coaching = 'Professional Training & Coaching',
  Program_Development = 'Program Development',
  Public_Policy = 'Public Policy',
  Public_Relations_and_Communications = 'Public Relations and Communications',
  Public_Safety = 'Public Safety',
  Publishing = 'Publishing',
  Railroad_Manufacture = 'Railroad Manufacture',
  Ranching = 'Ranching',
  Real_Estate = 'Real Estate',
  Recreational_Facilities_and_Services = 'Recreational Facilities and Services',
  Religious_Institutions = 'Religious Institutions',
  Renewables_Environment = 'Renewables & Environment',
  Research = 'Research',
  Restaurants = 'Restaurants',
  Retail = 'Retail',
  Security_and_Investigations = 'Security and Investigations',
  Semiconductors = 'Semiconductors',
  Shipbuilding = 'Shipbuilding',
  Sporting_Goods = 'Sporting Goods',
  Sports = 'Sports',
  Staffing_and_Recruiting = 'Staffing and Recruiting',
  Supermarkets = 'Supermarkets',
  Telecommunications = 'Telecommunications',
  Textiles = 'Textiles',
  Think_Tanks = 'Think Tanks',
  Tobacco = 'Tobacco',
  Translation_and_Localization = 'Translation and Localization',
  Transportation_Trucking_Railroad = 'Transportation/Trucking/Railroad',
  Utilities = 'Utilities',
  Venture_Capital_Private_Equity = 'Venture Capital & Private Equity',
  Veterinary = 'Veterinary',
  Warehousing = 'Warehousing',
  Wholesale = 'Wholesale',
  Wine_and_Spirits = 'Wine and Spirits',
  Wireless = 'Wireless',
  Writing_and_Editing = 'Writing and Editing',
  Fashion_Accessoires = 'Fashion & Accessoires',
  Interior_DIY = 'Interior & DIY',
  Beauty_Make_Up = 'Beauty & Make-Up ',
  Mommy_Kids = 'Mommy & Kids',
  Gesundheit_Fitness = 'Gesundheit & Fitness',
  Food = 'Food',
  Freizeit = 'Freizeit',
  Technik = 'Technik',
  Luxury = 'Luxury',
  Schmuck_Accessoires = 'Schmuck & Accessoires',
  Schuhe = 'Schuhe',
  Outdoor = 'Outdoor',
  Buecher_Schreibwaren = 'Bücher & Schreibwaren',
  Travel = 'Travel',
  Gaming = 'Gaming',
  Hobbies_Buecher = 'Hobbies & Bücher',
  Haustier_Haustierpflege = 'Haustier / Haustierpflege',
  Digital_Products_Apps = 'Digital Products & Apps',
  Finance_Insurance = 'Finance & Insurance',
  Diverse = 'Diverse'
}
