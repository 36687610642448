import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Card, CardContent, Grid } from '@mui/material'
import { observer } from 'mobx-react'
import { useSnackbar } from 'notistack'
import React, { useState, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { boolean, object, string } from 'yup'
import { useStrings } from '../../assets/localization/strings'
import { base64toFile, uploadToS3 } from '../../reusableUtils/Helpers'
import rootStore from '../../stores/rootStore'
import BrandContact from '../createBrand/BrandContact'
import BrandInfo from '../createBrand/BrandInfo'
import ErrorReload from '../error/ErrorReload'

export interface IBrandSettings {
  brandName: string
  brandLogo: string // as base64
  brandAudioUrl: string
  firstname: string
  surname: string
  contactEmail: string
  contactPhone: string
  isAgency?: boolean
  industry: string
}

const BrandSettings = observer(() => {
  const strings = useStrings()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const { authenticatedUser } = rootStore.userStore
  const { selectedBrand } = rootStore.brandStore

  const schema = object().shape({
    brandName: string().required(strings.brand_creation_yup_brand_name),
    brandLogo: string().optional(),
    brandAudioUrl: string().optional(),
    firstname: string().required(strings.brand_creation_yup_firstname),
    surname: string().required(strings.brand_creation_yup_surname),
    contactEmail: string().email().required(strings.brand_creation_yup_email),
    contactPhone: string()
      .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/, strings.brand_creation_yup_mobile1)
      .required(strings.brand_creation_yup_mobile),
    isAgency: boolean().optional(),
    industry: string().required(strings.brand_creation_yup_industry)
  })
  const methods = useForm<IBrandSettings>({
    resolver: yupResolver(schema),
    defaultValues: {
      brandLogo: '',
      industry: selectedBrand?.industry ?? ''
    }
  })

  if (!selectedBrand || !authenticatedUser) return <ErrorReload />

  const { handleSubmit, setValue, formState, reset, getValues, watch } = methods
  const [isBrandLogoDirty, setBrandLogoDirty] = useState(false)
  const [isMediaDirty, setMediaDirty] = useState(false)

  useEffect(() => {
    const onMount = async () => {
      const { brandName, brandLogo, firstname, surname, contactEmail, contactPhone, isAgency, industry, brandAudioUrl } = selectedBrand

      setValue('brandName', brandName)
      setValue('brandLogo', brandLogo)
      setValue('firstname', firstname)
      setValue('surname', surname)
      setValue('contactEmail', contactEmail)
      setValue('contactPhone', contactPhone)
      setValue('isAgency', isAgency)
      setValue('industry', industry ?? '')
      setValue('brandAudioUrl', brandAudioUrl || '')
    }
    onMount()
  }, [])

  useEffect(() => {
    setBrandLogoDirty(selectedBrand.brandLogo != getValues('brandLogo'))
  }, [watch('brandLogo')])

  useEffect(() => {
    setMediaDirty(selectedBrand.brandAudioUrl != getValues('brandAudioUrl'))
  }, [watch('brandAudioUrl')])

  const formSubmitHandler: SubmitHandler<IBrandSettings> = async ({ brandName, brandLogo, firstname, surname, contactEmail, contactPhone, isAgency, industry, brandAudioUrl }) => {
    setLoading(true)

    let brandLogoUrl = selectedBrand.brandLogo

    if ((formState.dirtyFields.brandLogo || isBrandLogoDirty) && brandLogo)
      try {
        const file = await base64toFile(brandLogo, `brandLogo_${Date.now()}`)
        brandLogoUrl = await uploadToS3(file)
      } catch (e) {
        console.error(e)
        enqueueSnackbar(strings.brand_creation_upload_logo_error, { variant: 'error' })
      }

    const { brandId } = selectedBrand

    try {
      const updatedBrand = {
        ...selectedBrand,
        brandId,
        brandName,
        brandLogo: brandLogoUrl,
        contactPerson: `${firstname} ${surname}`,
        contactEmail,
        contactPhone,
        isAgency,
        industry,
        brandAudioUrl
      }

      await rootStore.brandStore.updateBrand(updatedBrand)
      reset(getValues())
      enqueueSnackbar(strings.common_saved, { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(strings.error, { variant: 'error' })
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(formSubmitHandler)}>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} md={6}>
            <Card variant='outlined'>
              <CardContent>
                <BrandInfo />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card variant='outlined'>
              <CardContent>
                <BrandContact />
              </CardContent>
            </Card>
            <Grid container justifyContent='end' mt={2}>
              <LoadingButton variant='contained' loading={loading} disabled={!(formState.isDirty || isBrandLogoDirty || isMediaDirty)} type='submit'>
                {strings.save}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
})

export default BrandSettings
