import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter } from 'react-router-dom'
// eslint-disable-next-line import/no-cycle
import { App } from './App'

/* aws */
import Amplify from 'aws-amplify'
import config from './config'
import TagManager from 'react-gtm-module'
import firebase from 'firebase'
import rootStore from './stores/rootStore'
import ReactPixel from 'react-facebook-pixel'
import './reusableUtils/ArrayExts'

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  }
})

// Firebase Analytics
const firebaseConfig = {
  apiKey: 'AIzaSyBvwgm7kSt99dJFdpRJ-YC4dKBLa8O2u84',
  authDomain: 'nano-web-a9a61.firebaseapp.com',
  databaseURL: 'https://nano-web-a9a61.firebaseio.com',
  projectId: 'nano-web-a9a61',
  storageBucket: 'nano-web-a9a61.appspot.com',
  messagingSenderId: '350661578092',
  appId: '1:350661578092:web:e29d9797bb01ad1bcb3749',
  measurementId: 'G-6SLMWZ7120'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

// check if user disabled analytics
const analytics = firebase.analytics()

const options = {
  autoConfig: true,
  debug: false
}
ReactPixel.init('2964377870531880', undefined, options)
ReactPixel.grantConsent()
analytics.setAnalyticsCollectionEnabled(true)

if (!rootStore.userStore.authenticatedUser?.enabledAnalytics) {
  analytics.setAnalyticsCollectionEnabled(false)
  ReactPixel.revokeConsent()
}

const tagManagerArgs = {
  gtmId: 'GTM-PD98T64B'
}

TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <HashRouter>
    <App />
  </HashRouter>,
  document.getElementById('root')
)

export { analytics, TagManager }
