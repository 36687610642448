import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { array, boolean, number, object, SchemaOf, string } from 'yup'
import type { Locale as BLocale, CampaignGender as BGender, Language } from '../../../../getnano-backend/utils/Types'
import { Strings, useStrings } from '../../assets/localization/strings'
import { AgeOptions, CampaignGender, Locale, ProductType, Service, VideoDuration, VideoFeature, VideoFormat, VideoType } from '../../models/GeneralTypes'
import { CampaignInsightsUgc } from '../../requestModels'
import { keep, URL_REGEX } from '../../reusableUtils/Helpers'
import usePersistedForm from '../../reusableUtils/usePersistedForm'
import { RootStore } from '../../stores/rootStore'
import { MediaStore } from './MediaStore'

// https://stackoverflow.com/questions/53245794/field-required-based-on-value-of-another-field-formik-yup
// .when('chooseAlphabet', {
//   is: 'B',
//   then: schema => schema,
//   otherwise: yup.string().when('type', {
//     is: 'D',
//     then: yup.string().required('field required.')
//   })

type ReactHookFormArray = { id?: string; value?: string }
type DiscountCodes = { id?: string; value?: string; count?: number }
export type IUGCCampaign = {
  brandLogo?: string
  brandName: string | undefined
  service: Service

  inviteInfluencers: string[]
  productType: ProductType
  name: string
  price: number
  campaignImage?: string
  link: string
  description: string
  productPreferences: ReactHookFormArray[]
  locale: Locale
  ageRanges: AgeOptions[]
  gender?: BGender
  language: string
  videoType: VideoType
  videoDuration: VideoDuration
  videoFormat: VideoFormat
  videoFeature?: VideoFeature
  scenes: ReactHookFormArray[]
  hooks: ReactHookFormArray[]
  numberOfHooks: number // This is only so it does not lag due to Pricing component
  ctas: ReactHookFormArray[]
  numberOfCtas: number // This is only so it does not lag due to Pricing component
  mentions: ReactHookFormArray[]
  moodboard: string[]
  numberOfVideos: number
  informationCheck: boolean
  contactCheck: boolean
  noPaymentCheck: boolean
  originalNumberOfSlots: number
  brandAudioUrl?: string
}

const numberOfVideosKey = 'numberOfVideos'
const schema = (strings: Strings, isAgency: boolean, needBrandLogo: boolean) => {
  const reactHookFormArray: SchemaOf<ReactHookFormArray> = object({ id: string().optional(), value: string().optional() })
  const mentionFormArray: SchemaOf<ReactHookFormArray> = object({
    id: string().optional(),
    value: string().test('len', (val, { createError, path }) => ((val || '').length > 120 ? createError({ message: strings.campaignCreationYupMetionsLimit, path }) : true))
  })
  const hooksFormArray: SchemaOf<ReactHookFormArray> = object({
    id: string().optional(),
    value: string().test('len', (val, { createError, path }) => ((val || '').length > 200 ? createError({ message: strings.campaign_creation_yup_hooks_ctas_limit, path }) : true))
  })
  const ctasFormArray: SchemaOf<ReactHookFormArray> = object({
    id: string().optional(),
    value: string().test('len', (val, { createError, path }) => ((val || '').length > 200 ? createError({ message: strings.campaign_creation_yup_hooks_ctas_limit, path }) : true))
  })
  const scenesArray: SchemaOf<ReactHookFormArray> = object({
    id: string().required(),
    value: string().required(strings.campaign_creation_yup_scenes1)
  })
  const brandLogoFields = { brandLogo: string().required(strings.campaign_creation_yup_brand_logo) }
  const agencyFields = { ...brandLogoFields, brandName: string().required(strings.campaign_creation_yup_name) }
  return object().shape({
    ...(isAgency ? agencyFields : needBrandLogo ? brandLogoFields : {}),
    [numberOfVideosKey]: number()
      .typeError(strings.campaign_creation_yup_number_of_slots1)
      .required(strings.campaign_creation_yup_number_of_slots2)
      .max(10, strings.campaign_creation_yup_number_of_slots3),
    productType: string().required(strings.campaign_creation_yup_product_type),
    name: string().required(strings.campaign_creation_yup_name),
    price: number().typeError(strings.campaign_creation_yup_price1).min(0, strings.campaign_creation_yup_price2).required(strings.campaign_creation_yup_price3),
    campaignImage: string().required(strings.campaign_creation_yup_campaign_image),
    link: string().matches(URL_REGEX, strings.campaign_creation_yup_link1).required(strings.campaign_creation_yup_link2),
    description: string().required(strings.campaign_creation_yup_product_desc),
    productPreferences: array().of(reactHookFormArray).optional(),
    locale: string().required(strings.campaign_creation_yup_locale),
    ageRanges: array().of(string()).min(1, strings.campaign_creation_yup_age).required(strings.campaign_creation_yup_age),
    gender: string().required(strings.campaign_creation_yup_gender),
    language: string().required(strings.campaign_creation_yup_language),
    videoType: string().required(strings.campaign_creation_yup_video_type),
    videoDuration: number().required(strings.campaign_creation_yup_video_duration),
    videoFormat: string().required(strings.campaign_creation_yup_video_format),
    videoFeature: string().optional(),
    service: string().optional(),
    scenes: array().when('service', { is: Service.selfService, then: array().of(scenesArray).min(1).required(strings.campaign_creation_yup_scenes2) }),
    hooks: array().of(hooksFormArray).optional(),
    ctas: array().of(ctasFormArray).optional(),
    mentions: array().of(mentionFormArray).optional(),
    moodboard: array().optional(),
    informationCheck: boolean().isTrue(strings.campaign_creation_yup_info_check).required(strings.campaign_creation_yup_info_check),
    contactCheck: boolean().isTrue(strings.campaign_creation_yup_contact_check).required(strings.campaign_creation_yup_contact_check),
    noPaymentCheck: boolean().optional()
  })
}
const defaultValues = (): IUGCCampaign => ({
  // isAgency
  brandName: '',

  inviteInfluencers: [],
  productPreferences: [{ id: '1', value: '' }],
  scenes: [{ id: '1', value: '' }],
  hooks: [{ id: '1', value: '' }],
  ctas: [{ id: '1', value: '' }],
  numberOfHooks: 1,
  numberOfCtas: 1,
  mentions: [{ id: '1', value: '' }],
  numberOfVideos: 1,
  ageRanges: [AgeOptions.age1, AgeOptions.age2, AgeOptions.age3],
  language: 'de',
  gender: CampaignGender.ANY as string as BGender,
  videoDuration: 15,
  name: '',
  price: 0,
  link: '',
  description: '',
  informationCheck: false,
  contactCheck: false,
  noPaymentCheck: false,
  locale: Locale.DELIVERY,
  productType: ProductType.physical,
  videoType: VideoType.ad,
  videoFormat: VideoFormat.vertical,
  videoFeature: undefined,
  moodboard: [],
  originalNumberOfSlots: 0,
  service: Service.selfService
})
export const ageOptionToObj = {
  [AgeOptions.age1]: { min: 16, max: 18 },
  [AgeOptions.age2]: { min: 18, max: 30 },
  [AgeOptions.age3]: { min: 30, max: 100 }
}

const usePersistedUGCForm = (id: string, isAgency: boolean, needBrandLogo: boolean) => {
  const strings = useStrings()
  const methods = useForm({
    resolver: yupResolver(schema(strings, isAgency, needBrandLogo)),
    defaultValues: defaultValues(),
    context: { getNumber: () => methods.getValues('numberOfVideos') }
  })
  const { setValue, getValues } = methods
  const { dialogMethods, compareWithPersistenceAndAsk } = usePersistedForm(methods, `createForm_${id}`)
  return {
    dialogMethods,
    compareWithPersistenceAndAsk,
    loadIntoForm: async (campaign: CampaignInsightsUgc) => {
      const {
        productPreferences = [],
        moodboard = [],
        hooks = [],
        ctas = [],
        mentions = [],
        scenes = [],
        numberOfSlots,
        ageRanges,
        language,
        gender,
        videoDuration,
        locale,
        productInfo: { name, price, type: productType, link, description },
        campaignImage,
        videoType,
        videoFormat,
        videoFeature,
        brandLogo,
        brandName,
        service,
        brandAudioUrl
      } = campaign
      const ageRangesEnum =
        ageRanges[0].min == 16 && ageRanges[0].max == 100
          ? Object.values(AgeOptions)
          : Object.entries(ageOptionToObj).compactMap(([key, value]) => (ageRanges.some(a => a.min == value.min && a.max == value.max) ? (key as AgeOptions) : undefined))

      setValue('productType', productType)

      setValue(
        'productPreferences',
        productPreferences.map((value, id) => ({ id: `${id}`, value }))
      )

      setValue(
        'scenes',
        scenes.map((value, id) => ({ id: `${id}`, value }))
      )

      setValue(
        'hooks',
        hooks.map((value, id) => ({ id: `${id}`, value }))
      )

      setValue(
        'ctas',
        ctas.map((value, id) => ({ id: `${id}`, value }))
      )

      setValue(
        'mentions',
        mentions.map((value, id) => ({ id: `${id}`, value }))
      )

      setValue('numberOfVideos', numberOfSlots)
      setValue('ageRanges', ageRangesEnum)
      setValue('language', language)
      setValue('gender', gender as string as BGender)
      setValue('videoDuration', videoDuration)
      setValue('name', name)
      setValue('price', price / 100)
      setValue('link', link)
      setValue('description', description)
      setValue('informationCheck', true)
      setValue('contactCheck', true)
      setValue('noPaymentCheck', false)
      setValue('locale', locale as string as Locale)
      setValue('videoFormat', videoFormat)
      setValue('videoFeature', videoFeature)
      setValue('campaignImage', campaignImage)
      setValue('moodboard', moodboard)
      setValue('originalNumberOfSlots', campaign.numberOfSlots)

      if ([VideoType.ad, VideoType.premium].includes(videoType)) {
        // Only set videoType for duplications if it's ad or premium,
        setValue('videoType', videoType)
      }

      if (isAgency) {
        setValue('brandName', brandName)
        setValue('brandLogo', brandLogo)
        setValue('brandAudioUrl', brandAudioUrl)
      }

      setValue('service', service)
      return campaign
    },
    methods,
    getUgcObjectFromForm: async (selectedBrand: NonNullable<RootStore['brandStore']['selectedBrand']>, couponCode: string) => {
      const {
        campaignImage,
        mentions,
        hooks,
        ctas,
        scenes,
        language,
        videoType,
        name,
        link,
        description,
        price,
        productType,
        videoDuration,
        videoFeature,
        locale,
        productPreferences,
        gender,
        numberOfVideos,
        videoFormat,
        ageRanges,
        moodboard = [],
        brandLogo,
        brandName,
        inviteInfluencers,
        service,
        noPaymentCheck,
        brandAudioUrl
      } = getValues()
      const ageRangesObj = ageRanges.map(age => ageOptionToObj[age])
      const moodboardUrls = await moodboard
        .map(idOrUrl => MediaStore.getPromiseFromStore(idOrUrl))
        .awaitAll()
        .catch(e => {
          console.error(e)
          alert(strings.campaign_creation_upload_moodboard_error), [] as string[]
          return undefined
        })
      const audioUrl = brandAudioUrl ? await MediaStore.getPromiseFromStore(brandAudioUrl).catch(() => (alert(strings.campaign_creation_upload_brand_audio_error), '')) : ''
      const campaignImageUrl = campaignImage
        ? await MediaStore.getPromiseFromStore(campaignImage).catch(() => (alert(strings.campaign_creation_upload_campaign_image_error), ''))
        : ''
      const brandImageUrl = brandLogo ? await MediaStore.getPromiseFromStore(brandLogo).catch(() => (alert(strings.campaign_creation_upload_brand_logo_error), '')) : ''

      const brand = isAgency ? { brandId: selectedBrand.brandId, brandLogo: brandImageUrl, brandName } : keep(selectedBrand, 'brandId', 'brandLogo', 'brandName')
      if (needBrandLogo) brand.brandLogo = brandImageUrl

      return {
        ...brand,
        couponCode,
        _type: 'ugc' as const,
        // make it so per count once in array
        mentions: mentions.compactMap(s => (s.value ? s.value : undefined)),
        hooks: hooks.compactMap(s => (s.value ? s.value : undefined)),
        ctas: ctas.compactMap(s => (s.value ? s.value : undefined)),
        scenes: scenes.compactMap(s => (s.value ? s.value : undefined)),
        productPreferences: productPreferences.compactMap(s => (s.value ? s.value : undefined)),
        language: language as string as Language,
        videoType,
        campaignImage: campaignImageUrl,
        productInfo: {
          name,
          link,
          description,
          price: +price * 100,
          type: productType as ProductType
        },
        videoDuration,
        videoFeature,
        locale: locale as string as BLocale,
        gender: gender as string as BGender,
        ageRanges: ageRangesObj,
        numberOfVideos,
        moodboard: moodboardUrls,
        videoFormat,
        influencerIds: inviteInfluencers,
        service,
        noPayment: noPaymentCheck,
        brandAudioUrl: audioUrl
      }
    }
  }
}

export default usePersistedUGCForm
