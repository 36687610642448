import React from 'react'
import { Grid, Typography } from '@mui/material'
import ReactPlayer from 'react-player'
import styles from '../../style/CampaignDashboard.module.css'

interface InspirationCardProps {
  videoUrl: string
  thumbUrl: string
  title1: string
  title2: string
  bulletPoints: string[]
}

const InspirationCard = ({ videoUrl, thumbUrl, title1, title2, bulletPoints }: InspirationCardProps) => {
  return (
    <Grid item xs={12} sm={6} md={3} xl={2}>
      <div className={styles.inspirationCard}>
        <div className={styles.videoContainer}>
          <ReactPlayer
            url={videoUrl}
            light={thumbUrl}
            width='100%'
            height='100%'
            controls={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </div>
        <div className={styles.inspirationContent}>
          <Typography variant='h6' className={styles.inspirationTitle}>
            <span className={styles.primaryText}>{title1}</span> <span className={styles.secondaryText}>{title2}</span>
          </Typography>
          <div className={styles.inspirationDetails}>
            <ul>
              {bulletPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default InspirationCard
