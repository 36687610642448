import { Auth } from 'aws-amplify'
import request, { Response } from 'superagent'
import type * as BrandHandler from '../../getnano-backend/handlers/business/brandHandler'
import type * as BusinessInfluencerHandler from '../../getnano-backend/handlers/business/businessInfluencerHandler'
import type * as CampaignsHandler from '../../getnano-backend/handlers/business/campaignsHandler'
import type * as UserHandler from '../../getnano-backend/handlers/business/userHandler'
import { API_ROOT, API_ROOT_CAMPAIGNS } from './api-config'
import { check } from './reusableUtils/Helpers'
import rootStore from './stores/rootStore'

type Business = typeof BusinessInfluencerHandler & typeof BrandHandler & typeof CampaignsHandler & typeof UserHandler

type RequestReturnType<T> = Awaited<T extends { request: (...args: any) => infer R } ? (Awaited<R> extends new () => { execute: (...args: any) => infer R } ? R : R) : never>
type Exceptions = any[] | string | boolean | number | undefined | null
export type ExcludeAll<Obj> = Obj extends Exceptions ? Obj : Pick<Obj, { [K in keyof Obj]: Obj[K] extends (...args: any) => any ? never : K }[keyof Obj]>
type RRT<Key extends keyof Business> = RequestReturnType<Business[Key]>
type Args<T> = T extends { validateEvent: (...args: any) => infer Return } ? Return : never
export type Arg<T extends (...args: any[]) => any> = T extends (arg: infer R) => any ? R : never

const handleErrors = async (err: any) => {
  if (err?.response?.body?.errorMessage === 'Unauthorized') await rootStore.authStore.logout()
  return err
}

const responseBody = (res: Response) => res.body

export class Requests {
  constructor(public baseUrl: string) {}

  private async authHeaderValue() {
    const cognitoUser = await Auth.currentSession()
    return cognitoUser.getIdToken().getJwtToken()
  }

  private replacePathAndQueryPlaceholders(url: string, dontCreateQuery: boolean, data: Record<string, any> | undefined) {
    data = data ?? {}
    const entries = Object.entries(data)
    const matches = Array.from(url.matchAll(/{(.*?)}/g))
    for (const [match, key] of matches) {
      const [dataKey, value] = entries.find(([dataKey]) => key == dataKey || key == dataKey.toLowerCase()) ?? []
      check(dataKey != null && typeof value == 'string', `Invalid path parameter for ${key}`)
      url = url.replace(match, value)
      delete data[dataKey]
    }
    if (dontCreateQuery) return url
    const q = new URLSearchParams(data).toString()
    if (q) url += `?${q}`
    return url
  }

  getCustom = <K extends keyof Business>(url: string, options: { unauthed?: boolean } = {}) => {
    type RequestData = Args<Business[K]>
    return async (data: RequestData): Promise<RRT<K>> => {
      for (const key in data) {
        if (data[key] === undefined) delete data[key]
      }
      const requestBuilder = request.get(`${this.baseUrl}${this.replacePathAndQueryPlaceholders(url, false, data as any)}`).on('error', handleErrors)
      if (!options.unauthed) {
        requestBuilder.set('Authorization', `${await this.authHeaderValue()}`)
      }
      return await requestBuilder.then(responseBody)
    }
  }

  postCustom = <K extends keyof Business>(url: string, options: { unauthed?: boolean } = {}) => {
    type RequestData = Args<Business[K]>
    return async (data: RequestData): Promise<RRT<K>> => {
      const requestBuilder = request
        .post(`${this.baseUrl}${this.replacePathAndQueryPlaceholders(url, true, data as any)}`, data as any)
        .set('Content-Type', 'application/json')
        .on('error', handleErrors)
      if (!options.unauthed) {
        requestBuilder.set('Authorization', `${await this.authHeaderValue()}`)
      }
      return await requestBuilder.then(responseBody)
    }
  }
}

const requests = new Requests(API_ROOT)
const requestsCampaign = new Requests(API_ROOT_CAMPAIGNS)

const Authentication = {
  current: () => Auth.currentAuthenticatedUser(),
  currentSession: () =>
    Auth.currentSession()
      .then()
      .catch(err => console.log(err)),
  login: (email: string, password: string) => Auth.signIn(email, password),
  register: (
    email: string,
    password: string,
    firstname: string,
    name: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_content?: string,
    referral_code?: string
  ) => {
    const attributes: Record<string, string> = {
      'custom:firstname': firstname,
      'custom:name': name
    }
    if (utm_source) attributes['custom:utm_source'] = utm_source
    if (utm_medium) attributes['custom:utm_medium'] = utm_medium
    if (utm_campaign) attributes['custom:utm_campaign'] = utm_campaign
    if (utm_content) attributes['custom:utm_content'] = utm_content
    if (referral_code) attributes['custom:referral_code'] = referral_code

    return Auth.signUp({
      username: email,
      password: password,
      attributes: attributes
    })
  },
  forgotPassword: (email: string) => Auth.forgotPassword(email),
  forgotPasswordSubmit: (email: string, confirmationCode: string, newPassword: string) => Auth.forgotPasswordSubmit(email, confirmationCode, newPassword),
  changePassword: (user: string, oldPassword: string, newPassword: string) => Auth.changePassword(user, oldPassword, newPassword),
  completeNewPassword: (user: any, newPassword: string) => Auth.completeNewPassword(user, newPassword, {}),
  logout: () => Auth.signOut(),
  setFeedback: requests.postCustom<'postSignUpQuestions'>(`/business/user/feedback`)
}

const Users = {
  checkReferralCode: requests.getCustom<'checkReferralCode'>('/business/user/referral', { unauthed: true }),
  save: requests.postCustom<'updateUser'>('/business/user'),
  getInformation: requests.getCustom<'getUser'>('/business/user'),
  hideInformation: requests.postCustom<'hideInformation'>('/business/user/information'),
  optOutAnalytics: requests.getCustom<'optOutAnalytics'>('/business/user/optout/analytics'),
  giveFeedback: requests.getCustom<'giveFeedback'>('/business/feedback')
}

const Brands = {
  create: requests.postCustom<'createBrand'>('/business/brand'),
  /** @deprecated not in backend */
  // sepaIntent: (payload: { contactPerson: string; contactEmail: string; brandName: string; brandId?: string }) => requests.post('/business/brand/sepa', payload),
  getBrands: requests.getCustom<'getAllBrands'>('/business/user/brands'),
  get: requests.getCustom<'getBrand'>(`/business/brand/{brandid}`),
  update: requests.postCustom<'updateBrand'>('/business/brand/update'),
  getAllUsers: requests.getCustom<'getAllUsers'>(`/business/brand/{brandid}/users/all`),
  removeUser: requests.postCustom<'removeUserFromBrand'>(`/business/brand/{brandid}/users/remove`),
  addUserToBrand: requests.postCustom<'addUserToBrand'>(`/business/brand/{brandid}/users/add`),
  updateBrandOfUser: requests.postCustom<'updateUserOfBrand'>(`/business/brand/{brandid}/users/update`),
  getInvoices: requests.getCustom<'getInvoices'>(`/business/brand/{brandid}/invoices`),
  getCard: requests.getCustom<'getCard'>(`/business/brand/{brandid}/card`),
  updateCard: requests.postCustom<'updateCard'>(`/business/brand/{brandid}/card`),
  startPayment: requests.postCustom<'startPayment'>(`/business/startPayment`),
  attachPaymentMethod: requests.postCustom<'attachPaymentMethod'>(`/business/attachPaymentMethod`),
  getPricing: requestsCampaign.getCustom<'getPricing'>(`/business/getPricing`),
  getTimeline: requestsCampaign.getCustom<'getTimeline'>(`/business/getTimeline`),
  previewInvoice: requestsCampaign.postCustom<'previewInvoice'>('/business/previewInvoice')
}

const Campaigns = {
  getAll: requests.getCustom<'getCampaigns'>(`/business/{brandid}/campaign`),
  getCampaign: requestsCampaign.getCustom<'getCampaign'>(`/business/campaign/{brandid}/{campaignid}`),
  create: requestsCampaign.postCustom<'createCampaign'>('/business/campaign'),
  setActive: requestsCampaign.postCustom<'setActive'>(`/business/{brandid}/{campaignid}/active`),
  update: requestsCampaign.postCustom<'updateCampaign'>(`/business/campaign/{brandid}/{campaignid}/update`)
}

const Shop = {
  acceptInfluencer: requestsCampaign.postCustom<'acceptInfluencer'>(`/business/influencer/{campaignid}/accept`),
  denyUgcInfluencer: requestsCampaign.postCustom<'denyInfluencer'>(`/business/influencer/{campaignid}/deny`),
  removeInfluencer: requestsCampaign.postCustom<'removeFromCampaign'>(`/business/campaign/{brandid}/{campaignid}/remove`),
  buyMoreSlots: requestsCampaign.postCustom<'buyMoreSlots'>('/business/buyMoreSlots'),
  getCouponAfterAcceptingLastInfluencer: requestsCampaign.postCustom<'getCouponAfterAcceptingLastInfluencer'>('/business/couponAfterLastInfluencer')
}

const Status = {
  onSent: requestsCampaign.postCustom<'productSent'>(`/business/influencer/{campaignid}/sent`)
}

const UGC = {
  approveContent: requestsCampaign.postCustom<'approveContent'>(`/business/ugc/approve`),
  giveFeedbackToContent: requestsCampaign.postCustom<'giveFeedbackToContent'>(`/business/ugc/feedback`),
  getAllFinishedCreators: requestsCampaign.getCustom<'getAllFinishedCreators'>(`/business/ugc/allFinishedContent`),
  upgradeVideo: requests.postCustom<'upgradeVideo'>(`/business/upgradeVideo`),
  setContentFeedback: requestsCampaign.postCustom<'setContentRating'>('/business/ugc/contentRating')
}

export default {
  Authentication,
  Users,
  Brands,
  Campaigns,
  Shop,
  Status,
  UGC
}
