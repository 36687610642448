import { Box, FormControl, FormHelperText, Grid, Typography } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { useReadonly } from '../../../reusableUtils/useReadonly'
import { useMediaStore } from '../../createUgcCampaign/MediaStore'
import 'cropperjs/dist/cropper.css'
import { useSnackbar } from 'notistack'
import { AudioPlayer } from 'react-audio-play'
import { color, theme } from '../../../theme'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

type Props = { name: string; label: string; accept: string; width: string; height: string; onChange?: () => void }

const ReactHookFormAudioDropzone = ({ name, label, width, height, accept }: Props) => {
  const [file, setFile] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()
  const isReadonly = useReadonly(name)

  const {
    formState: { errors }
  } = useFormContext()

  const inputFile = useRef<HTMLInputElement | null>(null)
  const [[store = undefined], { remove, replace }] = useMediaStore(name, false)

  const onDrop = useCallback<DropzoneOptions['onDrop'] & {}>(
    async droppedFile => {
      if (isReadonly) return
      const file = droppedFile[0]
      if (file.size / 1_000_000 > 3) {
        enqueueSnackbar('Max. 3MB erlaubt', { variant: 'error' })
      } else {
        remove(store)
        setFile(URL.createObjectURL(file))
        replace(store, file)
      }
    },
    [file, isReadonly]
  )

  const { getRootProps, isDragActive } = useDropzone({ onDrop, accept })

  const deleteFile = () => remove(store)

  const renderInner = () => {
    return (
      <>
        <Grid item textAlign='center'>
          <AudioFileIcon color='primary' sx={{ width: '4.5rem', height: '4.5rem' }} />
        </Grid>
        <Grid item>
          <Typography variant='h3' textAlign='center'>
            {label}
          </Typography>
        </Grid>
        <Grid item sx={{ textAlign: 'center' }}>
          <Typography variant='caption'>File: .MP3 Info and max. 10 Seconds</Typography>
        </Grid>
      </>
    )
  }

  const inputProps = !file && !isReadonly ? { ...getRootProps() } : undefined
  const background = isDragActive ? color.backgroundPurple : color.grey4
  // const isMobile = !!navigator.userAgent.match(/(safari|android)/i)
  return (
    <>
      <input type='file' style={{ display: 'none' }} name={name} ref={inputFile} onChange={e => onDrop(e.target.files ? Array.from(e.target.files) : [], [], undefined as any)} />
      <Grid container sx={{ height: store?.src ? '60%' : height, width, background, border: '1px dotted', borderColor: color.grey3, borderRadius: '8px' }}>
        <Grid container item flexDirection='column' justifyContent='space-evenly' {...inputProps} onClick={() => inputFile.current?.click()}>
          {renderInner()}
        </Grid>
      </Grid>
      {store?.src && (
        <Box sx={{ mt: 2 }}>
          <HighlightOffIcon fontSize='small' sx={{ float: 'right' }} onClick={deleteFile} />
          <AudioPlayer
            src={store.src}
            color={theme.palette.primary.main}
            sliderColor={theme.palette.primary.main}
            style={{ background: color.grey4, borderRadius: '15px', padding: '30px', width: '90%' }}
          />
        </Box>
      )}
      {errors[name] && (
        <FormControl error>
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
      )}
    </>
  )
}

export default ReactHookFormAudioDropzone
