import { useEffect, useRef } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { v4, validate } from 'uuid'
import { base64toFile, check, uploadToS3 } from '../../reusableUtils/Helpers'
import imageCompression from 'browser-image-compression'

export class MediaStore {
  private _promise?: Promise<string>
  /** only for storing s3 url */
  url?: URL
  /** file or base64 */
  protected readonly source?: File | URL
  readonly id = v4()
  /** used as id in <img>, whenever this.url changes. for some reason <img> doesn't rerender without this */
  valueId = v4()

  refresh() {
    this.valueId = v4()
  }

  private async upload(prefix: string) {
    if (this.url) return this.url.toString()
    this.ensureHasSource()
    const name = `${prefix}_${Date.now()}.${this.pathExtension}`

    let file = this.source instanceof File ? new File([this.source], name, { type: this.source.type }) : await base64toFile(this.source, name)
    if (file.type.includes('image')) file = await imageCompression(file, { maxSizeMB: 2 })
    const link = await uploadToS3(file)
    // In case want to test without uploading
    // await delay(1000)
    // const link = URL.createObjectURL(file)
    console.log(link)
    this.url = new URL(link)
    this.refresh()
    return link
  }

  get pathExtension() {
    if (!this.source) return
    if (this.source instanceof File) return this.source.name.split('.').at(-1)
    if (this.source.protocol.startsWith('data')) {
      const url = `${this.source}`
      const type = url.substring(url.indexOf(':') + 1, url.indexOf(';'))
      check(type.startsWith('image') || type.startsWith('video'), `Invalid format ${type}`)
      return type.split('/')[1]
    }
    return this.source.pathname.split('/').at(-1)?.split('.').at(-1)
  }

  /**
   * @param source file or base64 or s3 url
   */
  constructor(source: File | string) {
    if (source instanceof File) {
      this.source = source
    } else {
      const url = new URL(source)
      if (url.protocol == 'https:') {
        this.url = url
      } else {
        this.source = url
      }
    }
  }

  /** is this store created from url or already uploaded? */
  isConstant(): this is { url: URL } {
    return !!this.url
  }

  promise(prefix?: string) {
    if (this._promise) return this._promise
    check(prefix, 'Prefix is required when uploading promise')
    return (this._promise = this.upload(prefix))
  }
  /** url of source. Can be file (url) or base64 or s3 url */
  get src() {
    if (this.url && this.source == null) return this.url.toString()
    this.ensureHasSource()
    if (this.source instanceof File) return URL.createObjectURL(this.source)
    return this.source.toString()
  }
  get isImage() {
    if (this.isConstant()) {
      const url = this.url.toString().toLowerCase()
      return url.endsWith('png') || url.endsWith('jpeg') || url.endsWith('jpg')
    } else {
      if (!(this.source instanceof File)) return this.source?.protocol == 'data:'
      return this.source.type === 'image/jpeg' || this.source.type === 'image/png'
    }
  }
  isSameSource(file: File) {
    return this.source instanceof File && this.source.name == file.name
  }

  private ensureHasSource(): asserts this is { readonly source: File | URL } {
    check(this.source, 'Source is expected, since url was not provided')
  }

  static async getPromiseFromStore(idOrUrl: string) {
    const media = validate(idOrUrl) ? stores.find(s => s.id == idOrUrl) : idOrUrl
    check(media, `Missing store for ${idOrUrl}`)
    return typeof media == 'string' ? media : await media.promise()
  }
}

let stores: MediaStore[] = []

export const useMediaStore = (name: string, isArray: boolean) => {
  const localStores = useRef([] as MediaStore[])
  const { control, setValue } = useFormContext()
  const value = useWatch({ control, name }) as string | string[] | undefined

  const valueChanged = () => {
    const srcs = localStores.current.map(s => s.url?.toString() || s.id)
    setValue(name, isArray ? srcs : srcs[0])
  }
  const create = (source: File | string) => {
    const media = new MediaStore(source)
    media.promise(name).then(valueChanged)
    return media
  }
  const add = (source: File | string) => {
    const media = create(source)
    localStores.current.push(media)
    stores.push(media)
    valueChanged()
    return media
  }
  const remove = (media: MediaStore | undefined) => {
    if (!media) return
    localStores.current = localStores.current.filter(m => m !== media)
    valueChanged()
  }
  const replace = (media: MediaStore | undefined, source: File | string) => {
    const newMedia = create(source)
    localStores.current = localStores.current.filter(m => m !== media)
    localStores.current.push(newMedia)
    stores = stores.filter(m => m !== media)
    stores.push(newMedia)
    valueChanged()
    return newMedia
  }

  useEffect(() => {
    for (const url of [value].flat().filterNotNull()) {
      if (validate(url)) continue
      const store = stores.find(s => s.isConstant() && s.url.toString() == url)
      if (store) {
        if (!localStores.current.find(s => s === store)) localStores.current.push(store)
        continue
      }
      if (url) add(url)
    }
  }, [JSON.stringify(value)])

  return [
    localStores.current,
    {
      add,
      remove,
      replace,
      getPromiseFromStore(idOrUrl: string) {
        const media = validate(idOrUrl) ? stores.find(s => s.id == idOrUrl) : idOrUrl
        check(media, `Missing store for ${idOrUrl}`)
        return typeof media == 'string' ? media : media.src
      }
    }
  ] as const
}
