import { makeAutoObservable } from 'mobx'
import agent from '../agent'
import config from '../config'
import { RootStore } from './rootStore'

class AuthStore {
  rootStore: RootStore
  userId = window.localStorage.getItem('userId')
  token = window.localStorage.getItem(`CognitoIdentityServiceProvider.${config.cognito.APP_CLIENT_ID}.${this.userId}.idToken`)

  get isAuthenticated() {
    return this.token != null
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    makeAutoObservable(this)
  }

  setToken(token: any) {
    this.token = token
  }

  setUserId(userId: string) {
    window.localStorage.setItem('userId', userId)
    this.userId = userId
  }

  currentUser() {
    return agent.Authentication.current()
  }

  async login(email: string, password: string) {
    return agent.Authentication.login(email, password).then(async congitoUser => {
      if (congitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') await agent.Authentication.completeNewPassword(congitoUser, '')
      this.setToken(congitoUser.signInUserSession.idToken.jwtToken)
      this.setUserId(congitoUser.getUsername())
      await this.rootStore.userStore.getUser()
      return await this.currentUser()
    })
  }

  register(
    email: string,
    password: string,
    firstname: string,
    name: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_content?: string,
    referral_code?: string
  ) {
    return agent.Authentication.register(email, password, firstname, name, utm_source, utm_medium, utm_campaign, utm_content, referral_code)
  }

  forgotPassword(email: string) {
    return agent.Authentication.forgotPassword(email)
  }

  forgotPasswordSubmit(email: string, confirmationCode: string, newPassword: string) {
    return agent.Authentication.forgotPasswordSubmit(email, confirmationCode, newPassword)
  }

  async changePassword(oldPassword: string, newPassword: string) {
    const user = await this.currentUser()
    return agent.Authentication.changePassword(user, oldPassword, newPassword)
  }

  async logout() {
    console.log('logout')
    this.setToken(undefined)
    this.rootStore.brandStore.reset()
    this.rootStore.campaignStore.reset()
    localStorage.clear()
    return await agent.Authentication.logout()
  }

  setFeedback(onboardingFeedback: string) {
    return agent.Authentication.setFeedback({ onboardingFeedback })
  }

  checkReferralCode = agent.Users.checkReferralCode
}

export default AuthStore
