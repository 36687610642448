
  /* eslint @typescript-eslint/no-unused-vars: 0 */
type P = string | number
type S = string
export default {
agreeToCampaignTerm_1: `I have read the rules`,
agree_to_campaign_term_1: `I have read the rules`,
agreeToCampaignText_2: `I will not have any contact with the brand outside of this platform.`,
agree_to_campaign_text_2: `I will not have any contact with the brand outside of this platform.`,
applicationVideo: `Application Video`,
application_video: `Application Video`,
applyToTheCampaign: `Apply now`,
apply_to_the_campaign: `Apply now`,
back: `Back`,
brandCreationContactEmail: `E-mail Address*`,
brand_creation_contact_email: `E-mail Address*`,
brandCreationContactFirstname: `First Name*`,
brand_creation_contact_firstname: `First Name*`,
brandCreationContactPhone: `Mobile*`,
brand_creation_contact_phone: `Mobile*`,
brandCreationContactSurname: `Last Name*`,
brand_creation_contact_surname: `Last Name*`,
brandCreationContactTitle: `Contact Person`,
brand_creation_contact_title: `Contact Person`,
brandCreationCreateAgency: `CREATE AGENCY`,
brand_creation_create_agency: `CREATE AGENCY`,
brandCreationCreateBrand: `CREATE BRAND`,
brand_creation_create_brand: `CREATE BRAND`,
brandCreationError: `Oh no, you forgot something!`,
brand_creation_error: `Oh no, you forgot something!`,
brandCreationInfoAgencyLogo: `Agency Logo`,
brand_creation_info_agency_logo: `Agency Logo`,
brandCreationInfoAgencyLogoTitle: `Agency Logo (optional)`,
brand_creation_info_agency_logo_title: `Agency Logo (optional)`,
brandCreationInfoAgencyName: `Agency Name*`,
brand_creation_info_agency_name: `Agency Name*`,
brandCreationInfoAgencyNameQuestion: `How is your Agency called?`,
brand_creation_info_agency_name_question: `How is your Agency called?`,
brandCreationInfoBrandLogo: `Brand Logo`,
brand_creation_info_brand_logo: `Brand Logo`,
brandCreationInfoBrandLogoTitle: `Brand Logo (optional)`,
brand_creation_info_brand_logo_title: `Brand Logo (optional)`,
brandCreationInfoBrandName: `Brand Name*`,
brand_creation_info_brand_name: `Brand Name*`,
brandCreationInfoBrandNameQuestion: `How is your Brand called?`,
brand_creation_info_brand_name_question: `How is your Brand called?`,
brandCreationInfoIndustry: `Industry`,
brand_creation_info_industry: `Industry`,
brandCreationInfoIndustryOptionAccessories: `Accessories (Jewelery, Watches etc.)`,
brand_creation_info_industry_option_accessories: `Accessories (Jewelery, Watches etc.)`,
brandCreationInfoIndustryOptionAgency: `Advertising Agency`,
brand_creation_info_industry_option_agency: `Advertising Agency`,
brandCreationInfoIndustryOptionApparel: `Apparel`,
brand_creation_info_industry_option_apparel: `Apparel`,
brandCreationInfoIndustryOptionBeauty: `Beauty`,
brand_creation_info_industry_option_beauty: `Beauty`,
brandCreationInfoIndustryOptionBeverages: `Beverages`,
brand_creation_info_industry_option_beverages: `Beverages`,
brandCreationInfoIndustryOptionBusiness: `Business Services`,
brand_creation_info_industry_option_business: `Business Services`,
brandCreationInfoIndustryOptionEducation: `Education`,
brand_creation_info_industry_option_education: `Education`,
brandCreationInfoIndustryOptionEntertainment: `Entertainment`,
brand_creation_info_industry_option_entertainment: `Entertainment`,
brandCreationInfoIndustryOptionFinancial: `Financial Services`,
brand_creation_info_industry_option_financial: `Financial Services`,
brandCreationInfoIndustryOptionFitness: `Fitness/Sports`,
brand_creation_info_industry_option_fitness: `Fitness/Sports`,
brandCreationInfoIndustryOptionFood: `Food`,
brand_creation_info_industry_option_food: `Food`,
brandCreationInfoIndustryOptionGames: `Games`,
brand_creation_info_industry_option_games: `Games`,
brandCreationInfoIndustryOptionHealth: `Health`,
brand_creation_info_industry_option_health: `Health`,
brandCreationInfoIndustryOptionJobs: `Jobs`,
brand_creation_info_industry_option_jobs: `Jobs`,
brandCreationInfoIndustryOptionMobile: `Mobile Apps`,
brand_creation_info_industry_option_mobile: `Mobile Apps`,
brandCreationInfoIndustryOptionOther: `Other`,
brand_creation_info_industry_option_other: `Other`,
brandCreationInfoIndustryOptionPets: `Pets & Animals`,
brand_creation_info_industry_option_pets: `Pets & Animals`,
brandCreationSectionAgencyInfo: `Agency Info`,
brand_creation_section_agency_info: `Agency Info`,
brandCreationSectionBrandInfo: `Brand Info`,
brand_creation_section_brand_info: `Brand Info`,
brandCreationSectionContact: `Contact Person`,
brand_creation_section_contact: `Contact Person`,
brandCreationSectionUsers: `Employees`,
brand_creation_section_users: `Employees`,
brandCreationUploadLogoError: `Error uploading the logo. The brand is still created. Please report to ugc@stylink.com so that we can add the missing logo.`,
brand_creation_upload_logo_error: `Error uploading the logo. The brand is still created. Please report to ugc@stylink.com so that we can add the missing logo.`,
brandCreationUsersBody: `You can assign roles to other users which have the following functions:
          <br /> <br />
          <b> Admin: </b> can edit brand settings and campaigns
          <br />
          <b> Marketer: </b> can edit campaigns
          <br />
          <b> Analyst: </b> can view campaigns
          <br />
          <b> Accountant: </b> can only view invoice overview`,
brand_creation_users_body: `You can assign roles to other users which have the following functions:
          <br /> <br />
          <b> Admin: </b> can edit brand settings and campaigns
          <br />
          <b> Marketer: </b> can edit campaigns
          <br />
          <b> Analyst: </b> can view campaigns
          <br />
          <b> Accountant: </b> can only view invoice overview`,
brandCreationUsersEmailLabel: `E-mail Address`,
brand_creation_users_email_label: `E-mail Address`,
brandCreationUsersInvite: `Invite`,
brand_creation_users_invite: `Invite`,
brandCreationUsersRoleAccountant: `Accountant`,
brand_creation_users_role_accountant: `Accountant`,
brandCreationUsersRoleAdmin: `Admin`,
brand_creation_users_role_admin: `Admin`,
brandCreationUsersRoleAnalyst: `Analyst`,
brand_creation_users_role_analyst: `Analyst`,
brandCreationUsersRoleLabel: `Role`,
brand_creation_users_role_label: `Role`,
brandCreationUsersRoleMarketer: `Marketer`,
brand_creation_users_role_marketer: `Marketer`,
brandCreationUsersTitle: `More Users`,
brand_creation_users_title: `More Users`,
brandCreationYupBrandName: `Brand Name is a mandatory field`,
brand_creation_yup_brand_name: `Brand Name is a mandatory field`,
brandCreationYupEmail: `Email is a mandatory field`,
brand_creation_yup_email: `Email is a mandatory field`,
brandCreationYupEmail1: `Invalid E-mail Address`,
brand_creation_yup_email1: `Invalid E-mail Address`,
brandCreationYupEmail2: `Email of users is a mandatory field`,
brand_creation_yup_email2: `Email of users is a mandatory field`,
brandCreationYupFirstname: `First Name is a mandatory field`,
brand_creation_yup_firstname: `First Name is a mandatory field`,
brandCreationYupIndustry: `Industry is a mandatory field`,
brand_creation_yup_industry: `Industry is a mandatory field`,
brandCreationYupMobile: `Mobile number is a mandatory field`,
brand_creation_yup_mobile: `Mobile number is a mandatory field`,
brandCreationYupMobile1: `Invalid mobile number`,
brand_creation_yup_mobile1: `Invalid mobile number`,
brandCreationYupRole: `Role is a mandatory field`,
brand_creation_yup_role: `Role is a mandatory field`,
brandCreationYupSurname: `Last Name is a mandatory field`,
brand_creation_yup_surname: `Last Name is a mandatory field`,
brandInvoicesCredit: `Credit Note`,
brand_invoices_credit: `Credit Note`,
brandInvoicesDebit: `Debit`,
brand_invoices_debit: `Debit`,
brandInvoicesDownload: `Download invoice`,
brand_invoices_download: `Download invoice`,
brandInvoicesDownloadError: `Please contact Nano Support`,
brand_invoices_download_error: `Please contact Nano Support`,
brandInvoicesEmpty: `No bills yet`,
brand_invoices_empty: `No bills yet`,
brandInvoicesError: `Unknown error`,
brand_invoices_error: `Unknown error`,
brandInvoicesMissingDate: `Missing date`,
brand_invoices_missing_date: `Missing date`,
brandInvoicesNoAuth: `You have no authorization to see this area.`,
brand_invoices_no_auth: `You have no authorization to see this area.`,
brandInvoicesNumber: `Invoice Number`,
brand_invoices_number: `Invoice Number`,
brandInvoicesTableDate: `Date`,
brand_invoices_table_date: `Date`,
brandInvoicesTableSum: `Total`,
brand_invoices_table_sum: `Total`,
brandInvoicesTableType: `Reference`,
brand_invoices_table_type: `Reference`,
brandPaymentBalanceCurrent: `current balances:`,
brand_payment_balance_current: `current balances:`,
brandPaymentCreditTitle: `Credit`,
brand_payment_credit_title: `Credit`,
brandPaymentCreditcardTitle: `Credit Card`,
brand_payment_creditcard_title: `Credit Card`,
brandPaymentMethodChoose: `Choose the means of payment`,
brand_payment_method_choose: `Choose the means of payment`,
brandPaymentMethodChooseError: `Error`,
brand_payment_method_choose_error: `Error`,
brandPaymentMethodNoRole: `You have no authorization to change the payment info!`,
brand_payment_method_no_role: `You have no authorization to change the payment info!`,
brandPaymentMethodSaved: (last4: P) => `Saved payment method ends with the digits ${last4 as S}` as const,
brand_payment_method_saved: (last4: P) => `Saved payment method ends with the digits ${last4 as S}` as const,
brandPaymentMethodSavedNothing: `None specified yet`,
brand_payment_method_saved_nothing: `None specified yet`,
brandPaymentMethodTitle: `Payment method`,
brand_payment_method_title: `Payment method`,
brandPaymentPricingButton: `Just click here!`,
brand_payment_pricing_button: `Just click here!`,
brandPaymentPricingEmail: `Otherwise write us an email under`,
brand_payment_pricing_email: `Otherwise write us an email under`,
brandPaymentPricingTitle: `Questions about pricing`,
brand_payment_pricing_title: `Questions about pricing`,
brandPaymentSave: `Save Payment Method`,
brand_payment_save: `Save Payment Method`,
brandPaymentSepaAgreement: `By signing this mandate form, you authorise (A) Stylink Social Media GmbH and Stripe to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instructions from Stylink Social Media GmbH.`,
brand_payment_sepa_agreement: `By signing this mandate form, you authorise (A) Stylink Social Media GmbH and Stripe to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with the instructions from Stylink Social Media GmbH.`,
brandPaymentSepaTitle: `SEPA`,
brand_payment_sepa_title: `SEPA`,
brandSettingsBrandButtonSave: `Save`,
brand_settings_brand_button_save: `Save`,
brandSettingsBrandCompanyTitle: `Company Information`,
brand_settings_brand_company_title: `Company Information`,
brandSettingsBrandFirmAddressTitle: `Company Address*`,
brand_settings_brand_firm_address_title: `Company Address*`,
brandSettingsBrandFirmCityTitle: `Location*`,
brand_settings_brand_firm_city_title: `Location*`,
brandSettingsBrandFirmCountryTitle: `Country*`,
brand_settings_brand_firm_country_title: `Country*`,
brandSettingsBrandFirmNameTitle: `Company Name*`,
brand_settings_brand_firm_name_title: `Company Name*`,
brandSettingsBrandFirmZipTitle: `Postal Code*`,
brand_settings_brand_firm_zip_title: `Postal Code*`,
brandSettingsBrandLogoPlaceh: `Upload Brand Logo*`,
brand_settings_brand_logo_placeh: `Upload Brand Logo*`,
brandSettingsBrandLogoTitle: `Brand Logo`,
brand_settings_brand_logo_title: `Brand Logo`,
brandSettingsBrandNamePlaceh: `Brand Name*`,
brand_settings_brand_name_placeh: `Brand Name*`,
brandSettingsBrandNameTitle: `What is your Brand called?`,
brand_settings_brand_name_title: `What is your Brand called?`,
brandSettingsBrandUidnumberDisclaimer: `We cannot make any changes to sales tax after the accounting. Please ensure whether your company has a vat number.`,
brand_settings_brand_uidnumber_disclaimer: `We cannot make any changes to sales tax after the accounting. Please ensure whether your company has a vat number.`,
brandSettingsBrandUidnumberTitle: `Vat Number*`,
brand_settings_brand_uidnumber_title: `Vat Number*`,
brandSettingsBrandUserEmailTitle: `E-mail Address*`,
brand_settings_brand_user_email_title: `E-mail Address*`,
brandSettingsBrandUserFirstnameTitle: `First Name*`,
brand_settings_brand_user_firstname_title: `First Name*`,
brandSettingsBrandUserPhoneTitle: `Mobile Number*`,
brand_settings_brand_user_phone_title: `Mobile Number*`,
brandSettingsBrandUserSurnameTitle: `Last Name*`,
brand_settings_brand_user_surname_title: `Last Name*`,
brandSettingsBrandUserTitle: `Contact Person`,
brand_settings_brand_user_title: `Contact Person`,
brandSettingsBrandVatnumberTitle: `My company has a vat tax number*`,
brand_settings_brand_vatnumber_title: `My company has a vat tax number*`,
brandSettingsNavInvoices: `Bills`,
brand_settings_nav_invoices: `Bills`,
brandSettingsNavPayment: `Payments`,
brand_settings_nav_payment: `Payments`,
brandSettingsNavSettings: `Settings`,
brand_settings_nav_settings: `Settings`,
brandSettingsNavUsers: `Users`,
brand_settings_nav_users: `Users`,
brandUsersCampaignSubtitle: `Brand Management`,
brand_users_campaign_subtitle: `Brand Management`,
brandUsersCampaignTitle: `Campaigns`,
brand_users_campaign_title: `Campaigns`,
brandUsersEmailTitle: `E-mail Address`,
brand_users_email_title: `E-mail Address`,
brandUsersError: `There was a mistake, try again`,
brand_users_error: `There was a mistake, try again`,
brandUsersInfo: `You can assign roles to other users which have the following functions:
          <br /> <br />
          <b> Admin: </b> can edit brand settings and campaigns
          <br />
          <b> Marketer: </b> can edit campaigns
          <br />
          <b> Analyst: </b> can view campaigns
          <br />
          <b> Accountant: </b> can only view invoice overview`,
brand_users_info: `You can assign roles to other users which have the following functions:
          <br /> <br />
          <b> Admin: </b> can edit brand settings and campaigns
          <br />
          <b> Marketer: </b> can edit campaigns
          <br />
          <b> Analyst: </b> can view campaigns
          <br />
          <b> Accountant: </b> can only view invoice overview`,
brandUsersInsert: `Add user`,
brand_users_insert: `Add user`,
brandUsersInsertAdminOnly: `Only admins can add or remove users.`,
brand_users_insert_admin_only: `Only admins can add or remove users.`,
brandUsersInsertInvalidEmail: `No valid email-address`,
brand_users_insert_invalid_email: `No valid email-address`,
brandUsersInvite: `Invite`,
brand_users_invite: `Invite`,
brandUsersTableEmail: `E-mail`,
brand_users_table_email: `E-mail`,
brandUsersTableRole: `Role`,
brand_users_table_role: `Role`,
campaignAlertTitle: `No data!`,
campaign_alert_title: `No data!`,
campaignApplication: `Application`,
campaign_application: `Application`,
campaignApplicationVideoUpload: `Upload sample video`,
campaign_application_video_upload: `Upload sample video`,
campaignCreationAgeLabel: `Age (multiple selectable)`,
campaign_creation_age_label: `Age (multiple selectable)`,
campaignCreationBudget_10Discount: `10% Discount on Videotyp`,
campaign_creation_budget_10_discount: `10% Discount on Videotyp`,
campaignCreationBudget_5Discount: `5% Discount on Videotyp`,
campaign_creation_budget_5_discount: `5% Discount on Videotyp`,
campaignCreationBudgetCreator: (number: P) => `${number as S} Content Creator.` as const,
campaign_creation_budget_creator: (number: P) => `${number as S} Content Creator.` as const,
campaignCreationBudgetCreators: (number: P) => `${number as S} Content Creators.` as const,
campaign_creation_budget_creators: (number: P) => `${number as S} Content Creators.` as const,
campaignCreationBudgetInfo1: `Your order is delivered by`,
campaign_creation_budget_info1: `Your order is delivered by`,
campaignCreationBudgetInfo2: ``,
campaign_creation_budget_info2: ``,
campaignCreationBudgetQuestion: `How many videos do you want to order?`,
campaign_creation_budget_question: `How many videos do you want to order?`,
campaignCreationBudgetRecommended: `recommended`,
campaign_creation_budget_recommended: `recommended`,
campaignCreationBudgetShowcaseCreator: (number: P) => `${number as S} Content Creator.` as const,
campaign_creation_budget_showcase_creator: (number: P) => `${number as S} Content Creator.` as const,
campaignCreationBudgetShowcaseCreators: (number: P) => `${number as S} Content Creators.` as const,
campaign_creation_budget_showcase_creators: (number: P) => `${number as S} Content Creators.` as const,
campaignCreationBudgetTip: `We recommend that you have several creators delivery the same video concept to be able to select from the best.`,
campaign_creation_budget_tip: `We recommend that you have several creators delivery the same video concept to be able to select from the best.`,
campaignCreationBudgetTooltip: `You can create a campaign with all your available videos or divide them into several campaigns.`,
campaign_creation_budget_tooltip: `You can create a campaign with all your available videos or divide them into several campaigns.`,
campaignCreationBudgetVideoCount: `Number of Videos`,
campaign_creation_budget_video_count: `Number of Videos`,
campaignCreationBudgetVideoCountShowcaseTip: `Online marketers achieve the best results with 2-3 showcase videos per ad campaign.`,
campaign_creation_budget_video_count_showcase_tip: `Online marketers achieve the best results with 2-3 showcase videos per ad campaign.`,
campaignCreationBudgetVideoCountTip: `Online marketers achieve the best results with 3-5 creators per ad campaign.`,
campaign_creation_budget_video_count_tip: `Online marketers achieve the best results with 3-5 creators per ad campaign.`,
campaignCreationBudgetVideosLabel: `Videos`,
campaign_creation_budget_videos_label: `Videos`,
campaignCreationCodesBody: `Make sure that the creator can order the product free of charge and does not have to pay shipping costs.`,
campaign_creation_codes_body: `Make sure that the creator can order the product free of charge and does not have to pay shipping costs.`,
campaignCreationCodesCodeLabel: `Coupon Code`,
campaign_creation_codes_code_label: `Coupon Code`,
campaignCreationCodesCountLabel: `Number`,
campaign_creation_codes_count_label: `Number`,
campaignCreationCodesShowcaseBody: `Make sure that the creator can order the product free of charge and does not have to pay shipping costs.`,
campaign_creation_codes_showcase_body: `Make sure that the creator can order the product free of charge and does not have to pay shipping costs.`,
campaignCreationCodesTitle: `Coupon Codes`,
campaign_creation_codes_title: `Coupon Codes`,
campaignCreationContentCtasAddButton: (i: P) => `Add alternative CTA (€${i as S})` as const,
campaign_creation_content_ctas_add_button: (i: P) => `Add alternative CTA (€${i as S})` as const,
campaignCreationContentCtasDesc: ``,
campaign_creation_content_ctas_desc: ``,
campaignCreationContentCtasPlaceh: (i: P) => `Alternative Call-to-Action ${i as S}` as const,
campaign_creation_content_ctas_placeh: (i: P) => `Alternative Call-to-Action ${i as S}` as const,
campaignCreationContentCtasPlaceholder1: `Make a recommendation to buy. Get -20% with code now.`,
campaign_creation_content_ctas_placeholder1: `Make a recommendation to buy. Get -20% with code now.`,
campaignCreationContentCtasTitle: `Call-To-Action (Optional)`,
campaign_creation_content_ctas_title: `Call-To-Action (Optional)`,
campaignCreationContentHooksAddButton: (i: P) => `Add alternative Hook (€${i as S})` as const,
campaign_creation_content_hooks_add_button: (i: P) => `Add alternative Hook (€${i as S})` as const,
campaignCreationContentHooksDesc: ``,
campaign_creation_content_hooks_desc: ``,
campaignCreationContentHooksPlaceh: (i: P) => `Alternative Hook ${i as S}` as const,
campaign_creation_content_hooks_placeh: (i: P) => `Alternative Hook ${i as S}` as const,
campaignCreationContentHooksPlaceholder1: `Welcome, high energy, selfie camera`,
campaign_creation_content_hooks_placeholder1: `Welcome, high energy, selfie camera`,
campaignCreationContentHooksTitle: `Intro / Hook (Optional)`,
campaign_creation_content_hooks_title: `Intro / Hook (Optional)`,
campaignCreationContentMentionsDesc: `Define information here that should be mentioned by the creators.`,
campaign_creation_content_mentions_desc: `Define information here that should be mentioned by the creators.`,
campaignCreationContentMentionsHonestDesc: `Define Don'ts which the Creator should not mention.`,
campaign_creation_content_mentions_honest_desc: `Define Don'ts which the Creator should not mention.`,
campaignCreationContentMentionsHonestLabel: (i: P) => `Don't ${i as S}` as const,
campaign_creation_content_mentions_honest_label: (i: P) => `Don't ${i as S}` as const,
campaignCreationContentMentionsHonestTitle: `Don'ts (optional)`,
campaign_creation_content_mentions_honest_title: `Don'ts (optional)`,
campaignCreationContentMentionsLabel: (i: P) => `Info ${i as S}` as const,
campaign_creation_content_mentions_label: (i: P) => `Info ${i as S}` as const,
campaignCreationContentMentionsShowcaseDesc: `Define mentions here, which are particularly important in the showcase video.`,
campaign_creation_content_mentions_showcase_desc: `Define mentions here, which are particularly important in the showcase video.`,
campaignCreationContentMentionsShowcaseLabel: (i: P) => `Info ${i as S}` as const,
campaign_creation_content_mentions_showcase_label: (i: P) => `Info ${i as S}` as const,
campaignCreationContentMentionsShowcaseTitle: `Additional Info`,
campaign_creation_content_mentions_showcase_title: `Additional Info`,
campaignCreationContentMentionsTitle: `Additional Info (Optional)`,
campaign_creation_content_mentions_title: `Additional Info (Optional)`,
campaignCreationContentMoodboardBody: `Upload up to 6 images/videos up, which should serve the creatorn as inspiration.\nAlso feel free to show us how you would like your subtitles, music or animations to look like.`,
campaign_creation_content_moodboard_body: `Upload up to 6 images/videos up, which should serve the creatorn as inspiration.\nAlso feel free to show us how you would like your subtitles, music or animations to look like.`,
campaignCreationContentMoodboardBody1: `Show the creator how you imagine your videos.`,
campaign_creation_content_moodboard_body1: `Show the creator how you imagine your videos.`,
campaignCreationContentMoodboardBody2: `Upload up to 6 images/videos up, which should serve the creatorn as inspiration.`,
campaign_creation_content_moodboard_body2: `Upload up to 6 images/videos up, which should serve the creatorn as inspiration.`,
campaignCreationContentMoodboardDontUse: `Do not use the Moodboard`,
campaign_creation_content_moodboard_dont_use: `Do not use the Moodboard`,
campaignCreationContentMoodboardTitle: `Moodboard (optional)`,
campaign_creation_content_moodboard_title: `Moodboard (optional)`,
campaignCreationContentMoodboardUse: `Use Moodboard`,
campaign_creation_content_moodboard_use: `Use Moodboard`,
campaignCreationContentNanoStoryboardDesc: `An expert from our team creates a storyboard for the creator.`,
campaign_creation_content_nano_storyboard_desc: `An expert from our team creates a storyboard for the creator.`,
campaignCreationContentNanoStoryboardLabel: `getnano Storyboard`,
campaign_creation_content_nano_storyboard_label: `getnano Storyboard`,
campaignCreationContentNoVoiceOverDesc: `360 ° Premium video without voice-over`,
campaign_creation_content_no_voice_over_desc: `360 ° Premium video without voice-over`,
campaignCreationContentNoVoiceOverLabel: `No Voice-Over`,
campaign_creation_content_no_voice_over_label: `No Voice-Over`,
campaignCreationContentProductPrefPlaceholder1: `Welcome, high energy, selfie camera`,
campaign_creation_content_product_pref_placeholder1: `Welcome, high energy, selfie camera`,
campaignCreationContentProductPrefPlaceholder2: `T-shirt on a neutral background and explain advantages`,
campaign_creation_content_product_pref_placeholder2: `T-shirt on a neutral background and explain advantages`,
campaignCreationContentProductPrefPlaceholder3: `Selfie camera; dressed in the t-shirt and shouting out to go to the website`,
campaign_creation_content_product_pref_placeholder3: `Selfie camera; dressed in the t-shirt and shouting out to go to the website`,
campaignCreationContentScenePlaceh: `Scenes`,
campaign_creation_content_scene_placeh: `Scenes`,
campaignCreationContentScenesDesc: `Describe the individual scenes how the video should be structured.`,
campaign_creation_content_scenes_desc: `Describe the individual scenes how the video should be structured.`,
campaignCreationContentScenesPlaceholder1: `Outer-Camera, show of the t-shirt on neutral background.
Selfie-Camera, show how you wear the t-shirt and talk about its quality.
Screen recording from online store with VoiceOver.`,
campaign_creation_content_scenes_placeholder1: `Outer-Camera, show of the t-shirt on neutral background.
Selfie-Camera, show how you wear the t-shirt and talk about its quality.
Screen recording from online store with VoiceOver.`,
campaignCreationContentScenesShowcaseTitle: `Content of the voiceovers`,
campaign_creation_content_scenes_showcase_title: `Content of the voiceovers`,
campaignCreationContentScenesTitle: `Scenes`,
campaign_creation_content_scenes_title: `Scenes`,
campaignCreationContentSelfServiceDesc: `You set the scenes and further inspiration for the creator yourself.`,
campaign_creation_content_self_service_desc: `You set the scenes and further inspiration for the creator yourself.`,
campaignCreationContentSelfServiceLabel: `Self-Service`,
campaign_creation_content_self_service_label: `Self-Service`,
campaignCreationContentStandardServiceDesc: `The creators create videos that match your product.`,
campaign_creation_content_standard_service_desc: `The creators create videos that match your product.`,
campaignCreationContentStandardServiceLabel: `Default`,
campaign_creation_content_standard_service_label: `Default`,
campaignCreationContentVoiceOverDesc: `Premium voice-over according to your description`,
campaign_creation_content_voice_over_desc: `Premium voice-over according to your description`,
campaignCreationContentVoiceOverDescription: `Describe the individual sections of your voice over.`,
campaign_creation_content_voice_over_description: `Describe the individual sections of your voice over.`,
campaignCreationContentVoiceOverLabel: `Voice-over`,
campaign_creation_content_voice_over_label: `Voice-over`,
campaignCreationContentVoiceOverPlaceh: (i: P) => `Voice-over ${i as S}` as const,
campaign_creation_content_voice_over_placeh: (i: P) => `Voice-over ${i as S}` as const,
campaignCreationCreate: `CHECKOUT`,
campaign_creation_create: `CHECKOUT`,
campaignCreationDeliveryTitle: `Delivery`,
campaign_creation_delivery_title: `Delivery`,
campaignCreationDisclaimerBody: `If there are delays that are indebted on your side, this also affects the delivery date.`,
campaign_creation_disclaimer_body: `If there are delays that are indebted on your side, this also affects the delivery date.`,
campaignCreationDisclaimerContactCheckLabel: `I take note that I am not allowed to contact the creators outside the platform.`,
campaign_creation_disclaimer_contact_check_label: `I take note that I am not allowed to contact the creators outside the platform.`,
campaignCreationDisclaimerInfoCheckLabel: `I take note of this information.`,
campaign_creation_disclaimer_info_check_label: `I take note of this information.`,
campaignCreationDisclaimerNoPaymentCheck: ``,
campaign_creation_disclaimer_no_payment_check: ``,
campaignCreationDisclaimerTitle: `Estimated delivery date`,
campaign_creation_disclaimer_title: `Estimated delivery date`,
campaignCreationError: `Oh no, you forgot something!`,
campaign_creation_error: `Oh no, you forgot something!`,
campaignCreationExample: `Example`,
campaign_creation_example: `Example`,
campaignCreationFormatTitle: `Video format`,
campaign_creation_format_title: `Video format`,
campaignCreationGenderAny: `All genders`,
campaign_creation_gender_any: `All genders`,
campaignCreationGenderDiverse: `Diverse`,
campaign_creation_gender_diverse: `Diverse`,
campaignCreationGenderFemale: `Female`,
campaign_creation_gender_female: `Female`,
campaignCreationGenderLabel: `Gender`,
campaign_creation_gender_label: `Gender`,
campaignCreationGenderMale: `Male`,
campaign_creation_gender_male: `Male`,
campaignCreationGeneralCampaignImageLabel: `Product Image`,
campaign_creation_general_campaign_image_label: `Product Image`,
campaignCreationGeneralProductDescLabel: `Describe your product briefly.*`,
campaign_creation_general_product_desc_label: `Describe your product briefly.*`,
campaignCreationGeneralProductInfo: `Product Information`,
campaign_creation_general_product_info: `Product Information`,
campaignCreationGeneralProductInfoBody: `Describe which product should advertise the content creator.`,
campaign_creation_general_product_info_body: `Describe which product should advertise the content creator.`,
campaignCreationGeneralProductInfoShowcaseBody: `Describe which product should advertise the content creator.`,
campaign_creation_general_product_info_showcase_body: `Describe which product should advertise the content creator.`,
campaignCreationGeneralProductLinkLabel: `Link to the product/website*`,
campaign_creation_general_product_link_label: `Link to the product/website*`,
campaignCreationGeneralProductNameLabel: `Product Name*`,
campaign_creation_general_product_name_label: `Product Name*`,
campaignCreationGeneralProductPriceDisclaimer: `Please input here the prime cost price instead of selling price. `,
campaign_creation_general_product_price_disclaimer: `Please input here the prime cost price instead of selling price. `,
campaignCreationGeneralProductPriceLabel: `Value in €*`,
campaign_creation_general_product_price_label: `Value in €*`,
campaignCreationGeneralProductType: `Product Type`,
campaign_creation_general_product_type: `Product Type`,
campaignCreationGeneralProductTypeDigital: `Digital Product`,
campaign_creation_general_product_type_digital: `Digital Product`,
campaignCreationGeneralProductTypePhysical: `Physical Product`,
campaign_creation_general_product_type_physical: `Physical Product`,
campaignCreationGeneralProducttypeDigitalDesc: `For digital products (app, software, etc.)`,
campaign_creation_general_producttype_digital_desc: `For digital products (app, software, etc.)`,
campaignCreationGeneralProducttypeDigitalTitle: `Digital`,
campaign_creation_general_producttype_digital_title: `Digital`,
campaignCreationGeneralProducttypePhysicalDesc: `Deliver your product to the content creator. He can keep it after completing the order.`,
campaign_creation_general_producttype_physical_desc: `Deliver your product to the content creator. He can keep it after completing the order.`,
campaignCreationGeneralProducttypePhysicalShowcaseDesc: `Deliver your product to our teams content creator. He can keep it after completing the order.`,
campaign_creation_general_producttype_physical_showcase_desc: `Deliver your product to our teams content creator. He can keep it after completing the order.`,
campaignCreationGeneralProducttypePhysicalTitle: `Physical`,
campaign_creation_general_producttype_physical_title: `Physical`,
campaignCreationInviteBody: `With which creators do you want to work again?`,
campaign_creation_invite_body: `With which creators do you want to work again?`,
campaignCreationInviteTitle: `Invite the creator`,
campaign_creation_invite_title: `Invite the creator`,
campaignCreationLanguageEnglish: `English`,
campaign_creation_language_english: `English`,
campaignCreationLanguageGerman: `German`,
campaign_creation_language_german: `German`,
campaignCreationLanguageLabel: `Video Language`,
campaign_creation_language_label: `Video Language`,
campaignCreationLocaleDeliveryDesc: `You will receive the addresses of participating creators and you will deliver them the product.`,
campaign_creation_locale_delivery_desc: `You will receive the addresses of participating creators and you will deliver them the product.`,
campaignCreationLocaleDeliveryTitle: `Via Delivery`,
campaign_creation_locale_delivery_title: `Via Delivery`,
campaignCreationLocaleOnlineDesc: `The creator receives a voucher code, he can use in your online shop.`,
campaign_creation_locale_online_desc: `The creator receives a voucher code, he can use in your online shop.`,
campaignCreationLocaleOnlineTitle: `Via Voucher`,
campaign_creation_locale_online_title: `Via Voucher`,
campaignCreationPersistBody: `You still have an unfished order stored. Do you want to load this data?`,
campaign_creation_persist_body: `You still have an unfished order stored. Do you want to load this data?`,
campaignCreationPersistDelete: `Delete`,
campaign_creation_persist_delete: `Delete`,
campaignCreationPersistTitle: `Load data`,
campaign_creation_persist_title: `Load data`,
campaignCreationPreferences: `Product Preferences`,
campaign_creation_preferences: `Product Preferences`,
campaignCreationPreferencesNo: `The product is universal for everyone.`,
campaign_creation_preferences_no: `The product is universal for everyone.`,
campaignCreationPreferencesPlaceh: `For example, what clothing size do you wear?`,
campaign_creation_preferences_placeh: `For example, what clothing size do you wear?`,
campaignCreationPreferencesQuestion: (i: P) => `Question {i}}` as const,
campaign_creation_preferences_question: (i: P) => `Question {i}}` as const,
campaignCreationPreferencesTooltip: `Do you need certain information such as shoe size of the creator to send you the right product? Then add questions here to collect the necessary information.`,
campaign_creation_preferences_tooltip: `Do you need certain information such as shoe size of the creator to send you the right product? Then add questions here to collect the necessary information.`,
campaignCreationPreferencesYes: `I need further information from the creators.`,
campaign_creation_preferences_yes: `I need further information from the creators.`,
campaignCreationProductOptionNo: `No, the creator doesn't need my product for the video`,
campaign_creation_product_option_no: `No, the creator doesn't need my product for the video`,
campaignCreationProductTypeQuestion: `Will you send your product to the creator?`,
campaign_creation_product_type_question: `Will you send your product to the creator?`,
campaignCreationRequirements: `Requirements for the Creators`,
campaign_creation_requirements: `Requirements for the Creators`,
campaignCreationSectionBrand: `Brand Info`,
campaign_creation_section_brand: `Brand Info`,
campaignCreationSectionBudget: `Amount`,
campaign_creation_section_budget: `Amount`,
campaignCreationSectionContent: `Content`,
campaign_creation_section_content: `Content`,
campaignCreationSectionInvite: `Invites`,
campaign_creation_section_invite: `Invites`,
campaignCreationSectionProduct: `Product`,
campaign_creation_section_product: `Product`,
campaignCreationSectionRequirements: `Requirements`,
campaign_creation_section_requirements: `Requirements`,
campaignCreationSectionVideoType: `Video Type`,
campaign_creation_section_video_type: `Video Type`,
campaignCreationTimelineApplications: (endDate: P) => `Select Applicants\ntill ${endDate as S}` as const,
campaign_creation_timeline_applications: (endDate: P) => `Select Applicants\ntill ${endDate as S}` as const,
campaignCreationTimelineDelivery: (endDate: P) => `Deliver Products\ntill ${endDate as S}` as const,
campaign_creation_timeline_delivery: (endDate: P) => `Deliver Products\ntill ${endDate as S}` as const,
campaignCreationTimelineFeedback: (endDate: P) => `Get Content\ntill ${endDate as S}` as const,
campaign_creation_timeline_feedback: (endDate: P) => `Get Content\ntill ${endDate as S}` as const,
campaignCreationTimelineVideos: (endDate: P) => `Give Feedback (Optional)\ntill ${endDate as S}` as const,
campaign_creation_timeline_videos: (endDate: P) => `Give Feedback (Optional)\ntill ${endDate as S}` as const,
campaignCreationUpdate: `SAVE UPDATE`,
campaign_creation_update: `SAVE UPDATE`,
campaignCreationUploadBrandLogoError: `Error when uploading the brand image. The campaign is still created. Please mail to ugc@stylink.com so that we can add the missing picture.`,
campaign_creation_upload_brand_logo_error: `Error when uploading the brand image. The campaign is still created. Please mail to ugc@stylink.com so that we can add the missing picture.`,
campaignCreationUploadCampaignImageError: `Errors when uploading the campaign image. The campaign is still created. Please mail to ugc@stylink.com so that we can add the missing picture.`,
campaign_creation_upload_campaign_image_error: `Errors when uploading the campaign image. The campaign is still created. Please mail to ugc@stylink.com so that we can add the missing picture.`,
campaignCreationUploadMoodboardError: `Error uploading the moodboard`,
campaign_creation_upload_moodboard_error: `Error uploading the moodboard`,
campaignCreationVideoDuration_15: `10 - 15 seconds`,
campaign_creation_video_duration_15: `10 - 15 seconds`,
campaignCreationVideoDuration_30: `25 - 30 seconds`,
campaign_creation_video_duration_30: `25 - 30 seconds`,
campaignCreationVideoDuration_60: `60 seconds`,
campaign_creation_video_duration_60: `60 seconds`,
campaignCreationVideoDurationPrice: (price: P) => `€ ${price as S} / sec` as const,
campaign_creation_video_duration_price: (price: P) => `€ ${price as S} / sec` as const,
campaignCreationVideoDurationTitle: `Video Duration`,
campaign_creation_video_duration_title: `Video Duration`,
campaignCreationVideoFeaturesInfo: `These additional services are delivered directly via our team.`,
campaign_creation_video_features_info: `These additional services are delivered directly via our team.`,
campaignCreationVideoFeaturesTitle: `Additional Services (optional)`,
campaign_creation_video_features_title: `Additional Services (optional)`,
campaignCreationVideoFormatFullscreen: `Landscape`,
campaign_creation_video_format_fullscreen: `Landscape`,
campaignCreationVideoFormatHorizontal: `Landscape`,
campaign_creation_video_format_horizontal: `Landscape`,
campaignCreationVideoFormatHorizontalPlatform: `YouTube`,
campaign_creation_video_format_horizontal_platform: `YouTube`,
campaignCreationVideoFormatSquare: `Square`,
campaign_creation_video_format_square: `Square`,
campaignCreationVideoFormatVertical: `Portrait`,
campaign_creation_video_format_vertical: `Portrait`,
campaignCreationVideoFormatVerticalPlatform: `Instagram | TikTok`,
campaign_creation_video_format_vertical_platform: `Instagram | TikTok`,
campaignCreationVideoTypeAdDesc: `Ideal for social media ads.`,
campaign_creation_video_type_ad_desc: `Ideal for social media ads.`,
campaignCreationVideoTypeAdTitle: `UGC Video Ad`,
campaign_creation_video_type_ad_title: `UGC Video Ad`,
campaignCreationVideoTypeExampleTitle: `Example Video`,
campaign_creation_video_type_example_title: `Example Video`,
campaignCreationVideoTypeHonestDesc: `Creators give honest experiences about your product.`,
campaign_creation_video_type_honest_desc: `Creators give honest experiences about your product.`,
campaignCreationVideoTypeHonestTitle: `Honest Review`,
campaign_creation_video_type_honest_title: `Honest Review`,
campaignCreationVideoTypePremiumDesc: `UGC videos by experienced creators. In detail: better lighting, sound quality etc.`,
campaign_creation_video_type_premium_desc: `UGC videos by experienced creators. In detail: better lighting, sound quality etc.`,
campaignCreationVideoTypePremiumTitle: `UGC Premium Video`,
campaign_creation_video_type_premium_title: `UGC Premium Video`,
campaignCreationVideoTypeShowcaseDesc: `Show your product in a 360 ° video. Created by a premium creator of our team.`,
campaign_creation_video_type_showcase_desc: `Show your product in a 360 ° video. Created by a premium creator of our team.`,
campaignCreationVideoTypeShowcaseTitle: `360 ° Show Case`,
campaign_creation_video_type_showcase_title: `360 ° Show Case`,
campaignCreationVideoTypeTitle: `Video Type`,
campaign_creation_video_type_title: `Video Type`,
campaignCreationVoiceOverAny: `Not important`,
campaign_creation_voice_over_any: `Not important`,
campaignCreationVoiceOverComputer: `Computer`,
campaign_creation_voice_over_computer: `Computer`,
campaignCreationVoiceOverFemale: `Feminine`,
campaign_creation_voice_over_female: `Feminine`,
campaignCreationVoiceOverLabel: `Voice-Over`,
campaign_creation_voice_over_label: `Voice-Over`,
campaignCreationVoiceOverMale: `Masculine`,
campaign_creation_voice_over_male: `Masculine`,
campaignCreationVoiceOverNone: `No voice-over`,
campaign_creation_voice_over_none: `No voice-over`,
campaignCreationYupAge: `Age is a mandatory field`,
campaign_creation_yup_age: `Age is a mandatory field`,
campaignCreationYupBrandLogo: `Logo is a mandatory field`,
campaign_creation_yup_brand_logo: `Logo is a mandatory field`,
campaignCreationYupCampaignImage: `Product Image is a mandatory field`,
campaign_creation_yup_campaign_image: `Product Image is a mandatory field`,
campaignCreationYupCode: (number: P) => `You have to add at least ${number as S} code` as const,
campaign_creation_yup_code: (number: P) => `You have to add at least ${number as S} code` as const,
campaignCreationYupCodePlural: (number: P) => `You have to add at least ${number as S} codes` as const,
campaign_creation_yup_code_plural: (number: P) => `You have to add at least ${number as S} codes` as const,
campaignCreationYupCodes: `Voucher Code is a mandatory field`,
campaign_creation_yup_codes: `Voucher Code is a mandatory field`,
campaignCreationYupCodesCount1: `Number of Vouchers must be a number`,
campaign_creation_yup_codes_count1: `Number of Vouchers must be a number`,
campaignCreationYupCodesCount2: `Number of Vouchers is a mandatory field`,
campaign_creation_yup_codes_count2: `Number of Vouchers is a mandatory field`,
campaignCreationYupContactCheck: `Confirm that you only get in touch with the creators via the platform `,
campaign_creation_yup_contact_check: `Confirm that you only get in touch with the creators via the platform `,
campaignCreationYupGender: `Gender is a mandatory field`,
campaign_creation_yup_gender: `Gender is a mandatory field`,
campaignCreationYupHooksCtasLimit: `A maximum of 200 characters possible`,
campaign_creation_yup_hooks_ctas_limit: `A maximum of 200 characters possible`,
campaignCreationYupInfoCheck: `Please confirm that you have noted the information`,
campaign_creation_yup_info_check: `Please confirm that you have noted the information`,
campaignCreationYupLanguage: `Language is a mandatory field`,
campaign_creation_yup_language: `Language is a mandatory field`,
campaignCreationYupLink1: `Invalid URL`,
campaign_creation_yup_link1: `Invalid URL`,
campaignCreationYupLink2: `Website is a mandatory field`,
campaign_creation_yup_link2: `Website is a mandatory field`,
campaignCreationYupLocale: `The type of delivery is a madatory field`,
campaign_creation_yup_locale: `The type of delivery is a madatory field`,
campaignCreationYupMetionsLimit: `A maximum of 120 characters possible`,
campaign_creation_yup_metions_limit: `A maximum of 120 characters possible`,
campaignCreationYupName: `Name is a mandatory field`,
campaign_creation_yup_name: `Name is a mandatory field`,
campaignCreationYupNumberOfSlots1: `Number of videos must be at least 1`,
campaign_creation_yup_number_of_slots1: `Number of videos must be at least 1`,
campaignCreationYupNumberOfSlots2: `Number of videos is a mandatory field`,
campaign_creation_yup_number_of_slots2: `Number of videos is a mandatory field`,
campaignCreationYupNumberOfSlots3: `A maximum of 10 videos are possible`,
campaign_creation_yup_number_of_slots3: `A maximum of 10 videos are possible`,
campaignCreationYupPrice1: `Price must be a number`,
campaign_creation_yup_price1: `Price must be a number`,
campaignCreationYupPrice2: `Price must be larger than 0 €`,
campaign_creation_yup_price2: `Price must be larger than 0 €`,
campaignCreationYupPrice3: `Price is a mandatory field`,
campaign_creation_yup_price3: `Price is a mandatory field`,
campaignCreationYupProductDesc: `Description is a mandatory field`,
campaign_creation_yup_product_desc: `Description is a mandatory field`,
campaignCreationYupProductType: `Product Type is a compulsory field`,
campaign_creation_yup_product_type: `Product Type is a compulsory field`,
campaignCreationYupScenes1: `Scenes are a mandatory field`,
campaign_creation_yup_scenes1: `Scenes are a mandatory field`,
campaignCreationYupScenes2: `Add at least one scene`,
campaign_creation_yup_scenes2: `Add at least one scene`,
campaignCreationYupVideoDuration: `Video Duration is a mandatory field`,
campaign_creation_yup_video_duration: `Video Duration is a mandatory field`,
campaignCreationYupVideoFormat: `Video Format is a mandatory field`,
campaign_creation_yup_video_format: `Video Format is a mandatory field`,
campaignCreationYupVideoType: `Video Type is a mandatory field`,
campaign_creation_yup_video_type: `Video Type is a mandatory field`,
campaignDataMissing: ``,
campaign_data_missing: ``,
campaignFormNanoserviceDesc: `An expert from our team creates a storyboard for the creator.`,
campaign_form_nanoservice_desc: `An expert from our team creates a storyboard for the creator.`,
campaignFormNanoserviceTitle: `getnano Storyboard`,
campaign_form_nanoservice_title: `getnano Storyboard`,
campaignMoodboard: `Inspiration`,
campaign_moodboard: `Inspiration`,
campaignOverviewAcceptInfluencer: `Accept`,
campaign_overview_accept_influencer: `Accept`,
campaignOverviewAcceptInfluencerError: `Error occurred when accepting`,
campaign_overview_accept_influencer_error: `Error occurred when accepting`,
campaignOverviewApplicantsEmptyBody: `Where is everyone?`,
campaign_overview_applicants_empty_body: `Where is everyone?`,
campaignOverviewApplicantsEmptyTitle: `You have no applicants for this order`,
campaign_overview_applicants_empty_title: `You have no applicants for this order`,
campaignOverviewBookInfluencer: `Book`,
campaign_overview_book_influencer: `Book`,
campaignOverviewCheckEmptyBody: `There are no videos in your campaign yet`,
campaign_overview_check_empty_body: `There are no videos in your campaign yet`,
campaignOverviewCheckEmptyDoneBody: `No new content pieces to review`,
campaign_overview_check_empty_done_body: `No new content pieces to review`,
campaignOverviewCheckEmptyDoneTitle: `All done`,
campaign_overview_check_empty_done_title: `All done`,
campaignOverviewCheckEmptyTitle: `Huch, no content pieces yet`,
campaign_overview_check_empty_title: `Huch, no content pieces yet`,
campaignOverviewCheckGiveFeedback: `Give feedback`,
campaign_overview_check_give_feedback: `Give feedback`,
campaignOverviewCheckInfo: `Booked additional services, such as subtitles, music and animations, are only added by our team after accepting.`,
campaign_overview_check_info: `Booked additional services, such as subtitles, music and animations, are only added by our team after accepting.`,
campaignOverviewContentBeingEdited: `IN EDITING`,
campaign_overview_content_being_edited: `IN EDITING`,
campaignOverviewContentDownload: `download`,
campaign_overview_content_download: `download`,
campaignOverviewContentDuplicateBody: `Duplicate your order now, to get more videos.`,
campaign_overview_content_duplicate_body: `Duplicate your order now, to get more videos.`,
campaignOverviewContentDuplicateButton: `Duplicate`,
campaign_overview_content_duplicate_button: `Duplicate`,
campaignOverviewContentDuplicateTitle: `Get more videos`,
campaign_overview_content_duplicate_title: `Get more videos`,
campaignOverviewContentEmptyBody: `You don't have finished videos yet`,
campaign_overview_content_empty_body: `You don't have finished videos yet`,
campaignOverviewContentEmptyTitle: `There are still no finished videos`,
campaign_overview_content_empty_title: `There are still no finished videos`,
campaignOverviewContentGiveRatingBody: `Based on your rating, we can display fitting creators for your future orders.`,
campaign_overview_content_give_rating_body: `Based on your rating, we can display fitting creators for your future orders.`,
campaignOverviewContentGiveRatingButton: `Send Rating`,
campaign_overview_content_give_rating_button: `Send Rating`,
campaignOverviewContentGiveRatingPholder: `Let the creator know what he can do better.`,
campaign_overview_content_give_rating_pholder: `Let the creator know what he can do better.`,
campaignOverviewContentGiveRatingTitle: `Rating`,
campaign_overview_content_give_rating_title: `Rating`,
campaignOverviewContentRawDownload: `Download the raw file`,
campaign_overview_content_raw_download: `Download the raw file`,
campaignOverviewContentUpgradeLabel: `BOOK UPGRADE`,
campaign_overview_content_upgrade_label: `BOOK UPGRADE`,
campaignOverviewContentUpgradedMessage: `Successfully booked`,
campaign_overview_content_upgraded_message: `Successfully booked`,
campaignOverviewCreatorsAddress: `Address`,
campaign_overview_creators_address: `Address`,
campaignOverviewCreatorsBeingEdited: `In Editing`,
campaign_overview_creators_being_edited: `In Editing`,
campaignOverviewCreatorsCheckContent: `Check content`,
campaign_overview_creators_check_content: `Check content`,
campaignOverviewCreatorsCodes: `Coupon Code`,
campaign_overview_creators_codes: `Coupon Code`,
campaignOverviewCreatorsCreator: `Creator`,
campaign_overview_creators_creator: `Creator`,
campaignOverviewCreatorsDeleteButton: `remove`,
campaign_overview_creators_delete_button: `remove`,
campaignOverviewCreatorsDisclaimer: `If possible, leave the email ugc@stylink.com with the shipping service provider as a contact. This way we can prevent delivery problems.`,
campaign_overview_creators_disclaimer: `If possible, leave the email ugc@stylink.com with the shipping service provider as a contact. This way we can prevent delivery problems.`,
campaignOverviewCreatorsEmptyBody: `You have no active participants yet`,
campaign_overview_creators_empty_body: `You have no active participants yet`,
campaignOverviewCreatorsEmptyTitle: `Huch, no participants yet`,
campaign_overview_creators_empty_title: `Huch, no participants yet`,
campaignOverviewCreatorsFinished: `completed`,
campaign_overview_creators_finished: `completed`,
campaignOverviewCreatorsGiveFeedback: (endDate: P) => `Add feedback till ${endDate as S}` as const,
campaign_overview_creators_give_feedback: (endDate: P) => `Add feedback till ${endDate as S}` as const,
campaignOverviewCreatorsMail: (brandName: P,firstname: P) => `mailto:ugc@stylink.com?subject=Problem in ${brandName as S} with Nano ${firstname as S}` as const,
campaign_overview_creators_mail: (brandName: P,firstname: P) => `mailto:ugc@stylink.com?subject=Problem in ${brandName as S} with Nano ${firstname as S}` as const,
campaignOverviewCreatorsMailButton: `report`,
campaign_overview_creators_mail_button: `report`,
campaignOverviewCreatorsMake_2ndContent: (endDate: P) => `Reworks content till ${endDate as S}` as const,
campaign_overview_creators_make_2nd_content: (endDate: P) => `Reworks content till ${endDate as S}` as const,
campaignOverviewCreatorsProductPreferences: `Product Preferences`,
campaign_overview_creators_product_preferences: `Product Preferences`,
campaignOverviewCreatorsSendUntil: (endDate: P) => `Send till ${endDate as S}` as const,
campaign_overview_creators_send_until: (endDate: P) => `Send till ${endDate as S}` as const,
campaignOverviewCreatorsShippingButton: `Delivery Info`,
campaign_overview_creators_shipping_button: `Delivery Info`,
campaignOverviewCreatorsStatus: `Status`,
campaign_overview_creators_status: `Status`,
campaignOverviewCreatorsSuccess: `All done`,
campaign_overview_creators_success: `All done`,
campaignOverviewCreatorsTitle1: `Creators`,
campaign_overview_creators_title1: `Creators`,
campaignOverviewCreatorsTitle2: `Send product`,
campaign_overview_creators_title2: `Send product`,
campaignOverviewCreatorsTitle3: `Content produced`,
campaign_overview_creators_title3: `Content produced`,
campaignOverviewCreatorsTodo: `ToDo`,
campaign_overview_creators_todo: `ToDo`,
campaignOverviewCreatorsWaitForFeedback: `Content is being checked by our team`,
campaign_overview_creators_wait_for_feedback: `Content is being checked by our team`,
campaignOverviewMakeContent: (endDate: P) => `Produces content till ${endDate as S}` as const,
campaign_overview_make_content: (endDate: P) => `Produces content till ${endDate as S}` as const,
campaignOverviewNoMoreCodes: `There are no more voucher codes. Please add more.`,
campaign_overview_no_more_codes: `There are no more voucher codes. Please add more.`,
campaignOverviewNoMoreSlots: `No more free videos`,
campaign_overview_no_more_slots: `No more free videos`,
campaignOverviewOnsentError: `Error occurred when sending`,
campaign_overview_onsent_error: `Error occurred when sending`,
campaignOverviewParticipant: `Participant:`,
campaign_overview_participant: `Participant:`,
campaignOverviewStatsBody1: `Creators upload a sample video promoting a product of their choice to show how they perform in front of the camera. Each booking is binding.`,
campaign_overview_stats_body1: `Creators upload a sample video promoting a product of their choice to show how they perform in front of the camera. Each booking is binding.`,
campaignOverviewStatsBody2: (daysForApplying: P) => `Applications remain for ${daysForApplying as S} days.` as const,
campaign_overview_stats_body2: (daysForApplying: P) => `Applications remain for ${daysForApplying as S} days.` as const,
campaignOverviewStatsTitle: `Note:`,
campaign_overview_stats_title: `Note:`,
campaignOverviewStepApplications: `Applications`,
campaign_overview_step_applications: `Applications`,
campaignOverviewStepCheck: `Check Content`,
campaign_overview_step_check: `Check Content`,
campaignOverviewStepContent: `My Content`,
campaign_overview_step_content: `My Content`,
campaignOverviewStepCreators: `My Creators`,
campaign_overview_step_creators: `My Creators`,
campaignProductInfo: `Product information`,
campaign_product_info: `Product information`,
campaignReward: `Reward`,
campaign_reward: `Reward`,
campaignRewardAmount: `In the amount of`,
campaign_reward_amount: `In the amount of`,
campaignRules: `Rules`,
campaign_rules: `Rules`,
campaignTask: ``,
campaign_task: ``,
campaignTaskMentions: `Mentions`,
campaign_task_mentions: `Mentions`,
campaignTaskScenes: `Scenes`,
campaign_task_scenes: `Scenes`,
cancel: `Reject`,
change: `Change`,
clear: `Okay!`,
commonSaved: `Saved successfully!`,
common_saved: `Saved successfully!`,
completeVideoToReceiveCredit: `After completing the video, you will receive credit that you can cash out via the app settings.`,
complete_video_to_receive_credit: `After completing the video, you will receive credit that you can cash out via the app settings.`,
copied: `Copied!`,
couponErrorNotEnoughVideos: (number: P) => `The code is only valid from ${number as S} videos booked` as const,
coupon_error_not_enough_videos: (number: P) => `The code is only valid from ${number as S} videos booked` as const,
createAPromoVideo: `Create a 15-second sample promo video with a product of your choice. We want to know how you would promote a product.`,
create_a_promo_video: `Create a 15-second sample promo video with a product of your choice. We want to know how you would promote a product.`,
creatorsExamples: ``,
creators_examples: ``,
creatorsStatusDeletedFirstupload: `Canceled`,
creators_status_deleted_firstupload: `Canceled`,
creatorsStatusDeletedSecondupload: `Feedback not noticed`,
creators_status_deleted_secondupload: `Feedback not noticed`,
dashboardCampaignApplicants: `Applications`,
dashboard_campaign_applicants: `Applications`,
dashboardCampaignCodesRunningOut: `Codes will go out soon`,
dashboard_campaign_codes_running_out: `Codes will go out soon`,
dashboardCampaignFinished: `Completed`,
dashboard_campaign_finished: `Completed`,
dashboardCampaignNotVerified: `Campaign is in review`,
dashboard_campaign_not_verified: `Campaign is in review`,
dashboardCampaignOffline: `offline`,
dashboard_campaign_offline: `offline`,
dashboardCampaignOnline: `online`,
dashboard_campaign_online: `online`,
dashboardCampaignParticipants: `Participant`,
dashboard_campaign_participants: `Participant`,
dashboardCampaignUgcLabel: `CONTENT`,
dashboard_campaign_ugc_label: `CONTENT`,
dashboardCampaignUsedUpCodes: `Codes used up`,
dashboard_campaign_used_up_codes: `Codes used up`,
dashboardCreateUgcBody: `Content creators create natural-looking promotional videos for your business.`,
dashboard_create_ugc_body: `Content creators create natural-looking promotional videos for your business.`,
dashboardCreateUgcButton: `Create Order`,
dashboard_create_ugc_button: `Create Order`,
dashboardCreateUgcTitle: `Promotion Video`,
dashboard_create_ugc_title: `Promotion Video`,
dashboardEmptyBody: (price: P) => `Let's change that! Create your first UGC orders now, from ${price as S} €` as const,
dashboard_empty_body: (price: P) => `Let's change that! Create your first UGC orders now, from ${price as S} €` as const,
dashboardEmptyIcon1: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1AF8AB9E-BFD2-4204-B116-ABED909E249F-26664-00000C30E97293A2/839718E9-3E1E-49A4-9BD8-FD71A20A0C2F-26664-00000C30E977F5BC.jpeg`,
dashboard_empty_icon1: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1AF8AB9E-BFD2-4204-B116-ABED909E249F-26664-00000C30E97293A2/839718E9-3E1E-49A4-9BD8-FD71A20A0C2F-26664-00000C30E977F5BC.jpeg`,
dashboardEmptyIcon2: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1CC9768A-1BE7-4690-B672-C6FE70A991E2-2978-000000DF802555CD/28BBE067-FF7A-442E-963E-7D829C0932A6-2978-000000DF802CD92D.jpeg`,
dashboard_empty_icon2: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/1CC9768A-1BE7-4690-B672-C6FE70A991E2-2978-000000DF802555CD/28BBE067-FF7A-442E-963E-7D829C0932A6-2978-000000DF802CD92D.jpeg`,
dashboardEmptyIcon3: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/39E52DD7-8131-400D-A070-82FD17C05BA5-1825-000000D71CA09EB5/6E320706-B093-4B37-80F2-9172F7036B26-1825-000000D71CA4B4DE.jpeg`,
dashboard_empty_icon3: `https://s3.eu-central-1.amazonaws.com/freebee-app-user/profilePictures/39E52DD7-8131-400D-A070-82FD17C05BA5-1825-000000D71CA09EB5/6E320706-B093-4B37-80F2-9172F7036B26-1825-000000D71CA4B4DE.jpeg`,
dashboardEmptyName1: `Sophie`,
dashboard_empty_name1: `Sophie`,
dashboardEmptyName2: `Carolin`,
dashboard_empty_name2: `Carolin`,
dashboardEmptyName3: `Claudio`,
dashboard_empty_name3: `Claudio`,
dashboardEmptyTitle: `Quite empty here.`,
dashboard_empty_title: `Quite empty here.`,
dashboardEmptyTour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
dashboard_empty_tour: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/platform_tour.mp4`,
dashboardEmptyVideo1: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_1.mp4`,
dashboard_empty_video1: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_1.mp4`,
dashboardEmptyVideo2: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_2.mp4`,
dashboard_empty_video2: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_2.mp4`,
dashboardEmptyVideo3: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_3.mp4`,
dashboard_empty_video3: `https://s3.eu-central-1.amazonaws.com/com.freebeeapp/public/webapp_videos/overview_empty_3.mp4`,
dashboardSortBudget: `Budget`,
dashboard_sort_budget: `Budget`,
dashboardSortChronological: `Chronologically`,
dashboard_sort_chronological: `Chronologically`,
dashboardSortOnline: `Online first`,
dashboard_sort_online: `Online first`,
dashboardTitle: `Your Orders`,
dashboard_title: `Your Orders`,
denyDialogChooseFeedback: `Enter feedback`,
deny_dialog_choose_feedback: `Enter feedback`,
denyDialogDisclaimer: `You can only request changes from creators once. So think carefully about what your feedback looks like.`,
deny_dialog_disclaimer: `You can only request changes from creators once. So think carefully about what your feedback looks like.`,
denyDialogFeedback: (number: P) => `Feedback ${number as S}` as const,
deny_dialog_feedback: (number: P) => `Feedback ${number as S}` as const,
denyDialogMax: `Max. 5 feedback comments`,
deny_dialog_max: `Max. 5 feedback comments`,
denyDialogReason1: ``,
deny_dialog_reason1: ``,
denyDialogReason2: ``,
deny_dialog_reason2: ``,
denyDialogReason3: ``,
deny_dialog_reason3: ``,
denyDialogReason4: ``,
deny_dialog_reason4: ``,
denyDialogReason5: ``,
deny_dialog_reason5: ``,
denyDialogReason6: ``,
deny_dialog_reason6: ``,
denyDialogReason7: ``,
deny_dialog_reason7: ``,
denyDialogReason8: ``,
deny_dialog_reason8: ``,
denyDialogSendFeedback: `Send Feedback`,
deny_dialog_send_feedback: `Send Feedback`,
denyDialogTitle: `Request Changes`,
deny_dialog_title: `Request Changes`,
denyReasonBadAge: `Too young or too old`,
deny_reason_bad_age: `Too young or too old`,
denyReasonBadQuality: `Quality not sufficient`,
deny_reason_bad_quality: `Quality not sufficient`,
denyReasonBadVoicing: `Voice does not fit`,
deny_reason_bad_voicing: `Voice does not fit`,
denyReasonIrrelevant: `Doesn't fit the brand`,
deny_reason_irrelevant: `Doesn't fit the brand`,
denyReasonOther: `Other`,
deny_reason_other: `Other`,
denyReasonQuestion: `Please explain what did not fit.`,
deny_reason_question: `Please explain what did not fit.`,
deselect: `Deselect`,
discount: `Discount Code`,
dragAndDropBody: `Max. 6 pictures or videos`,
drag_and_drop_body: `Max. 6 pictures or videos`,
dragAndDropCaption: `File: MP4, JPG, PNG; min. 400x400`,
drag_and_drop_caption: `File: MP4, JPG, PNG; min. 400x400`,
dragAndDropTitle: `Drag & Drop or choose File`,
drag_and_drop_title: `Drag & Drop or choose File`,
error: `Error occurred`,
error2: `There has been an error`,
errorReloadBody: `There has been an error. Try to reload the page.`,
error_reload_body: `There has been an error. Try to reload the page.`,
errorTitle: `Error`,
error_title: `Error`,
feedbackDialogDescription: `Help us improve your experience! We value your feedback and would love to hear your thoughts on how we can make our platform better. Thank you for your time!`,
feedback_dialog_description: `Help us improve your experience! We value your feedback and would love to hear your thoughts on how we can make our platform better. Thank you for your time!`,
feedbackDialogSubmitButton: `Submit`,
feedback_dialog_submit_button: `Submit`,
feedbackDialogSuccess: `Feedback sent successfully!`,
feedback_dialog_success: `Feedback sent successfully!`,
feedbackDialogTextfield: `Feedback`,
feedback_dialog_textfield: `Feedback`,
feedbackDialogTitle: `Give Feedback`,
feedback_dialog_title: `Give Feedback`,
fullSlotsCouponDialogBody: `For the next participant you book in this order you will receive`,
full_slots_coupon_dialog_body: `For the next participant you book in this order you will receive`,
fullSlotsCouponDialogBodyEmpty: `If your order gets new applicants today, you have the possibility to book these creators. For the next participant you book in this order, you will receive`,
full_slots_coupon_dialog_body_empty: `If your order gets new applicants today, you have the possibility to book these creators. For the next participant you book in this order, you will receive`,
fullSlotsCouponDialogExpiration: `Your voucher is:`,
full_slots_coupon_dialog_expiration: `Your voucher is:`,
fullSlotsCouponDialogTitle: `Here is a Voucher`,
full_slots_coupon_dialog_title: `Here is a Voucher`,
fullSlotsCouponDialogValidUntil: `Date of Expiry:`,
full_slots_coupon_dialog_valid_until: `Date of Expiry:`,
fullSlotsNormalDialogBody: `You now have the opportunity to book more Creators.

All open applicants will be rejected tonight.`,
full_slots_normal_dialog_body: `You now have the opportunity to book more Creators.

All open applicants will be rejected tonight.`,
fullSlotsNormalDialogBodyEmpty: `If your order gets new applicants today, you have the possibility to book this Creator.

All open applicants will then be rejected with tonight.`,
full_slots_normal_dialog_body_empty: `If your order gets new applicants today, you have the possibility to book this Creator.

All open applicants will then be rejected with tonight.`,
fullSlotsNormalDialogTitle: `Book more Creator`,
full_slots_normal_dialog_title: `Book more Creator`,
howToBecomeReward: `How do you get your reward?`,
how_to_become_reward: `How do you get your reward?`,
influencerDialogAge: `Age:`,
influencer_dialog_age: `Age:`,
influencerDialogBirthPlace: `Residence:`,
influencer_dialog_birth_place: `Residence:`,
influencerDialogCountry: `Country:`,
influencer_dialog_country: `Country:`,
influencerDialogDenyReason: `Select reason`,
influencer_dialog_deny_reason: `Select reason`,
introCampaignOverviewCreatorsSend: (date: P) => `You have to enter the shipment number here till ${date as S}.` as const,
intro_campaign_overview_creators_send: (date: P) => `You have to enter the shipment number here till ${date as S}.` as const,
loginAgbButton: `Terms and conditions`,
login_agb_button: `Terms and conditions`,
loginButton: `Login`,
login_button: `Login`,
loginCodeLabel: `Code {{name}}`,
login_code_label: `Code {{name}}`,
loginDataProtectionButton: `Privacy policy`,
login_data_protection_button: `Privacy policy`,
loginEmailLabel: `E-Mail`,
login_email_label: `E-Mail`,
loginError: `There has been an error`,
login_error: `There has been an error`,
loginErrorEmailExists: `E-Mail already exists.`,
login_error_email_exists: `E-Mail already exists.`,
loginErrorTooManyAttempts: `Too many attempts to register.`,
login_error_too_many_attempts: `Too many attempts to register.`,
loginErrorWrongCredentials: `Username and password do not match`,
login_error_wrong_credentials: `Username and password do not match`,
loginForgotPasswordButton: `Forgot Password?`,
login_forgot_password_button: `Forgot Password?`,
loginLoginButton: `Log in!`,
login_login_button: `Log in!`,
loginLoginText: `No account yet?`,
login_login_text: `No account yet?`,
loginPasswordLabel: `Password`,
login_password_label: `Password`,
loginRegisterButton: `Create one!`,
login_register_button: `Create one!`,
loginRegisterConfirmationPart1: `I agree with the`,
login_register_confirmation_part1: `I agree with the`,
loginRegisterConfirmationPart2: `Terms and conditions`,
login_register_confirmation_part2: `Terms and conditions`,
loginRegisterConfirmationPart3: `and the`,
login_register_confirmation_part3: `and the`,
loginRegisterConfirmationPart4: `Privacy policy.`,
login_register_confirmation_part4: `Privacy policy.`,
loginRegisterConfirmationPart5: ``,
login_register_confirmation_part5: ``,
loginRegisterFirstnameLabel: `First Name`,
login_register_firstname_label: `First Name`,
loginRegisterSubmitButton: `Register`,
login_register_submit_button: `Register`,
loginRegisterSurnameLabel: `Last Name`,
login_register_surname_label: `Last Name`,
loginRegisterText: `You already have an account?`,
login_register_text: `You already have an account?`,
loginRegisterYupCode1: `Please paste the code from the email`,
login_register_yup_code1: `Please paste the code from the email`,
loginRegisterYupConfirmation: `Terms and conditions and privacy policy must be confirmed`,
login_register_yup_confirmation: `Terms and conditions and privacy policy must be confirmed`,
loginRegisterYupEmail1: `Invalid e-mail address`,
login_register_yup_email1: `Invalid e-mail address`,
loginRegisterYupEmail2: `Email is a mandatory field`,
login_register_yup_email2: `Email is a mandatory field`,
loginRegisterYupFirstname1: `First Name must be longer than 2 characters`,
login_register_yup_firstname1: `First Name must be longer than 2 characters`,
loginRegisterYupPassword1: `Password must be min. 8 characters long`,
login_register_yup_password1: `Password must be min. 8 characters long`,
loginRegisterYupPassword2: `Password must contain min. 1 small letters`,
login_register_yup_password2: `Password must contain min. 1 small letters`,
loginRegisterYupPassword3: `Password must contain min. 1 capital letters`,
login_register_yup_password3: `Password must contain min. 1 capital letters`,
loginRegisterYupPassword4: `Password must contain min. 1 number`,
login_register_yup_password4: `Password must contain min. 1 number`,
loginRegisterYupPassword5: `Password is a mandatory field`,
login_register_yup_password5: `Password is a mandatory field`,
loginRegisterYupSurname1: `Last Name must be longer than 2 characters`,
login_register_yup_surname1: `Last Name must be longer than 2 characters`,
loginResetPasswordBody: `Don't worry about it happens to the best of them! Please tell us your email address and we will send you a code to reset.`,
login_reset_password_body: `Don't worry about it happens to the best of them! Please tell us your email address and we will send you a code to reset.`,
loginResetPasswordTitle: `Password Reset`,
login_reset_password_title: `Password Reset`,
loginSlideDesc1: `Create an order and set the video script, video length, mentions and more.`,
login_slide_desc1: `Create an order and set the video script, video length, mentions and more.`,
loginSlideDesc2: `Creators apply to carry out your order. You decide who you want to work with.`,
login_slide_desc2: `Creators apply to carry out your order. You decide who you want to work with.`,
loginSlideDesc3: `Send your product to the creators`,
login_slide_desc3: `Send your product to the creators`,
loginSlideDesc4: `You will receive your videos. If you are not satisfied, you have the option of a change loop.`,
login_slide_desc4: `You will receive your videos. If you are not satisfied, you have the option of a change loop.`,
loginSlideTitle1: `Create Order`,
login_slide_title1: `Create Order`,
loginSlideTitle2: `Receive Applicants & Select`,
login_slide_title2: `Receive Applicants & Select`,
loginSlideTitle3: `Send the Product`,
login_slide_title3: `Send the Product`,
loginSlideTitle4: `Get your Videos`,
login_slide_title4: `Get your Videos`,
loginYupEmail1: `Invalid e-mail address`,
login_yup_email1: `Invalid e-mail address`,
loginYupEmail2: `Email is a mandatory field`,
login_yup_email2: `Email is a mandatory field`,
loginYupPassword1: `Password must be min. 8 characters long`,
login_yup_password1: `Password must be min. 8 characters long`,
loginYupPassword2: `Password must contain min. 1 small letters`,
login_yup_password2: `Password must contain min. 1 small letters`,
loginYupPassword3: `Password must contain min. 1 capital letters`,
login_yup_password3: `Password must contain min. 1 capital letters`,
loginYupPassword4: `Password must contain min. 1 number`,
login_yup_password4: `Password must contain min. 1 number`,
loginYupPassword5: `Password is a mandatory field`,
login_yup_password5: `Password is a mandatory field`,
maximizeRoas: `Maximize your ROAS`,
maximize_roas: `Maximize your ROAS`,
missingCodesBody: `Some creators do not have a voucher code assigned yet`,
missing_codes_body: `Some creators do not have a voucher code assigned yet`,
missingCodesInfluencer: (name: P) => `Code ${name as S}` as const,
missing_codes_influencer: (name: P) => `Code ${name as S}` as const,
missingCodesTitle: `Here is a voucher`,
missing_codes_title: `Here is a voucher`,
more: `more`,
moreAboutTheProduct: `More about product`,
more_about_the_product: `More about product`,
mostPopular: `most popular`,
most_popular: `most popular`,
next: `Next`,
no: `No`,
noAuthBody: `It seems you have no permissions to this area.
                <br />
                Otherwise try to reload the page.`,
no_auth_body: `It seems you have no permissions to this area.
                <br />
                Otherwise try to reload the page.`,
noAuthTitle: `Sometimes it gets the best`,
no_auth_title: `Sometimes it gets the best`,
onboardingOther: `OTHER`,
onboarding_other: `OTHER`,
onboardingPlattformForAgencyButton: `FOR AGENCIES`,
onboarding_plattform_for_agency_button: `FOR AGENCIES`,
onboardingPlattformForBrandsButton: `FOR BRANDS`,
onboarding_plattform_for_brands_button: `FOR BRANDS`,
onboardingReferral: `Referral`,
onboarding_referral: `Referral`,
onboardingTitle: `How did you hear about us?`,
onboarding_title: `How did you hear about us?`,
paymentModalCancel: `CANCEL`,
payment_modal_cancel: `CANCEL`,
paymentModalChooseMethod: `Choose payment method:`,
payment_modal_choose_method: `Choose payment method:`,
paymentModalChooseMethodCreditcard: `Credit Card`,
payment_modal_choose_method_creditcard: `Credit Card`,
paymentModalChooseMethodSepa: `Sepa direct debit`,
payment_modal_choose_method_sepa: `Sepa direct debit`,
paymentModalCouponInvalid: `The discount code is invalid`,
payment_modal_coupon_invalid: `The discount code is invalid`,
paymentModalMethodChooseError: `Error when entering`,
payment_modal_method_choose_error: `Error when entering`,
paymentModalMethodCompanyInfoAddress: `Street, Streetnumber`,
payment_modal_method_company_info_address: `Street, Streetnumber`,
paymentModalMethodCompanyInfoCity: `City`,
payment_modal_method_company_info_city: `City`,
paymentModalMethodCompanyInfoCountry: `Country`,
payment_modal_method_company_info_country: `Country`,
paymentModalMethodCompanyInfoName: `Company Name`,
payment_modal_method_company_info_name: `Company Name`,
paymentModalMethodCompanyInfoTitle: `Billing Address`,
payment_modal_method_company_info_title: `Billing Address`,
paymentModalMethodCompanyInfoVatnumber: `VAT Number`,
payment_modal_method_company_info_vatnumber: `VAT Number`,
paymentModalMethodCompanyInfoVatnumberCheck: `My company has sales tax number`,
payment_modal_method_company_info_vatnumber_check: `My company has sales tax number`,
paymentModalMethodCompanyInfoZip: `Postal Code`,
payment_modal_method_company_info_zip: `Postal Code`,
paymentModalPay: `PAY`,
payment_modal_pay: `PAY`,
paymentModalTitle: `Your Order`,
payment_modal_title: `Your Order`,
paymentModalYupCompanyAddress: `Street & Streetnumber is a mandatory field`,
payment_modal_yup_company_address: `Street & Streetnumber is a mandatory field`,
paymentModalYupCompanyCity: `City is a mandatory field`,
payment_modal_yup_company_city: `City is a mandatory field`,
paymentModalYupCompanyCountry: `Country is a mandatory field`,
payment_modal_yup_company_country: `Country is a mandatory field`,
paymentModalYupCompanyName: `Company Name is a mandatory field`,
payment_modal_yup_company_name: `Company Name is a mandatory field`,
paymentModalYupCompanyPaymentMethod: `Payment Method is a mandatory field`,
payment_modal_yup_company_payment_method: `Payment Method is a mandatory field`,
paymentModalYupCompanyVatNumber1: `Vat number is a mandatory field`,
payment_modal_yup_company_vat_number1: `Vat number is a mandatory field`,
paymentModalYupCompanyVatNumber2: (country: P) => `Invalid VAT no. for ${country as S}` as const,
payment_modal_yup_company_vat_number2: (country: P) => `Invalid VAT no. for ${country as S}` as const,
paymentModalYupCompanyZip: `Postcode is a mandatory field`,
payment_modal_yup_company_zip: `Postcode is a mandatory field`,
phoneFeatureNotAvailable: `Phone call feature is not available on your device`,
phone_feature_not_available: `Phone call feature is not available on your device`,
preferencesDialogEmpty: `There has been an error`,
preferences_dialog_empty: `There has been an error`,
preferencesDialogNoAnswer: `No Answer`,
preferences_dialog_no_answer: `No Answer`,
preferencesDialogTitle: `Product Preferences`,
preferences_dialog_title: `Product Preferences`,
previewInvoiceBody: `Your price is made up of the following:`,
preview_invoice_body: `Your price is made up of the following:`,
previewInvoiceBrutto: `Gross`,
preview_invoice_brutto: `Gross`,
previewInvoiceCount: `Amount`,
preview_invoice_count: `Amount`,
previewInvoiceDiscount: `Discount`,
preview_invoice_discount: `Discount`,
previewInvoiceNetto: `Net`,
preview_invoice_netto: `Net`,
previewInvoiceReverseCharge: `Reverse Charge`,
preview_invoice_reverse_charge: `Reverse Charge`,
previewInvoiceTitle: `Pricing`,
preview_invoice_title: `Pricing`,
previewInvoiceUst_20: `VAT 19%`,
preview_invoice_ust_20: `VAT 19%`,
pricingCtas: (i: P) => `${i as S}x alt. Call-to-Action` as const,
pricing_ctas: (i: P) => `${i as S}x alt. Call-to-Action` as const,
pricingHooks: (i: P) => `${i as S}x alt. Hook` as const,
pricing_hooks: (i: P) => `${i as S}x alt. Hook` as const,
pricingNanoService: `Storyboard`,
pricing_nano_service: `Storyboard`,
pricingProductDigital: `Digital Product`,
pricing_product_digital: `Digital Product`,
pricingSelfService: `Self Service`,
pricing_self_service: `Self Service`,
pricingVideoDuration: `Video Duration`,
pricing_video_duration: `Video Duration`,
pricingVideoFeatureAnimations: `Animations`,
pricing_video_feature_animations: `Animations`,
pricingVideoFeatureLarge: `Editing: Large`,
pricing_video_feature_large: `Editing: Large`,
pricingVideoFeatureMedium: `Editing: Medium`,
pricing_video_feature_medium: `Editing: Medium`,
pricingVideoFeatureMusic: `Music`,
pricing_video_feature_music: `Music`,
pricingVideoFeatureSmall: `Subtitles`,
pricing_video_feature_small: `Subtitles`,
pricingVideoFeatureSubtitles: `Subtitle`,
pricing_video_feature_subtitles: `Subtitle`,
pricingVideoType: `Video Type`,
pricing_video_type: `Video Type`,
pricingVoiceOverAny: `Voice-Over`,
pricing_voice_over_any: `Voice-Over`,
pricingVoiceOverComputer: `Voice-Over`,
pricing_voice_over_computer: `Voice-Over`,
pricingVoiceOverFemale: `Voice-Over`,
pricing_voice_over_female: `Voice-Over`,
pricingVoiceOverMale: `Voice-Over`,
pricing_voice_over_male: `Voice-Over`,
productAtYourAddressShipped: `The product will be shipped to your address.`,
product_at_your_address_shipped: `The product will be shipped to your address.`,
profileSettingsAnalyticsDisabled: `You must restart the session to apply the change!`,
profile_settings_analytics_disabled: `You must restart the session to apply the change!`,
profileSettingsAnalyticsEnable: `Activate analytics`,
profile_settings_analytics_enable: `Activate analytics`,
profileSettingsAnalyticsUnable: `Deactivate analytics`,
profile_settings_analytics_unable: `Deactivate analytics`,
profileSettingsChangePassword: `Change Password`,
profile_settings_change_password: `Change Password`,
profileSettingsEmail: `E-mail`,
profile_settings_email: `E-mail`,
profileSettingsFirstname: `First Name`,
profile_settings_firstname: `First Name`,
profileSettingsPassword: `Password`,
profile_settings_password: `Password`,
profileSettingsSave: `Save Changes`,
profile_settings_save: `Save Changes`,
profileSettingsSaveError: `error while saving`,
profile_settings_save_error: `error while saving`,
profileSettingsSurname: `Last Name`,
profile_settings_surname: `Last Name`,
profileSettingsTitle: `Manage profile`,
profile_settings_title: `Manage profile`,
recommended: `recommended`,
recordVideoToBeAccepted: ``,
record_video_to_be_accepted: ``,
save: `Save`,
saved: `Saved`,
scene: `Scene`,
select: `Select`,
shippingBody: `Please select the shipping company here and enter the tracking code.`,
shipping_body: `Please select the shipping company here and enter the tracking code.`,
shippingButton: `Enter shipping nr.`,
shipping_button: `Enter shipping nr.`,
shippingOtherService: `Shipping provider`,
shipping_other_service: `Shipping provider`,
shippingSent: `Sent`,
shipping_sent: `Sent`,
shippingService: `Shipping Company`,
shipping_service: `Shipping Company`,
shippingTitle: `Shipping Tracking Code`,
shipping_title: `Shipping Tracking Code`,
shippingTrackingCodeLabel: `Shipping tracking code`,
shipping_tracking_code_label: `Shipping tracking code`,
sidebarHeadlineBrandSettings: `Manage Brand`,
sidebar_headline_brand_settings: `Manage Brand`,
sidebarHeadlineCreateCampaign: `Create Order`,
sidebar_headline_create_campaign: `Create Order`,
sidebarHeadlineOverview: `Order Overview`,
sidebar_headline_overview: `Order Overview`,
sidebarHeadlineProfileSettings: `Manage Profile`,
sidebar_headline_profile_settings: `Manage Profile`,
sidebarItemBrandSettings: `Manage Brand`,
sidebar_item_brand_settings: `Manage Brand`,
sidebarItemCampaigns: `Orders`,
sidebar_item_campaigns: `Orders`,
sidebarItemContact: `Support`,
sidebar_item_contact: `Support`,
sidebarItemCreateBrand: `Create new Brand`,
sidebar_item_create_brand: `Create new Brand`,
sidebarItemFeedback: `Feedback`,
sidebar_item_feedback: `Feedback`,
sidebarPopupCoupon: `Top up credit and secure <b>bonus</b>!`,
sidebar_popup_coupon: `Top up credit and secure <b>bonus</b>!`,
sidebarPopupCouponButton: `Charge`,
sidebar_popup_coupon_button: `Charge`,
sidebarPopupExpert: `Let our experts advise you <b>free of charge</b>.`,
sidebar_popup_expert: `Let our experts advise you <b>free of charge</b>.`,
sidebarPopupExpertButton: `Make a Meeting`,
sidebar_popup_expert_button: `Make a Meeting`,
sidebarStatsActiveCampaign: `Active Order`,
sidebar_stats_active_campaign: `Active Order`,
sidebarStatsActiveCampaigns: `Active Orders`,
sidebar_stats_active_campaigns: `Active Orders`,
somethingWentWrong: `Something went wrong`,
something_went_wrong: `Something went wrong`,
supportButtonCall: `Call`,
support_button_call: `Call`,
supportButtonChat: `Chat`,
support_button_chat: `Chat`,
supportButtonWhatsapp: ` Whats App`,
support_button_whatsapp: ` Whats App`,
test: (name: P,n: P) => `${name as S} Hello. ${n as S}` as const,
timePeriod: `Time period`,
time_period: `Time period`,
tipsForYourApplicationVideo: `Tips for your application video`,
tips_for_your_application_video: `Tips for your application video`,
toTheRules: `To the rules`,
to_the_rules: `To the rules`,
toTheTask: `To the task`,
to_the_task: `To the task`,
tutorialDoNotShowAgain: `Don't show again`,
tutorial_do_not_show_again: `Don't show again`,
understood: `Okay!`,
unknownError: `Unknown error`,
unknown_error: `Unknown error`,
upgradeVideoTutorialBody: `Upgrade your videos with music, subtitles or animations to get an even better ROAS!`,
upgrade_video_tutorial_body: `Upgrade your videos with music, subtitles or animations to get an even better ROAS!`,
upgradeVideoTutorialButton: `Choose Upgrade`,
upgrade_video_tutorial_button: `Choose Upgrade`,
upgradeVideoTutorialJoyrideContent: `Click here for Upgrades.`,
upgrade_video_tutorial_joyride_content: `Click here for Upgrades.`,
upgradeVideoTutorialTitle: `Upgrade your Videos.`,
upgrade_video_tutorial_title: `Upgrade your Videos.`,
uploadYourFinishedVideoTill: `You have time to upload your finished video until:`,
upload_your_finished_video_till: `You have time to upload your finished video until:`,
useVideosPicturesForPosting: `Please use the following pictures and videos as a guide when making your posting.`,
use_videos_pictures_for_posting: `Please use the following pictures and videos as a guide when making your posting.`,
userRoleAccountant: `ACCOUNTANT`,
user_role_accountant: `ACCOUNTANT`,
userRoleAdmin: `ADMIN`,
user_role_admin: `ADMIN`,
userRoleAnalyst: `ANALYST`,
user_role_analyst: `ANALYST`,
userRoleMarketer: `MARKETER`,
user_role_marketer: `MARKETER`,
videoFeatureAnimationsTitle: `Animations`,
video_feature_animations_title: `Animations`,
videoFeatureLargeDescription: `✓ Animated captions
✓ Logo
✓ Call-to-action
+ Motion graphics
+ Music`,
video_feature_large_description: `✓ Animated captions
✓ Logo
✓ Call-to-action
+ Motion graphics
+ Music`,
videoFeatureMediumDescription: `✓ Animated captions
+ Logo
+ Call-to-action`,
video_feature_medium_description: `✓ Animated captions
+ Logo
+ Call-to-action`,
videoFeatureMusicTitle: `Music`,
video_feature_music_title: `Music`,
videoFeatureSmallDescription: `✓ Subtitles`,
video_feature_small_description: `✓ Subtitles`,
videoFeatureSubtitlesTitle: `Subtitle`,
video_feature_subtitles_title: `Subtitle`,
wait: `Waiting`,
whatsappNotInstalledOnYourDevice: `Whats app is not installed on your device`,
whatsapp_not_installed_on_your_device: `Whats app is not installed on your device`,
yes: `Yes`,
yourApplicationVideo: `Your application video`,
your_application_video: `Your application video`,
texterifyTimestamp: `2024-11-27T15:01:11Z`,
texterify_timestamp: `2024-11-27T15:01:11Z`
} as const