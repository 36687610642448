import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const UTMHandler = ({ children }) => {
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const utmSource = searchParams.get('utm_source')
    const utmMedium = searchParams.get('utm_medium')
    const utmCampaign = searchParams.get('utm_campaign')
    const utmContent = searchParams.get('utm_content')

    if (utmSource || utmMedium || utmCampaign || utmContent) {
      const existingUTMData = JSON.parse(localStorage.getItem('utmData') || '{}')

      const newUTMData = {
        ...existingUTMData,
        source: utmSource?.replace('/', '') || existingUTMData.source,
        medium: utmMedium?.replace('/', '') || existingUTMData.medium,
        campaign: utmCampaign?.replace('/', '') || existingUTMData.campaign,
        content: utmContent?.replace('/', '') || existingUTMData.content,
        timestamp: new Date().toISOString()
      }

      localStorage.setItem('utmData', JSON.stringify(newUTMData))
    }
  }, [location])

  return <>{children}</>
}

export default UTMHandler
